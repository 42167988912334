//
// Component: Base
//
// ========================================================================
// Variables
// ========================================================================
// Base
// ========================================================================
a {
    transition: all .2s ease-in-out;
    color: $global-color;
    //border-bottom: 2px solid transparent;
    &:hover {
        text-decoration: none;
        color: $global-color-green;
        //border-bottom: 2px solid $global-color;
    }
}

a,
button,
.uk-radio {
    &:focus-visible {
        box-shadow: 0 0 10px $global-color-green!important;
        border: 1px solid $global-color-green;
        text-decoration: none!important;
    }
}

html {
    background: $global-color-white;
    color: $global-color;
    overflow-x: hidden;
}

.main {
    position: relative;
    width: 100%;
    //overflow-x: hidden;
    padding: 0;
    margin-top: 60px;
    @media (min-width: $breakpoint-small) {
        margin-top: 70px;
    }
    @media (min-width: $breakpoint-medium) {
        margin-top: 110px;
    }
}

h1,
.title-slide {
    color: white;
    font-family: $global-heading-font-family;
    font-size: 1.8rem;
    font-weight: normal;
    letter-spacing: 1px;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
    @media (min-width: $breakpoint-medium) {
        font-size: 2.5rem;
    }
    @media (min-width: $breakpoint-xlarge-max) {
        font-size: 3.1rem;
    }
}

.h1-black-center {
    color: $global-color-black;
    text-shadow: none;
    text-align: center;
    padding: $padding-padding*2 0;
}

.h1-validation {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    &:before {
        content: "\e909";
        font-family: 'icomoon';
        color: green;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3rem;
        width: 80px;
        height: 80px;
        border: 1px solid $global-color-soft-gray;
        border-radius: 50%;
        margin-bottom: 10px;
    }
}

.h1-error {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    &:before {
        content: "\e908";
        font-family: 'icomoon';
        color: red;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3rem;
        font-weight: bold;
        transform: rotate(45deg);
        width: 80px;
        height: 80px;
        border: 1px solid $global-color-soft-gray;
        border-radius: 50%;
        margin-bottom: 10px;
    }
}

h2 {
    text-transform: uppercase;
    font-family: $global-heading-font-family;
    font-weight: normal;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    margin-bottom: $margin-margin*2;
    color: $global-color-black;
    font-size: 1.2rem;
    @media (min-width: $breakpoint-medium) {
        font-size: 1.5rem;
    }
    @media (min-width: $breakpoint-xlarge-max) {
        font-size: 2.2rem;
    }
    &:before {
        content: "";
        display: block;
        width: 45px;
        margin-right: $margin-margin;
        height: 1px;
        background: $global-color-green;
        @media (min-width: $breakpoint-small) {
            width: 65px;
        }
    }
}

h3,
.titre_adresse {
    font-family: $global-heading-font-family;
    font-weight: normal;
    margin-bottom: $margin-margin*2;
    color: $global-color-black;
    font-size: 1.1rem;
    display: block;
    @media (min-width: $breakpoint-medium) {
        font-size: 1.2rem;
    }
    @media (min-width: $breakpoint-xlarge-max) {
        font-size: 1.2rem;
    }
}

.center {
    text-align: center;
}