//
// Component: Fonts
//
//
@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon/fonts/icomoon.eot?h8gs58');
    src: url('../fonts/icomoon/fonts/icomoon.eot?h8gs58#iefix') format('embedded-opentype'), url('../fonts/icomoon/fonts/icomoon.ttf?h8gs58') format('truetype'), url('../fonts/icomoon/fonts/icomoon.woff?h8gs58') format('woff'), url('../fonts/icomoon/fonts/icomoon.svg?h8gs58#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'bitcrushercondensed_bold';
    src: url('../fonts/bitcrusher/bitcrusher-condensed-bd-webfont.woff2') format('woff2'), url('../fonts/bitcrusher/bitcrusher-condensed-bd-webfont.woff') format('woff'), url('../fonts/bitcrusher/bitcrusher-condensed-bd.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}