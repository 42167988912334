//
// Component: Form
//
//
// ========================================================================
.uk-input,
.uk-textarea {
    border: none;
    border-bottom: 1px solid $global-color-soft-gray;
    margin-bottom: 20px;
    background: $global-color-white;
    font-size: .9em;
    color: $global-color;
    &::placeholder {
        color: $global-color;
        background: transparent;
    }
    &:focus-visible {
        background: none;
    }
}

.uk-textarea {
    min-height: 130px;
}

.uk-form-label {
    text-transform: uppercase;
    color: $global-color;
}

.uk-radio:checked,
.uk-checkbox:checked,
.uk-checkbox:indeterminate {
    background-color: $global-color-green!important;
    border-color: transparent;
}

.section-tri-form {
    background: #E5E1E2;
    padding: $padding-padding 0;
    transition: all .3s ease-in-out;
    margin-top: 20px;
    position: absolute;
    z-index: 10;
    right: -105%;
    width: 100%;
    &.open {
        right: 0;
    }
    @media (min-width: $breakpoint-small) {
        position: relative;
        right: initial;
        margin: 0;
    }
    .uk-container {
        @media (min-width: $breakpoint-small) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .formulaire-de-trie {
            width: 100%;
            .uk-flex {
                display: block;
                @media (min-width: $breakpoint-small) {
                    display: flex;
                    align-items: center;
                }
            }
            .container-select-items {
                @media (min-width: $breakpoint-small) {
                    display: flex;
                    align-items: center;
                }
                .container-select-field {
                    margin-right: .5rem!important;
                }
            }
            p {
                padding: 0;
                margin: 0 $margin-margin 0 0;
                font-family: $global-heading-font-family;
            }
            .uk-form-custom {
                margin: 10px 0 0 0;
                @media (min-width: $breakpoint-small) {
                    width: 32%;
                    margin: 0;
                }
            }
            .uk-button-search-trie-submit {
                background: white;
                border: none;
                text-transform: none;
                font-style: italic;
                font-size: .75rem;
                height: 40px;
                line-height: 1;
                border-radius: 25px;
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-between;
            }
        }
    }
    #btn-close-filters-tri {
        position: absolute;
        right: 5px;
        top: 10px;
        z-index: 10;
        @media (min-width: $breakpoint-small) {
            display: none;
        }
        span {
            font-size: 1.5rem;
            transform: rotate(45deg);
            display: block;
        }
    }
}

.container-btn-switch-card-list {
    display: none;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
    @media (min-width: $breakpoint-medium) {
        display: flex;
    }
    button {
        margin-left: 10px;
        opacity: .5;
        background: none;
        border: none;
        cursor: pointer;
        span {
            font-size: 1.5rem;
        }
        &.active {
            opacity: 1;
        }
    }
}

.formulaire-declinaison {
    .container-select-items {
        .uk-first-column {
            padding-left: 0;
        }
    }
    .container-colors {
        display: flex;
        grid-gap: 10px;
    }
}

.container-select-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    border: 1px solid $global-color-soft-gray;
    height: 45px;
    line-height: 1;
    border-radius: 25px;
    width: 95%;
    padding: 0;
    position: relative;
    //margin: .5rem auto!important;
    @media (min-width: $breakpoint-small) {
        width: 100%;
    }
    select {
        width: 100%;
        height: 45px!important;
        border: none;
        background-color: transparent;
        font-style: italic;
        font-size: .7rem;
        text-transform: none;
        appearance: none;
        background-image: none;
        -webkit-appearance: none;
        z-index: 10;
        padding: 0 calc($padding-padding/2) 0 $padding-padding;
        &:focus-visible {
            background-color: transparent;
            border: 1px solid red;
        }
    }
    .icon-arrow-down {
        position: absolute;
        right: 0;
        z-index: 0;
    }
    span.uk-icon {
        position: absolute;
        right: 10px;
    }
}

.detail_produit__addtocart .critere_libelle {
    color: $global-color-black;
    margin-bottom: 0.25rem;
}

.critere_libelle {
    color: $global-color-black;
    font-family: $global-heading-font-family;
    font-size: 1.2rem;
}

.carre_couleur {
    width: 2rem;
    height: 2rem;
    border-radius: 100rem;
    margin: 0;
    cursor: pointer;
    transition: all 0.4s cubic-bezier(0.14, 0.66, 0.28, 0.93);
    padding: 6px;
    @media (min-width: $breakpoint-large) {
        width: 2.5rem;
        height: 2.5rem
    }
    .color {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 100rem;
        margin: 0;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, .2);
    }
    /*     .label {
        color: $global-color-black;
        margin: 0 5px;
        font-size: .8rem;
        font-style: italic;
    } */
}

.contact-form {
    padding: 40px 40px 40px 10px!important;
    border: 1px solid $global-color-soft-gray;
    >* {
        padding-left: 30px;
    }
    *+.uk-margin {
        margin-top: 0!important;
    }
}

.input-data {
    height: 40px;
    position: relative;
    &.textarea-data {
        height: auto;
        label {
            bottom: 40px;
        }
    }
    input {
        height: 100%;
        width: 100%;
        border: none;
        border: 1px solid silver;
        &:focus~label,
        &:not(:placeholder-shown)~label {
            top: -25px;
            left: 0;
            font-size: .875rem;
            text-transform: uppercase;
            color: $global-color-green;
            font-style: normal;
        }
        &:focus-visible {
            border: 1px solid $global-color-green;
            box-shadow: 0 0 10px $global-color-green;
        }
    }
    textarea {
        &:focus~label,
        &:not(:placeholder-shown)~label {
            top: -25px;
            left: 0;
            font-size: .875rem;
            text-transform: uppercase;
            color: $global-color-green;
            font-style: normal;
        }
        &:focus-visible {
            border: 1px solid $global-color-green;
            box-shadow: 0 0 10px $global-color-green;
        }
    }
    label {
        position: absolute;
        pointer-events: none;
        left: 10px;
        top: 10px;
        font-style: italic;
        text-transform: none;
        text-align: left;
        color: $global-color-gray;
        transition: 0.2s ease-in-out all;
    }
}

.txt-legal {
    font-size: .9rem;
    .legal-link {
        font-weight: bold;
        &:hover {
            text-decoration: underline;
        }
    }
}

.mail-actuel {
    color: $global-color-green;
}

.civilite {
    margin-right: 1.5rem;
    span {
        padding-left: .2rem;
        text-transform: uppercase;
    }
}

input[type="date"] {
    position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    position: absolute;
    right: 5px;
    left: 0;
    top: 0;
    width: auto;
    background-position: right;
    background-size: 20px;
}