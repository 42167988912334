//
// Component: Footer
//
// Footer
// ========================================================================
footer {
    position: relative;
    z-index: 1;
    background: $global-color-black;
    color: white;
    .container-footer-top {
        padding: $padding-padding*2 0;
        @media (min-width: $breakpoint-small) {
            padding: $padding-padding*3 0;
        }
        .logo-footer {
            margin: 0 auto;
            padding: 0;
            @media (min-width: $breakpoint-small) {
                margin: 0;
            }
        }
        .uk-flex {
            justify-content: space-around;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 40px;
            @media (min-width: $breakpoint-small) {
                grid-template-columns: repeat(2, 1fr);
            }
            @media (min-width: $breakpoint-medium) {
                display: flex;
            }
        }
        .title-footer {
            text-transform: uppercase;
            font-weight: 500;
            font-size: 1.1rem;
        }
        .adresse {
            font-weight: 300;
            margin-bottom: 0;
            span {
                font-weight: 500;
            }
        }
        .phone-link {
            color: $global-color-green;
            display: block;
            &:hover {
                color: white;
                text-decoration: underline;
            }
        }
        .content-link {
            color: white;
            background: $global-color-green;
            border-radius: 25px;
            padding: 10px 20px;
            font-family: $global-heading-font-family;
            letter-spacing: 1px;
            margin-top: 20px;
            display: block;
            text-align: center;
            &:before {
                content: "";
                background: white;
            }
            &:hover {
                background: $global-color-soft-gray;
                color: $global-color;
                &:before {
                    content: "";
                    background: $global-color;
                }
            }
        }
    }
    .container-newsletter {
        font-weight: 300;
        #newsletter-form {
            .uk-flex {
                height: 60px;
                position: relative;
                display: flex;
                input.input-newsletter {
                    background: #5B5456;
                    border-radius: 50px;
                    border: none;
                    height: 60px;
                    width: 100%;
                    padding: 10px 30px 10px 15px;
                    color: white;
                    font-size: 1rem;
                    &::placeholder {
                        color: white;
                        font-style: italic;
                    }
                }
                .submit-newsletter-button {
                    height: 60px;
                    position: absolute;
                    right: 0;
                    color: white;
                    text-align: center;
                    background: $global-color-green;
                    border-radius: 50px;
                    padding: 10px 30px;
                    font-family: $global-heading-font-family;
                    font-size: 1rem;
                    letter-spacing: 1px;
                    border: none;
                    cursor: pointer;
                    transition: all .3s ease-in-out;
                    &:hover {
                        background: $global-color-soft-gray;
                        color: $global-color;
                    }
                }
            }
            .checkbox {
                align-items: center;
                justify-content: flex-start;
                grid-gap: 10px;
                input {
                    background: $global-color;
                    border: 1px solid white;
                    width: 20px;
                    height: 20px;
                    border-radius: 0;
                    padding: 0;
                }
            }
        }
    }
    .container-links {
        ul {
            padding: 0;
            margin: 0;
            list-style: none;
            li {
                a {
                    color: white;
                    text-transform: uppercase;
                    display: inline-block;
                    align-items: center;
                    margin: calc($margin-margin/2) 0;
                    border-bottom: 1px solid transparent;
                    transition: all .2s ease-in-out;
                    &:hover {
                        color: $global-color-green;
                        text-decoration: none;
                        border-bottom: 1px solid $global-color-green;
                    }
                    span {
                        font-size: 1.5rem;
                        margin-right: 5px;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
    .container-legal-links {
        display: block;
        @media (min-width: $breakpoint-small) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: block;
            align-items: center;
            text-align: left;
            @media (min-width: $breakpoint-small) {
                display: flex;
                width: auto;
                margin: 0;
                text-align: center;
            }
            li {
                display: inline-block;
                align-items: center;
                color: $global-color-white;
                a {
                    color: $global-color-white;
                    font-size: .8rem;
                    border-bottom: 1px solid transparent;
                    margin: calc($margin-margin/1.5) 0;
                    &:hover {
                        color: $global-color-green;
                        border-bottom: 1px solid $global-color-green;
                    }
                }
            }
        }
        .koredge-link {
            color: $global-color-white;
            font-size: .8rem;
            text-align: left;
            margin: calc($margin-margin/2) auto 0 auto;
            display: flex;
            align-items: center;
            border-bottom: none;
            @media (min-width: $breakpoint-small) {
                text-align: center;
                margin: 0;
            }
            &:hover {
                color: $global-color-green;
                border-bottom: none;
            }
            span {
                vertical-align: middle;
                font-size: 3rem;
                @media (min-width: $breakpoint-small) {
                    font-size: 1.2rem;
                }
            }
        }
    }
    .container-plan-de-site {
        padding: $padding-padding*1.5 0;
        background: url('../images/background-footer.jpg') no-repeat center center;
        background-size: cover;
        .container-item-nav-footer {
            max-width: 90%;
            margin: 0 auto;
            padding: 0;
            list-style: none;
            display: none;
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 20px;
            @media (min-width: $breakpoint-small) {
                grid-template-columns: repeat(2, 1fr);
            }
            @media (min-width: $breakpoint-medium) {
                grid-template-columns: repeat(4, 1fr);
            }
            @media (min-width: $breakpoint-xlarge) {
                grid-template-columns: repeat(7, 1fr);
            }
        }
        .title-nav-footer {
            color: white;
            font-family: $global-heading-font-family;
            font-size: 1.2rem;
            display: inline-block;
            margin-bottom: 10px;
        }
        ul {
            padding: 0;
            margin: 0;
            list-style: none;
            li {
                a {
                    color: white;
                    font-size: .75rem;
                    padding: 4px 0;
                    display: inline-block;
                    border-bottom: 1px solid transparent;
                    &:hover {
                        color: $global-color-green;
                        border-bottom: 1px solid $global-color-green;
                    }
                }
            }
        }
    }
    .container-footer-bottom {
        padding: $padding-padding*2 0 $padding-padding*8 0;
        @media (min-width: $breakpoint-small) {
            padding: $padding-padding 0;
        }
        .uk-flex {
            justify-content: space-between;
            align-items: center;
            display: block;
            @media (min-width: $breakpoint-small) {
                display: flex;
            }
        }
        ul {
            li {
                margin-right: 20px;
                .koredge-link {
                    span {
                        font-size: 1.5rem;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
    .container-picto-banque {
        margin-top: $margin-margin;
        @media (min-width: $breakpoint-small) {
            margin-top: 0;
        }
        figure {
            padding: 0;
            margin: 0;
        }
    }
}

#open-site-map {
    color: white;
    text-align: center;
    font-family: $global-heading-font-family;
    display: block;
    margin: 0 auto;
    font-size: 1.5rem;
    cursor: pointer;
    span {
        font-size: 1rem;
        display: inline-block;
    }
    &.active {
        span {
            transform: rotate(180deg);
        }
    }
}

#nav-footer {
    height: 0;
    opacity: 0;
    transition: all .2s ease-in-out;
    &.open {
        height: auto;
        opacity: 1;
        margin-top: 50px;
        .container-item-nav-footer {
            display: grid;
        }
    }
}

.section-garantis {
    width: 100%;
    background: url("../images/motif.svg") repeat center center;
    background-size: 40%;
    @media (min-width: $breakpoint-small) {
        background-size: 18%;
    }
    @media (min-width: $breakpoint-medium-light) {
        background-size: 8%;
    }
    ul {
        margin: 0 auto;
        padding: 0;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: space-evenly;
        list-style: none;
        grid-gap: 40px;
        @media (min-width: $breakpoint-small) {
            flex-flow: initial;
        }
        @media (min-width: $breakpoint-large) {
            max-width: 80%;
        }
        li {
            display: flex;
            align-items: center;
            flex-flow: column;
            text-align: center;
            .icon {
                font-size: 3rem;
                color: $global-color-black;
                @media (min-width: $breakpoint-small) {
                    //font-size: 6rem;
                }
                @media (min-width: $breakpoint-medium) {
                    font-size: 6rem;
                }
            }
            .desc {
                font-weight: 300;
            }
            .title-garantis {
                color: $global-color-black;
                font-size: .8rem;
                font-weight: 600;
                text-transform: uppercase;
                margin-bottom: 0;
                @media (min-width: $breakpoint-medium) {
                    font-size: 1.2rem;
                }
            }
        }
    }
}