//
// Component: Slider
//
// ========================================================================
.section-slider-conseillers {
    background: $global-color;
    position: relative;
    padding: 0 0 calc($padding-padding*1.5) 0;
    pattern {
        position: absolute;
        top: 0;
    }
    .container-slider {
        padding: calc($padding-padding*2) 0;
        display: block;
        @media (min-width: $breakpoint-small) {
            display: flex;
            align-items: center;
        }
        @media (min-width: $breakpoint-medium-light) {
            padding: calc($padding-padding*3) 0;
            margin-left: 60px;
        }
        @media (min-width: $breakpoint-xlarge) {
            margin-left: 15%;
        }
        .switcher-sliders {
            padding: 0;
            margin: 0;
            list-style: none;
            li {
                padding: $padding-padding 0;
                border-bottom: 1px solid $global-color;
                &:first-child {
                    border-top: 1px solid $global-color;
                }
                .btn-switcher-slider {
                    background: none;
                    color: $global-color;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-weight: 500;
                    border: none;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    text-align: left;
                    font-size: .9em;
                    transition: all .2s ease-in-out;
                    @media (min-width: $breakpoint-medium-light) {
                        font-size: .7em;
                    }
                    @media (min-width: $breakpoint-xlarge) {
                        font-size: .9em;
                    }
                    &:before {
                        content: "\e900";
                        font-family: 'icomoon';
                        font-size: 0;
                        visibility: hidden;
                        transition: all .2s ease-in-out;
                    }
                    &:hover {
                        color: $global-color;
                        &:before {
                            content: "\e900";
                            font-size: 1em;
                            visibility: visible;
                        }
                    }
                }
            }
        }
        .container-txt {
            padding: calc($padding-padding*2) $padding-padding;
            @media (min-width: $breakpoint-medium-light) {
                padding: 0;
            }
            h2 {
                color: $global-color;
                text-transform: uppercase;
                font-weight: 600;
                &:before {
                    content: "";
                    width: 60px;
                    height: 4px;
                    display: block;
                    margin-bottom: 10px;
                    background: $global-color;
                    @media (min-width: $breakpoint-medium-light) {
                        width: 80px;
                        height: 5px;
                    }
                }
            }
        }
        .slider-conseillers {
            @media (min-width: $breakpoint-small) {
                transform: translate3d(10%, 0, 0);
            }
            .container-arrows {
                width: 75%;
                display: block;
                height: 70px;
                position: absolute;
                top: 46%;
                left: 6.5%;
                @media (min-width: $breakpoint-small) {
                    width: 34%;
                }
                @media (min-width: $breakpoint-medium) {
                    width: 68%;
                }
                @media (min-width: $breakpoint-medium-light) {
                    width: 75%;
                }
                .uk-slidenav {
                    background: $global-color;
                    border-radius: 50%;
                    width: 70px;
                    height: 70px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-bottom: none;
                    transition: all .2s ease-in-out;
                    &:hover {
                        border-bottom: none;
                        background: $global-color;
                    }
                    svg {
                        color: white;
                    }
                }
            }
        }
    }
}

.item-conseiller {
    width: 80%;
    @media (min-width: $breakpoint-small) {
        width: 40%;
    }
    @media (min-width: $breakpoint-medium-light) {
        width: 43%;
    }
    @media (min-width: $breakpoint-xlarge) {
        width: 33%;
    }
    @media (min-width: $breakpoint-xlarge-max) {
        width: 25%;
    }
    .content {
        background: white;
        border: 1px solid #ADC3E6;
        .conseiller-link {
            transition: all .2s ease-in-out;
            .container-photo {
                background: linear-gradient(180deg, rgba(0, 47, 123, 1) 0%, rgba(0, 47, 123, 1) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
                display: flex;
                align-items: center;
                justify-content: center;
                padding: $padding-padding 0;
                figure {
                    padding: 0;
                    margin: 0;
                    transition: all .4s ease-in-out;
                    @media (min-width: $breakpoint-small) {
                        width: 195px;
                        height: 195px;
                    }
                    img {
                        object-fit: cover;
                        border: 6px solid $global-color;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }
                }
            }
            &:hover {
                .container-photo {
                    figure {
                        transform: translate3d(0, -10px, 0);
                    }
                }
            }
        }
        .container-infos-conseiller {
            padding: $padding-padding;
            border-bottom: 6px solid $global-color;
            text-align: center;
            min-height: 400px;
            @media (min-width: $breakpoint-small) {
                padding: calc($padding-padding*2);
            }
            @media (min-width: $breakpoint-medium-light) {
                padding: 0 calc($padding-padding*2) calc($padding-padding*2) calc($padding-padding*2);
            }
            @media (min-width: $breakpoint-xxlarge) {
                min-height: 400px;
            }
            @media (min-width: $breakpoint-xlarge-max) {
                min-height: 500px;
            }
            .name {
                h3,
                h2,
                p {
                    text-transform: uppercase;
                    color: $global-color;
                    border-bottom: none;
                    font-weight: 600;
                    font-size: 1.2em;
                    margin: 0 0 calc($margin-margin/2) 0;
                    transition: all .2s ease-in-out;
                    &:before {
                        content: none;
                    }
                    &:hover {
                        border-bottom: none;
                        color: $global-color;
                    }
                }
            }
            p {
                margin: 0;
                padding: 0 10px;
                &.role {
                    color: $global-color;
                    font-weight: bold;
                }
                &.location {
                    color: $global-color;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: .8em;
                    span {
                        font-size: 1.5em;
                        @media (min-width: $breakpoint-small) {
                            font-size: 2em;
                        }
                    }
                }
                &.description {
                    color: $global-color;
                    font-size: .8em;
                    margin: $margin-margin 0 0 0;
                    @media (min-width: $breakpoint-small) {
                        font-size: .9em;
                    }
                }
            }
            .video {
                color: $global-color;
                text-decoration: underline;
                font-size: .9em;
                border-bottom: none;
                justify-content: center;
                &:hover {
                    border-bottom: none;
                    color: $global-color;
                }
            }
        }
    }
}

.container-tag {
    margin: $margin-margin 0 0 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    justify-content: center;
    li {
        background: $global-color;
        color: $global-color;
        border-radius: 25px;
        font-size: .6em;
        display: table;
        text-transform: uppercase;
        font-weight: 600;
        padding: calc($padding-padding/4) calc($padding-padding/2);
        @media (min-width: $breakpoint-small) {
            font-size: .8em;
        }
    }
}