//
// Component: Détail produits
//
// Détail produits
// ========================================================================
.section-detail-produit {
    .container-swiper-detail {
        padding: .5rem;
        border: 1px solid $global-color-soft-gray;
        height: 50vh;
        position: relative;
        @media (min-width: $breakpoint-medium) {
            height: 46vh;
        }
        @media (min-width: $breakpoint-large) {
            height: 55vh;
        }
        @media (min-width: $breakpoint-xxlarge) {
            height: 57vh;
        }
        @media (min-width: $breakpoint-xlarge-max) {
            height: 45vh;
        }
        .swiper-wrapper {
            margin: 0;
            padding: 0;
        }
        .swiper {
            width: 100%;
            height: 100%;
        }
        .swiper-slide {
            text-align: center;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            figure {
                padding: 0;
                margin: 0;
                height: 100%;
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .swiper {
            width: 100%;
            height: 300px;
            margin-left: auto;
            margin-right: auto;
        }
        .swiper-details-thumbs {
            height: 70%;
            width: 100%;
        }
        .swiper-details {
            height: auto;
            max-height: 175px;
            box-sizing: border-box;
            padding: 10px 0;
            position: relative;
            bottom: 10px;
            @media (min-width: $breakpoint-xlarge) {
                height: 175px;
            }
            .swiper-slide {
                width: 25%;
                height: 100%;
                border: 1px solid $global-color-soft-gray;
                cursor: pointer;
            }
        }
        .swiper-details .swiper-slide-thumb-active {
            opacity: 1;
        }
    }
    .container-content-detail {
        padding-left: 0;
        @media (min-width: $breakpoint-medium) {
            padding-left: 60px;
        }
        h1 {
            text-shadow: none;
            color: $global-color-black;
            font-size: 1.5em;
            @media (min-width: $breakpoint-medium) {
                font-size: 1.5em;
            }
            @media (min-width: $breakpoint-medium-light) {
                font-size: 2em;
            }
            @media (min-width: $breakpoint-xlarge) {
                font-size: 3.1em;
            }
        }
        .ref {
            font-size: .8rem;
            display: block;
        }
        .container-flex-price {
            display: flex;
            flex-flow: column;
            @media (min-width: $breakpoint-medium-light) {
                flex-flow: row wrap;
                align-items: center;
            }
            .container-prices {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                flex-flow: column;
                margin-right: 25px;
                @media (min-width: $breakpoint-medium-light) {
                    grid-gap: 20px;
                    align-items: center;
                    flex-flow: row wrap;
                }
                .old-price {
                    font-weight: 600;
                    font-size: 1rem;
                    line-height: 1;
                    padding: 0;
                    margin: 0;
                    text-decoration: line-through;
                    color: $global-color-black;
                    @media (min-width: $breakpoint-small) {
                        font-size: 1.2rem;
                    }
                }
                /* .price {
                    font-weight: 600;
                    font-size: 1.5rem;
                    line-height: 1;
                    color: $global-color-black;
                    padding: 0;
                    margin: 0;
                    @media (min-width: $breakpoint-small) {
                        font-size: 2rem;
                    }
                } */
            }
            #alma-widget {
                margin-bottom: 1rem;
            }
            .price {
                padding: 0;
                font-size: 1.5rem;
                font-weight: bold;
                margin: 0;
                color: $global-color-black;
                @media (min-width: $breakpoint-medium-light) {
                    font-size: 2rem;
                    margin: 0 1rem 0 0;
                }
            }
            .statut {
                p {
                    margin: 0;
                    padding: 0;
                }
                .stock-ok {
                    display: flex;
                    align-items: center;
                    span {
                        color: $global-color-green;
                        font-size: 2rem;
                        margin-right: 10px;
                    }
                }
                .out-of-stock {
                    display: flex;
                    align-items: center;
                    color: #e60e0e;
                    span {
                        font-size: 2rem;
                        margin-right: 10px;
                        transform: rotate( 45deg);
                    }
                }
                .stock-orange {
                    display: flex;
                    align-items: center;
                    color: orange;
                    span {
                        font-size: 2rem;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    .container-flex-quantity {
        align-items: center;
    }
    .container-flex-detail-produit,
    .container-flex-quantity {
        display: flex;
        flex-flow: column;
        @media (min-width: $breakpoint-small) {
            flex-flow: initial;
            justify-content: space-between;
        }
        @media (min-width: $breakpoint-medium) {
            flex-flow: column;
        }
        @media (min-width: $breakpoint-large) {
            align-items: center;
            justify-content: center;
            flex-flow: initial;
            justify-content: initial;
            grid-gap: 20px;
        }
        .container-select-field {
            @media (min-width: $breakpoint-small) {
                margin: 0;
            }
            select {
                min-width: 180px;
                z-index: 10;
            }
            .icon-arrow-down {
                position: absolute;
                right: 0;
                z-index: 0;
            }
        }
        .container-price__item {
            form {
                display: flex;
                align-items: center;
                gap: 10px;
                .uk-form-controls {
                  .kg-form__input {
                    height: 45px;
                    line-height: 45px;
                    padding: 0 20px;
                    min-width: 250px;
                    border-radius: 25px;
                    border: none;
                    background: #f8f8f8;
                    color: #1D1719;
                  }
                }
                .container-price__item--buttons {
                    .kg-button:first-of-type {
                        background: $global-color-green;
                        color: white;
                        padding: 10px 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-family: $global-heading-font-family;
                        font-size: 1.2rem;
                        border-radius: 50px;
                        font-weight: 400;
                        letter-spacing: 1px;
                        cursor: pointer;
                        border: 1px solid transparent;
                        transition: all .2s ease-in-out;
                        &:hover {
                            background: white;
                            color: $global-color-black;
                            border: 1px solid $global-color-green;
                        }
                    }
                }
            }
        }
    }
    .container-flex-detail-produit {
        .container-select-field {
            margin: .5rem 0;
        }
    }
    .qty-input {
        color: $global-color-black;
        background: white;
        display: flex;
        align-items: center;
        overflow: hidden;
        .product-qty,
        .qty-count {
            background: transparent;
            color: inherit;
            font-size: inherit;
            border: none;
            display: inline-block;
            min-width: 0;
            height: 2.5rem;
            line-height: 1;
        }
        .product-qty {
            width: 50px;
            min-width: 0;
            display: inline-block;
            text-align: center;
            appearance: textfield;
            font-family: $global-heading-font-family;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                appearance: none;
                margin: 0;
            }
        }
        .qty-count {
            padding: 0;
            cursor: pointer;
            width: 2.5rem;
            font-size: 1.25em;
            text-indent: -100px;
            overflow: hidden;
            position: relative;
            font-weight: bold;
            background: #E5E1E2;
            transition: all .3s ease-in-out;
            &:before,
            &:after {
                content: "";
                height: 2px;
                width: 10px;
                position: absolute;
                display: block;
                background: black;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
            }
            &:hover {
                background: $global-color-green;
                &:before,
                &:after {
                    background: white;
                }
            }
            &--minus {
                border-right: 1px solid $global-color-soft-gray;
            }
            &--add {
                border-left: 1px solid $global-color-soft-gray;
                &:after {
                    transform: rotate(90deg);
                }
            }
            &:disabled {
                color: $global-color-gray;
                background: #f2f2f2;
                cursor: not-allowed;
                border-color: transparent;
                &:before,
                &:after {
                    background: $global-color-gray;
                }
            }
        }
    }
    .container-declinaisons {
        border-top: 1px solid $global-color-soft-gray;
        padding-top: $padding-padding;
    }
}