//
// Component: Card
//
// Card
// ========================================================================
.uk-card-default {
    background: transparent;
    box-shadow: none;
    position: relative;
    border: 1px solid transparent;
    transition: all .3s ease-in-out;
    &:hover {
        border: 1px solid $global-color-soft-gray;
        //box-shadow: 0 0 5px $global-color-green;
        figure {
            border: 1px solid transparent;
        }
    }
    .container-tags {
        position: absolute;
        top: 10px;
        left: 10px;
    }
    .tag {
        background: $global-color-green;
        color: white;
        border-radius: 20px;
        text-transform: uppercase;
        padding: 5px 10px;
        font-size: .6rem;
        margin-right: 10px;
        @media (min-width: $breakpoint-small) {
            padding: 5px 20px;
            font-size: .8rem;
        }
    }
    figure {
        padding: calc($padding-padding/1.5);
        margin: 0;
        width: 100%;
        height: 150px;
        border: 1px solid $global-color-soft-gray;
        transition: all .3s ease-in-out;
        background: white;
        @media (min-width: $breakpoint-small) {
            padding: $padding-padding;
            height: 265px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .content-card {
        padding: 10px;
        text-align: center;
        background: white;
        @media (min-width: $breakpoint-small) {
            padding: 40px 20px;
        }
        .title {
            font-weight: 600;
            font-size: .7rem;
            margin: 10px 0 0 0;
            color: $global-color-black;
            min-height: 60px;
            @media (min-width: $breakpoint-small) {
                font-size: 1.1rem;
            }
        }
        .desc {
            font-size: .7rem;
            color: $global-color-gray;
            margin: 0;
            min-height: 80px;
            display: flex;
            /* align-items: center;
            justify-content: center; */
            @media (min-width: $breakpoint-small) {
                font-size: .9rem;
            }
        }
        .container-prices {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-flow: column;
            margin: 1rem 0;
            @media (min-width: $breakpoint-small) {
                flex-flow: initial;
                align-items: flex-end;
                grid-gap: 20px;
            }
            .old-price {
                font-weight: 600;
                font-size: .7rem;
                line-height: 1;
                padding: 0;
                margin: 0;
                text-decoration: line-through;
                color: $global-color-black;
                @media (min-width: $breakpoint-small) {
                    font-size: .9rem;
                }
            }
            .price {
                font-weight: 600;
                font-size: .9rem;
                line-height: 1;
                color: $global-color-black;
                padding: 0;
                margin: 0;
                @media (min-width: $breakpoint-small) {
                    font-size: 1.2rem;
                }
            }
        }
        .add-card-button {
            background: $global-color-soft-gray;
            width: 65px;
            height: 65px;
            border-radius: 50%;
            cursor: pointer;
            transition: all .3s ease-in-out;
            span {
                font-size: 2rem;
                color: $global-color-black;
                transition: all .3s ease-in-out;
            }
            &:hover {
                background: $global-color-green;
                span {
                    color: white;
                }
            }
        }
    }
    &.card-promo {
        display: flex;
        //background: white;
        width: 100%;
        .detail-link {
            background: white;
            width: 50%;
            display: block;
            figure {
                height: 100%;
                max-height: 320px;
                @media (min-width: $breakpoint-small) {
                    max-height: 320px;
                }
            }
        }
        .detail-link-content {
            width: 50%;
            max-height: 320px;
        }
        .content-card {
            height: 100%;
            text-align: left;
            background: #F9F7F7;
        }
        .container-prices {
            justify-content: initial;
        }
        .container-flex-price-promo {
            @media (min-width: $breakpoint-small) {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}