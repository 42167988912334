//
// Component: Page type
//
// Page type
// ========================================================================
.section {
    padding: 1.5rem 0;
    &.txt,
    &.txt_txt,
    &.img,
    &.txt_img,
    &.img_txt {
        overflow: hidden;
        .uk-grid {
            .uk-first-column {
                padding-left: 0;
            }
        }
        /*  h2 {
            font-size: 1.3em;
            @media (min-width: $breakpoint-small) {
                font-size: 1.2em;
            }
            @media (min-width: $breakpoint-medium-light) {
                font-size: 1.2em;
            }
        }
        h3 {
            color: $global-color;
            font-family: $global-font-family;
            font-weight: 600;
        } */
        figure {
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
    &.txt_img,
    &.img_txt {
        .uk-grid {
            align-items: center;
        }
    }
    &.img_img {
        .uk-grid {
            div {
                padding: 0;
                margin: calc($margin-margin/2) 0;
                @media (min-width: $breakpoint-medium) {
                    padding-left: 10px;
                    margin: 0;
                }
                &.uk-first-column {
                    padding: 0;
                    @media (min-width: $breakpoint-medium) {
                        padding-left: 0;
                        padding-right: 10px;
                    }
                }
            }
        }
    }
    &.section-1-2 {
        .uk-grid {
            .uk-width-1-2 {
                width: 100%;
                @media (min-width: $breakpoint-medium-light) {
                    width: 50%;
                }
            }
        }
    }
    &.section-1-3 {
        .uk-grid {
            .uk-width-1-3 {
                width: 100%;
                @media (min-width: $breakpoint-medium-light) {
                    width: calc(100% * 1 / 3.001);
                }
            }
            .uk-width-2-3 {
                width: 100%;
                @media (min-width: $breakpoint-medium-light) {
                    width: calc(100% * 2 / 3.001);
                }
            }
        }
    }
    &.carousel,
    &.galerie-photos {
        .uk-grid {
            display: block;
            .uk-grid {
                div {
                    padding-left: 10px;
                    &.uk-first-column {
                        padding-left: 0;
                        padding-right: 10px;
                    }
                }
            }
            .container-carousel-page-type {
                padding-left: 0;
                .uk-slideshow-items {
                    min-height: 170px!important;
                    @media (min-width: $breakpoint-small) {
                        min-height: 300px!important;
                    }
                    @media (min-width: $breakpoint-xlarge) {
                        min-height: 600px!important;
                    }
                    @media (min-width: $breakpoint-xlarge-max) {
                        min-height: 800px!important;
                    }
                }
                figure {
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    height: 100%;
                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .album {
                display: flex;
                div {
                    padding-left: 10px;
                    padding-bottom: 20px;
                    &.uk-first-column {
                        padding-left: 0;
                        padding-right: 10px;
                    }
                }
                figure {
                    margin: 0;
                    padding: 0;
                    height: 90px;
                    @media (min-width: $breakpoint-small) {
                        height: 210px;
                    }
                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
                .uk-first-column {
                    //padding-left: 0;
                }
            }
        }
        .uk-slidenav {
            background: $global-color;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: none;
            transition: all .2s ease-in-out;
            @media (min-width: $breakpoint-small) {
                width: 60px;
                height: 60px;
            }
            svg {
                color: white;
            }
        }
    }
    &.txt {
        iframe {
            position: relative!important;
            display: block;
            margin: 0 auto;
            max-width: 100%;
        }
    }
    &.section-promos {
        background: url('../images/background-promo.jpg') no-repeat center center;
        background-size: cover;
        max-width: 100%;
        margin: 0 auto;
        @media (min-width: $breakpoint-medium) {
            max-width: 90%;
            margin: 0 auto 60px auto;
        }
        h2 {
            color: white;
            &:before {
                background: white;
            }
        }
        .content-link {
            color: white;
            padding: 5px 20px;
            border-radius: 50px;
            background: $global-color-green;
            transition: all .2s ease-in-out;
            margin: 0;
            position: relative!important;
            @media (min-width: $breakpoint-medium) {
                position: absolute!important;
                max-width: 90%;
                margin: 0 auto 60px auto;
            }
            &:hover {
                background: white;
                color: $global-color-green;
                &:before {
                    background: $global-color-green;
                }
            }
            &:before {
                background: white;
                transition: all .2s ease-in-out;
            }
            span {
                border-bottom: none;
            }
        }
    }
    &.section-meilleures-ventes {
        .uk-container {
            max-width: 90%;
            @media (min-width: $breakpoint-xxlarge) {
                max-width: 1680px;
            }
        }
        .container-meilleures-ventes {
            position: relative;
            figure {
                width: 100%;
                padding: 0;
                height: 300px;
                @media (min-width: $breakpoint-small) {
                    height: 695px;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
            .img {
                padding-left: 0;
                @media (min-width: $breakpoint-small) {
                    padding-left: 20px;
                }
            }
            .content {
                /*    padding: $padding-padding $padding-padding*1.5;
                @media (min-width: $breakpoint-medium-light) {
                    padding: $padding-padding*2 $padding-padding*3;
                } */
            }
        }
        .content-link {
            position: relative!important;
        }
        .container-listing-meilleures-ventes {
            position: relative;
            background: white;
            @media (min-width: $breakpoint-medium) {
                padding: 40px;
                width: 75%;
                transform: translate3d(-33%, 0, 0);
                left: 50%;
                margin-top: -31vh;
            }
            .listing {
                grid-template-columns: repeat(2, 1fr);
                @media (min-width: $breakpoint-small) {
                    grid-template-columns: repeat(3, 1fr);
                }
                li {
                    &:last-child {
                        display: none;
                        @media (min-width: $breakpoint-small) {
                            display: block;
                        }
                    }
                }
                figure {
                    height: 150px;
                    padding: 20px;
                    @media (min-width: $breakpoint-small) {
                        height: 265px;
                    }
                    img {
                        object-fit: contain;
                    }
                }
            }
        }
    }
}