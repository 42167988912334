//
// Component: Button
//
// ========================================================================
button {
    background: none;
    border: none;
}

.full-button {
    background: $global-color-green;
    color: white;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $global-heading-font-family;
    font-size: 1.2rem;
    border-radius: 50px;
    font-weight: 400;
    letter-spacing: 1px;
    cursor: pointer;
    border: 1px solid transparent;
    transition: all .2s ease-in-out;
    span {
        color: white;
        font-size: 2rem;
        margin-right: 10px;
        transition: all .2s ease-in-out;
    }
    &:hover {
        background: white;
        color: $global-color-black;
        border: 1px solid $global-color-green;
        span {
            color: $global-color-black;
        }
    }
}

.submit-button {
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $global-color-green;
    height: 50px;
    width: 100%;
    font-family: $global-heading-font-family;
    font-size: 1rem;
    border-radius: 25px;
    cursor: pointer;
    transition: all .3s ease-in-out;
    &:hover {
        background: black;
        color: white;
    }
    &.submit-button-black {
        background: black;
        &:hover {
            background: $global-color-green;
        }
    }
    &.pdf-button {
        max-width: 350px;
        margin-left: auto;
    }
    &__pieces__obligatoires {
        background: $global-color;
        &:hover {
            background: $global-color-black;
        }
    }
}