/**
 * Default Theme Variables
 */

// ================================================================================
// Colors
// ================================================================================
$global-color: #5B5456 !default;
$global-inverse-color: #ffffff !default;
$global-emphasis-color: #333333 !default;
$global-muted-color: #ffffff !default;
$title-footer-color: #D0D0D0 !default;
$global-color-white: #ffffff !default;
$global-color-black: #1D1719 !default;
$global-color-gray: #5B5456 !default;
$global-color-sub-nav: #E9E9E9 !default;
$global-color-soft-gray: #E5E1E2 !default;
$global-color-dark-gray: #484D4D !default;
$global-color-green: #789904 !default;
$global-color-background-header-gray: #F1F2F4 !default;
$global-color-background-filtres: #e9e9e9 !default;
$global-color-facebook:#3b5998 !default;
$global-color-instagram:#4E7DA2 !default;
$global-color-youtube:#c4302b !default;

/** Links Colors */

$global-link-color: #1e87f0 !default;

/** Inverse */

$inverse-global-color: rgba($global-inverse-color, .7) !default;
$inverse-global-emphasis-color: $global-inverse-color !default;
$inverse-global-muted-color: rgba($global-inverse-color, .5) !default;
$inverse-global-inverse-color: $global-color !default;
// ================================================================================
// Background
// ================================================================================
$global-background: black !default;
$global-primary-background: #414A59 !default;
$global-secondary-background: #222222 !default;
$global-muted-background: #f8f8f8 !default;
$global-success-background: #32d296 !default;
$global-warning-background: #faa05a !default;
$global-danger-background: #f0506e !default;
$background-default-background: $global-background!default;
$background-muted-background: $global-muted-background !default;
$background-primary-background: $global-primary-background !default;
$background-secondary-background: $global-secondary-background !default;

/** Inverse */

$inverse-global-primary-background: $global-inverse-color !default;
// ================================================================================
// Margins
// ================================================================================
$global-margin: 20px !default;
$global-small-margin: 10px !default;
$global-medium-margin: 40px !default;
$global-large-margin: 70px !default;
$global-margin-auto: 0 auto !default;

/** Gutter */

$global-gutter: 20px !default;
$global-small-gutter: 15px !default;
$global-medium-gutter: 40px !default;
$global-large-gutter: 60px !default;
// ================================================================================
// Fonts
// ================================================================================
$global-font-family: 'Poppins',
sans-serif;
$global-heading-font-family: 'Oswald',
sans-serif;
$global-extra-font-family: 'Poppins',
sans-serif;
// ================================================================================
// Fonts Size
// ================================================================================
$global-small-font-size: .875rem !default;
$global-font-size: 18px !default;
$global-medium-font-size: 1.25rem !default;
$global-large-font-size: 1.5rem !default;
$global-xlarge-font-size: 2rem !default;
$global-xxlarge-font-size: 2.625rem !default;

/** Line Height  */

$global-line-height: 1.5 !default;
// ================================================================================
// Border
// ================================================================================
$global-border-width: 1px !default;
$global-border: rgba(65, 74, 89, 0.2) !default;

/** Inverse */

$inverse-global-border: rgba($global-inverse-color, .2) !default;
// ================================================================================
// Control
// ================================================================================
$global-control-height: 40px !default;
$global-control-small-height: 30px !default;
$global-control-large-height: 55px !default;
// ================================================================================
// Z-Index
// ================================================================================
$global-z-index: 1000 !default;
// ================================================================================
// Box Shadow
// ================================================================================
$global-small-box-shadow: 0 2px 8px rgba(0, 0, 0, .08) !default;
$global-medium-box-shadow: 0 5px 15px rgba(0, 0, 0, .08) !default;
$global-large-box-shadow: 0 14px 25px rgba(0, 0, 0, .16) !default;
$global-xlarge-box-shadow: 0 28px 50px rgba(0, 0, 0, .16) !default;
// ================================================================================
// Accordion
// ================================================================================
$accordion-item-margin-top: $global-margin !default;
$accordion-title-font-size: $global-medium-font-size !default;
$accordion-title-line-height: 1.4 !default;
$accordion-title-color: $global-emphasis-color !default;
$accordion-title-hover-color: $global-color !default;
$accordion-content-margin-top: $global-margin !default;

/** Inverse */

$inverse-accordion-title-color: $inverse-global-emphasis-color !default;
$inverse-accordion-title-hover-color: $inverse-global-color !default;
// ================================================================================
// Alerts
// ================================================================================
$alert-margin-vertical: $global-margin !default;
$alert-padding: $global-small-gutter !default;
$alert-padding-right: $alert-padding+14px !default;
$alert-background: $global-muted-background !default;
$alert-color: $global-color !default;
$alert-close-top: $alert-padding+5px !default;
$alert-close-right: $alert-padding !default;

/** Primary */

$alert-primary-background: lighten(mix(white, $global-primary-background, 40%), 20%) !default;
$alert-primary-color: $global-primary-background !default;

/** Success */

$alert-success-background: lighten(mix(white, $global-success-background, 40%), 25%) !default;
$alert-success-color: $global-success-background !default;

/** Warning */

$alert-warning-background: lighten(mix(white, $global-warning-background, 45%), 15%) !default;
$alert-warning-color: $global-warning-background !default;

/** Danger */

$alert-danger-background: lighten(mix(white, $global-danger-background, 40%), 20%) !default;
$alert-danger-color: $global-danger-background !default;
// ================================================================================
// Align
// ================================================================================
$align-margin-horizontal: $global-gutter !default;
$align-margin-vertical: $global-gutter !default;
$align-margin-horizontal-l: $global-medium-gutter !default;
// ================================================================================
// Animations
// ================================================================================
$animation-duration: .5s !default;
$animation-fade-duration: .8s !default;
$animation-stroke-duration: 2s !default;
$animation-kenburns-duration: 15s !default;
$animation-fast-duration: .1s !default;
$animation-slide-small-translate: 10px !default;
$animation-slide-medium-translate: 50px !default;
// ================================================================================
// Articles
// ================================================================================
$article-margin-top: $global-large-margin !default;
$article-title-font-size-m: $global-xxlarge-font-size !default;
$article-title-font-size: $article-title-font-size-m * .85 !default;
$article-title-line-height: 1.2 !default;
$article-meta-font-size: $global-small-font-size !default;
$article-meta-line-height: 1.4 !default;
$article-meta-color: $global-muted-color !default;
$inverse-article-meta-color: $inverse-global-muted-color !default;
// ================================================================================
// Base
// ================================================================================

/** Body */

$base-body-color: $global-color !default;
$base-body-background: $global-background!default;
$base-body-font-size: $global-font-size !default;
$base-body-line-height: $global-line-height !default;
$base-body-font-family: $global-font-family !default;
$base-body-font-weight: normal !default;

/** Link */

$base-link-color: $global-link-color !default;
$base-link-text-decoration: none !default;
$base-link-hover-color: $global-color !default;
$base-link-hover-text-decoration: underline !default;

/** Font Weight */

$base-strong-font-weight: bolder !default;
$base-em-color: $global-danger-background !default;

/** Code */

$base-code-font-size: $global-small-font-size !default;
$base-code-font-family: Consolas,
monaco,
monospace !default;
$base-code-color: $global-danger-background !default;

/** Ins */

$base-ins-background: #ffffdd !default;
$base-ins-color: $global-color !default;

/** Mark  */

$base-mark-background: #ffffdd !default;
$base-mark-color: $global-color !default;

/** Quote */

$base-quote-font-style: italic !default;
$base-small-font-size: 80% !default;

/** Margin */

$base-margin-vertical: $global-margin !default;

/** Heading */

$base-heading-font-family: $global-font-family !default;
$base-heading-font-weight: normal !default;
$base-heading-color: $global-emphasis-color !default;
$base-heading-text-transform: none !default;
$base-heading-margin-top: $global-medium-margin !default;

/** H1 */

$base-h1-font-size-m: $global-xxlarge-font-size !default;
$base-h1-font-size: $base-h1-font-size-m * .85 !default;
$base-h1-line-height: 1.2 !default;

/** H2 */

$base-h2-font-size-m: $global-xlarge-font-size !default;
$base-h2-font-size: $base-h2-font-size-m * .85 !default;
$base-h2-line-height: 1.3 !default;

/** H3 */

$base-h3-font-size: $global-large-font-size !default;
$base-h3-line-height: 1.4 !default;

/** H4 */

$base-h4-font-size: $global-medium-font-size !default;
$base-h4-line-height: 1.4 !default;

/** H5 */

$base-h5-font-size: $global-font-size !default;
$base-h5-line-height: 1.4 !default;

/** H6 */

$base-h6-font-size: $global-small-font-size !default;
$base-h6-line-height: 1.4 !default;

/** List */

$base-list-padding-left: 30px !default;

/** HR */

$base-hr-margin-vertical: $global-margin !default;
$base-hr-border-width: $global-border-width !default;
$base-hr-border: $global-border !default;

/** Blockquote */

$base-blockquote-font-size: $global-medium-font-size !default;
$base-blockquote-line-height: 1.5 !default;
$base-blockquote-font-style: italic !default;
$base-blockquote-margin-vertical: $global-margin !default;
$base-blockquote-footer-margin-top: $global-small-margin !default;
$base-blockquote-footer-font-size: $global-small-font-size !default;
$base-blockquote-footer-line-height: 1.5 !default;

/** Pre */

$base-pre-font-size: $global-small-font-size !default;
$base-pre-line-height: 1.5 !default;
$base-pre-font-family: $base-code-font-family !default;
$base-pre-color: $global-color !default;

/** Selection  */

$base-selection-background: #3399ff !default;
$base-selection-color: $global-inverse-color !default;

/** Inverse */

$inverse-base-color: $inverse-global-color !default;
$inverse-base-link-color: $inverse-global-emphasis-color !default;
$inverse-base-link-hover-color: $inverse-global-emphasis-color !default;
$inverse-base-code-color: $inverse-global-color !default;
$inverse-base-em-color: $inverse-global-emphasis-color !default;
$inverse-base-heading-color: $inverse-global-emphasis-color !default;
$inverse-base-hr-border: $inverse-global-border !default;
// ================================================================================
// Badges
// ================================================================================
$badge-size: 22px !default;
$badge-padding-vertical: 0 !default;
$badge-padding-horizontal: 5px !default;
$badge-border-radius: 500px !default;
$badge-background: $global-primary-background !default;
$badge-color: $global-inverse-color !default;
$badge-font-size: $global-small-font-size !default;
$badge-hover-color: $global-inverse-color !default;

/** Inverse */

$inverse-badge-background: $inverse-global-primary-background !default;
$inverse-badge-color: $inverse-global-inverse-color !default;
$inverse-badge-hover-color: $inverse-global-inverse-color !default;
// ================================================================================
// Breadcrumbs
// ================================================================================
$breadcrumb-item-font-size: $global-small-font-size !default;
$breadcrumb-item-color: $global-muted-color !default;
$breadcrumb-item-hover-color: $global-color !default;
$breadcrumb-item-hover-text-decoration: none !default;
$breadcrumb-item-active-color: $global-color !default;
$breadcrumb-divider: "/" !default;
$breadcrumb-divider-margin-horizontal: 20px !default;
$breadcrumb-divider-color: $global-muted-color !default;

/** Inverse  */

$inverse-breadcrumb-item-color: $inverse-global-muted-color !default;
$inverse-breadcrumb-item-hover-color: $inverse-global-color !default;
$inverse-breadcrumb-item-active-color: $inverse-global-color !default;
$inverse-breadcrumb-divider-color: $inverse-global-muted-color !default;
// ================================================================================
// Buttons
// ================================================================================
$button-line-height: $global-control-height !default;
$button-small-line-height: $global-control-small-height !default;
$button-large-line-height: $global-control-large-height !default;
$button-font-size: $global-font-size !default;
$button-small-font-size: $global-small-font-size !default;
$button-large-font-size: $global-medium-font-size !default;
$button-padding-horizontal: $global-gutter !default;
$button-small-padding-horizontal: $global-small-gutter !default;
$button-large-padding-horizontal: $global-medium-gutter !default;

/** Defaults */

$button-default-background: $global-muted-background !default;
$button-default-color: $global-emphasis-color !default;
$button-default-hover-background: darken($button-default-background, 5%) !default;
$button-default-hover-color: $global-emphasis-color !default;
$button-default-active-background: darken($button-default-background, 10%) !default;
$button-default-active-color: $global-emphasis-color !default;
$inverse-button-default-background: $inverse-global-primary-background !default;
$inverse-button-default-color: $inverse-global-inverse-color !default;
$inverse-button-default-hover-background: darken($inverse-button-default-background, 5%) !default;
$inverse-button-default-hover-color: $inverse-global-inverse-color !default;
$inverse-button-default-active-background: darken($inverse-button-default-background, 10%) !default;
$inverse-button-default-active-color: $inverse-global-inverse-color !default;

/** Primary */

$button-primary-background: $global-primary-background !default;
$button-primary-color: $global-inverse-color !default;
$button-primary-hover-background: darken($button-primary-background, 5%) !default;
$button-primary-hover-color: $global-inverse-color !default;
$button-primary-active-background: darken($button-primary-background, 10%) !default;
$button-primary-active-color: $global-inverse-color !default;
$inverse-button-primary-background: $inverse-global-primary-background !default;
$inverse-button-primary-color: $inverse-global-inverse-color !default;
$inverse-button-primary-hover-background: darken($inverse-button-primary-background, 5%) !default;
$inverse-button-primary-hover-color: $inverse-global-inverse-color !default;
$inverse-button-primary-active-background: darken($inverse-button-primary-background, 10%) !default;
$inverse-button-primary-active-color: $inverse-global-inverse-color !default;

/** Secondary */

$button-secondary-background: $global-secondary-background !default;
$button-secondary-color: $global-inverse-color !default;
$button-secondary-hover-background: darken($button-secondary-background, 5%) !default;
$button-secondary-hover-color: $global-inverse-color !default;
$button-secondary-active-background: darken($button-secondary-background, 10%) !default;
$button-secondary-active-color: $global-inverse-color !default;
$inverse-button-secondary-background: $inverse-global-primary-background !default;
$inverse-button-secondary-color: $inverse-global-inverse-color !default;
$inverse-button-secondary-hover-background: darken($inverse-button-secondary-background, 5%) !default;
$inverse-button-secondary-hover-color: $inverse-global-inverse-color !default;
$inverse-button-secondary-active-background: darken($inverse-button-secondary-background, 10%) !default;
$inverse-button-secondary-active-color: $inverse-global-inverse-color !default;

/** Danger */

$button-danger-background: $global-danger-background !default;
$button-danger-color: $global-inverse-color !default;
$button-danger-hover-background: darken($button-danger-background, 5%) !default;
$button-danger-hover-color: $global-inverse-color !default;
$button-danger-active-background: darken($button-danger-background, 10%) !default;
$button-danger-active-color: $global-inverse-color !default;

/** Disabled */

$button-disabled-background: $global-muted-background !default;
$button-disabled-color: $global-muted-color !default;
$button-text-line-height: $global-line-height !default;

/** Text */

$button-text-color: $global-muted-color !default;
$button-text-hover-color: $global-color !default;
$button-text-disabled-color: $global-muted-color !default;
$inverse-button-text-color: $inverse-global-muted-color !default;
$inverse-button-text-hover-color: $inverse-global-color !default;
$inverse-button-text-disabled-color: $inverse-global-muted-color !default;

/** Link */

$button-link-line-height: $global-line-height !default;
$button-link-color: $global-link-color !default;
$button-link-hover-color: $global-color !default;
$button-link-hover-text-decoration: underline !default;
$button-link-disabled-color: $global-muted-color !default;
$inverse-button-link-color: $inverse-global-muted-color !default;
$inverse-button-link-hover-color: $inverse-global-color !default;
// ================================================================================
// Buttons
// ================================================================================
$card-body-padding-horizontal: $global-gutter !default;
$card-body-padding-vertical: $global-gutter !default;
$card-body-padding-horizontal-l: $global-medium-gutter !default;
$card-body-padding-vertical-l: $global-medium-gutter !default;
$card-header-padding-horizontal: $global-gutter !default;
$card-header-padding-vertical: round(calc($global-gutter / 2)) !default;
$card-header-padding-horizontal-l: $global-medium-gutter !default;
$card-header-padding-vertical-l: round(calc($global-medium-gutter / 2)) !default;
$card-footer-padding-horizontal: $global-gutter !default;
$card-footer-padding-vertical: calc($global-gutter / 2) !default;
$card-footer-padding-horizontal-l: $global-medium-gutter !default;
$card-footer-padding-vertical-l: round(calc($global-medium-gutter / 2)) !default;
$card-title-font-size: $global-large-font-size !default;
$card-title-line-height: 1.4 !default;
$card-badge-top: $global-gutter !default;
$card-badge-right: $card-badge-top !default;
$card-hover-background: $global-muted-background !default;
$card-default-background: $global-muted-background !default;
$card-default-color: $global-color !default;
$card-default-title-color: $global-emphasis-color !default;
$card-default-hover-background: darken($card-default-background, 5%) !default;
$card-primary-background: $global-primary-background !default;
$card-primary-color: $global-inverse-color !default;
$card-primary-title-color: $card-primary-color !default;
$card-primary-hover-background: darken($card-primary-background, 5%) !default;
$card-primary-color-mode: light !default;
$card-secondary-background: $global-secondary-background !default;
$card-secondary-color: $global-inverse-color !default;
$card-secondary-title-color: $card-secondary-color !default;
$card-secondary-hover-background: darken($card-secondary-background, 5%) !default;
$card-secondary-color-mode: light !default;
$card-small-body-padding-horizontal: $global-margin !default;
$card-small-body-padding-vertical: $global-margin !default;
$card-small-header-padding-horizontal: $global-margin !default;
$card-small-header-padding-vertical: round(calc($global-margin / 1.5)) !default;
$card-small-footer-padding-horizontal: $global-margin !default;
$card-small-footer-padding-vertical: round(calc($global-margin / 1.5)) !default;
$card-large-body-padding-horizontal-l: $global-large-gutter !default;
$card-large-body-padding-vertical-l: $global-large-gutter !default;
$card-large-header-padding-horizontal-l: $global-large-gutter !default;
$card-large-header-padding-vertical-l: round(calc($global-large-gutter / 2)) !default;
$card-large-footer-padding-horizontal-l: $global-large-gutter !default;
$card-large-footer-padding-vertical-l: round(calc($global-large-gutter / 2)) !default;
// ================================================================================
// Close
// ================================================================================
$close-color: $global-muted-color !default;
$close-hover-color: $global-color !default;
$inverse-close-color: $inverse-global-muted-color !default;
$inverse-close-hover-color: $inverse-global-color !default;
// ================================================================================
// Columns
// ================================================================================
$column-gutter: $global-gutter !default;
$column-gutter-l: $global-medium-gutter !default;
$column-divider-rule-color: $global-border !default;
$column-divider-rule-width: 1px !default;
$inverse-column-divider-rule-color: $inverse-global-border !default;
// ================================================================================
// Comments
// ================================================================================
$comment-header-margin-bottom: $global-margin !default;
$comment-title-font-size: $global-medium-font-size !default;
$comment-title-line-height: 1.4 !default;
$comment-meta-font-size: $global-small-font-size !default;
$comment-meta-line-height: 1.4 !default;
$comment-meta-color: $global-muted-color !default;
$comment-list-margin-top: $global-large-margin !default;
$comment-list-padding-left: 30px !default;
$comment-list-padding-left-m: 100px !default;
// ================================================================================
// Container
// ================================================================================
$container-max-width: 1600px !default;
$container-xsmall-max-width: 750px !default;
$container-small-max-width: 900px !default;
$container-large-max-width: 1600px !default;
$container-padding-horizontal: 15px !default;
$container-padding-horizontal-s: $global-gutter !default;
$container-padding-horizontal-m: $global-medium-gutter !default;
// ================================================================================
// Countdown
// ================================================================================
$countdown-item-line-height: 70px !default;
$countdown-number-font-size: 2rem !default;
$countdown-number-font-size-s: 4rem !default;
$countdown-number-font-size-m: 6rem !default;
$countdown-separator-font-size: 1rem !default;
$countdown-separator-font-size-s: 2rem !default;
$countdown-separator-font-size-m: 3rem !default;
// ================================================================================
// Description List
// ================================================================================
$description-list-term-color: $global-emphasis-color !default;
$description-list-term-margin-top: $global-margin !default;
$description-list-divider-term-margin-top: $global-margin !default;
$description-list-divider-term-border-width: $global-border-width !default;
$description-list-divider-term-border: $global-border !default;
// ================================================================================
// Divider
// ================================================================================
$divider-margin-vertical: $global-margin !default;
$divider-icon-width: 50px !default;
$divider-icon-height: 20px !default;
$divider-icon-color: $global-border !default;
$divider-icon-line-top: 50% !default;
$divider-icon-line-width: 100% !default;
$divider-icon-line-border-width: $global-border-width !default;
$divider-icon-line-border: $global-border !default;
$internal-divider-icon-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22none%22%20stroke%3D%22#000%22%20stroke-width%3D%222%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%227%22%20%2F%3E%0A%3C%2Fsvg%3E%0A" !default;
$divider-small-width: 100px !default;
$divider-small-border-width: $global-border-width !default;
$divider-small-border: $global-border !default;
$divider-vertical-height: 100px !default;
$divider-vertical-border-width: $global-border-width !default;
$divider-vertical-border: $global-border !default;
$inverse-divider-icon-color: $inverse-global-border !default;
$inverse-divider-icon-line-border: $inverse-global-border !default;
$inverse-divider-small-border: $inverse-global-border !default;
$inverse-divider-vertical-border: $inverse-global-border !default;
// ================================================================================
// Slider Dotnav
// ================================================================================
$dotnav-margin-horizontal: 12px !default;
$dotnav-margin-vertical: $dotnav-margin-horizontal !default;
$dotnav-item-width: 10px !default;
$dotnav-item-height: $dotnav-item-width !default;
$dotnav-item-border-radius: 50% !default;
$dotnav-item-background: rgba($global-color, .2) !default;
$dotnav-item-hover-background: rgba($global-color, .6) !default;
$dotnav-item-onclick-background: rgba($global-color, .2) !default;
$dotnav-item-active-background: rgba($global-color, .6) !default;
$inverse-dotnav-item-background: rgba($inverse-global-color, .5) !default;
$inverse-dotnav-item-hover-background: rgba($inverse-global-color, .9) !default;
$inverse-dotnav-item-onclick-background: rgba($inverse-global-color, .5) !default;
$inverse-dotnav-item-active-background: rgba($inverse-global-color, .9) !default;
// ================================================================================
// Drop
// ================================================================================
$drop-z-index: $global-z-index+20 !default;
$drop-width: 300px !default;
$drop-margin: $global-margin !default;
// ================================================================================
// Dropdown
// ================================================================================
$dropdown-z-index: $global-z-index+20 !default;
$dropdown-min-width: 200px !default;
$dropdown-padding: 15px !default;
$dropdown-background: $global-muted-background !default;
$dropdown-color: $global-color !default;
$dropdown-margin: $global-small-margin !default;
$dropdown-nav-item-color: $global-muted-color !default;
$dropdown-nav-item-hover-color: $global-color !default;
$dropdown-nav-header-color: $global-emphasis-color !default;
$dropdown-nav-divider-border-width: $global-border-width !default;
$dropdown-nav-divider-border: $global-border !default;
$dropdown-nav-sublist-item-color: $global-muted-color !default;
$dropdown-nav-sublist-item-hover-color: $global-color !default;
// ================================================================================
// Easing
// ================================================================================
$easeInSine: cubic-bezier(.47, 0, .745, .715);
$easeOutSine: cubic-bezier(.39, .575, .565, 1);
$easeInOutSine: cubic-bezier(.445, .05, .55, .95);
$easeInQuad: cubic-bezier(.55, .085, .68, .53);
$easeOutQuad: cubic-bezier(.25, .46, .45, .94);
$easeInOutQuad: cubic-bezier(.455, .03, .515, .955);
$easeInCubic: cubic-bezier(.55, .055, .675, .19);
$easeOutCubic: cubic-bezier(.215, .61, .355, 1);
$easeInOutCubic: cubic-bezier(.645, .045, .355, 1);
$easeInQuart: cubic-bezier(.895, .03, .685, .22);
$easeOutQuart: cubic-bezier(.165, .84, .44, 1);
$easeInOutQuart: cubic-bezier(.77, 0, .175, 1);
$easeInQuint: cubic-bezier(.755, .05, .855, .06);
$easeOutQuint: cubic-bezier(.23, 1, .32, 1);
$easeInOutQuint: cubic-bezier(.86, 0, .07, 1);
$easeInExpo: cubic-bezier(.95, .05, .795, .035);
$easeOutExpo: cubic-bezier(.19, 1, .22, 1);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);
$easeInCirc: cubic-bezier(.6, .04, .98, .335);
$easeOutCirc: cubic-bezier(.075, .82, .165, 1);
$easeInOutCirc: cubic-bezier(.785, .135, .15, .86);
$easeInBack: cubic-bezier(.6, -.28, .735, .045);
$easeOutBack: cubic-bezier(.175, .885, .32, 1.275);
$easeInOutBack: cubic-bezier(.68, -.55, .265, 1.55);
// ================================================================================
// Form
// ================================================================================
$form-range-thumb-height: 15px !default;
$form-range-thumb-border-radius: 500px !default;
$form-range-thumb-background: $global-color !default;
$form-range-track-height: 3px !default;
$form-range-track-background: darken($global-muted-background, 5%) !default;
$form-range-track-focus-background: darken($global-muted-background, 15%) !default;
$form-height: $global-control-height !default;
$form-line-height: $form-height !default;
$form-padding-horizontal: 10px !default;
$form-padding-vertical: 4px !default;
$form-background: $global-muted-background !default;
$form-color: $global-color !default;
$form-focus-background: $global-muted-background !default;
$form-focus-color: $global-color !default;
$form-disabled-background: $global-muted-background !default;
$form-disabled-color: $global-muted-color !default;
$form-placeholder-color: $global-muted-color !default;
$form-small-height: $global-control-small-height !default;
$form-small-padding-horizontal: 8px !default;
$form-small-line-height: $form-small-height !default;
$form-small-font-size: $global-small-font-size !default;
$form-large-height: $global-control-large-height !default;
$form-large-padding-horizontal: 12px !default;
$form-large-line-height: $form-large-height !default;
$form-large-font-size: $global-medium-font-size !default;
$form-danger-color: $global-danger-background !default;
$form-success-color: $global-success-background !default;
$form-width-xsmall: 50px !default;
$form-width-small: 130px !default;
$form-width-medium: 200px !default;
$form-width-large: 500px !default;
$form-select-padding-right: 20px !default;
$form-select-icon-color: $global-color !default;
$form-select-option-color: #444444 !default;
$form-select-disabled-icon-color: $global-muted-color !default;
$form-radio-size: 16px !default;
$form-radio-margin-top: -4px !default;
$form-radio-background: darken($global-muted-background, 5%) !default;
$form-radio-checked-background: $global-primary-background !default;
$form-radio-checked-icon-color: $global-inverse-color !default;
$form-radio-checked-focus-background: darken($global-primary-background, 10%) !default;
$form-radio-disabled-background: $global-muted-background !default;
$form-radio-disabled-icon-color: $global-muted-color !default;
$form-legend-font-size: $global-large-font-size !default;
$form-legend-line-height: 1.4 !default;
$form-stacked-margin-bottom: $global-small-margin !default;
$form-horizontal-label-width: 200px !default;
$form-horizontal-label-margin-top: 7px !default;
$form-horizontal-controls-margin-left: 215px !default;
$form-horizontal-controls-text-padding-top: 7px !default;
$form-icon-width: $form-height !default;
$form-icon-color: $global-muted-color !default;
$form-icon-hover-color: $global-color !default;
$internal-form-select-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22#000%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22#000%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A" !default;
$internal-form-radio-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22#000%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E" !default;
$internal-form-checkbox-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22#000%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A" !default;
$internal-form-checkbox-indeterminate-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22#000%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E" !default;
$inverse-global-muted-background: rgba($global-inverse-color, .1) !default;
$inverse-form-background: $inverse-global-muted-background !default;
$inverse-form-color: $inverse-global-color !default;
$inverse-form-focus-background: $inverse-global-muted-background !default;
$inverse-form-focus-color: $inverse-global-color !default;
$inverse-form-placeholder-color: $inverse-global-muted-color !default;
$inverse-form-select-icon-color: $inverse-global-color !default;
$inverse-form-radio-background: darken($inverse-global-muted-background, 5%) !default;
$inverse-form-radio-checked-background: $inverse-global-primary-background !default;
$inverse-form-radio-checked-icon-color: $inverse-global-inverse-color !default;
$inverse-form-radio-checked-focus-background: darken($inverse-global-primary-background, 10%) !default;
$inverse-form-icon-color: $inverse-global-muted-color !default;
$inverse-form-icon-hover-color: $inverse-global-color !default;
// ================================================================================
// Grid
// ================================================================================
$grid-gutter-horizontal: $global-gutter !default;
$grid-gutter-vertical: $grid-gutter-horizontal !default;
$grid-gutter-horizontal-l: $global-medium-gutter !default;
$grid-gutter-vertical-l: $grid-gutter-horizontal-l !default;
$grid-small-gutter-horizontal: $global-small-gutter !default;
$grid-small-gutter-vertical: $grid-small-gutter-horizontal !default;
$grid-medium-gutter-horizontal: $global-gutter !default;
$grid-medium-gutter-vertical: $grid-medium-gutter-horizontal !default;
$grid-large-gutter-horizontal: $global-medium-gutter !default;
$grid-large-gutter-vertical: $grid-large-gutter-horizontal !default;
$grid-large-gutter-horizontal-l: $global-large-gutter !default;
$grid-large-gutter-vertical-l: $grid-large-gutter-horizontal-l !default;
$grid-divider-border-width: $global-border-width !default;
$grid-divider-border: $global-border !default;
$inverse-grid-divider-border: $inverse-global-border !default;
// ================================================================================
// Heading
// ================================================================================
$heading-medium-font-size-l: 4rem !default;
$heading-small-font-size-m: $heading-medium-font-size-l * .8125 !default;
$heading-small-font-size: $heading-small-font-size-m * .8 !default;
$heading-medium-font-size-m: $heading-medium-font-size-l * .875 !default;
$heading-medium-font-size: $heading-medium-font-size-m * .825 !default;
$heading-large-font-size-m: $heading-medium-font-size-l !default;
$heading-large-font-size: $heading-large-font-size-m * .85 !default;
$heading-xlarge-font-size: $heading-large-font-size-m !default;
$heading-large-font-size-l: 6rem !default;
$heading-xlarge-font-size-m: $heading-large-font-size-l !default;
$heading-2xlarge-font-size: $heading-xlarge-font-size-m !default;
$heading-xlarge-font-size-l: 8rem !default;
$heading-2xlarge-font-size-m: $heading-xlarge-font-size-l !default;
$heading-2xlarge-font-size-l: 11rem !default;
$heading-small-line-height: 1.2 !default;
$heading-medium-line-height: 1.1 !default;
$heading-large-line-height: 1.1 !default;
$heading-xlarge-line-height: 1 !default;
$heading-2xlarge-line-height: 1 !default;

/** Divider */

$heading-divider-padding-bottom: unquote("calc(5px + 0.1em)") !default;
$heading-divider-border-width: unquote("calc(0.2px + 0.05em)") !default;
$heading-divider-border: $global-border !default;

/** Bullet */

$heading-bullet-top: unquote("calc(-0.1 * 1em)") !default;
$heading-bullet-height: unquote("calc(4px + 0.7em)") !default;
$heading-bullet-margin-right: unquote("calc(5px + 0.2em)") !default;
$heading-bullet-border-width: unquote("calc(5px + 0.1em)") !default;
$heading-bullet-border: $global-border !default;

/** Line */

$heading-line-top: 50% !default;
$heading-line-border-width: unquote("calc(0.2px + 0.05em)") !default;
$heading-line-height: $heading-line-border-width !default;
$heading-line-width: 2000px !default;
$heading-line-border: $global-border !default;
$heading-line-margin-horizontal: unquote("calc(5px + 0.3em)") !default;

/** Primary */

$heading-primary-font-size-l: 3.75rem !default;
$heading-primary-line-height-l: 1.1 !default;
$heading-primary-font-size-m: $heading-primary-font-size-l * .9 !default;
$heading-primary-font-size: $heading-primary-font-size-l * .8 !default;
$heading-primary-line-height: 1.2 !default;

/** Hero */

$heading-hero-font-size-l: 8rem !default;
$heading-hero-line-height-l: 1 !default;
$heading-hero-font-size-m: $heading-hero-font-size-l * .75 !default;
$heading-hero-line-height-m: 1 !default;
$heading-hero-font-size: $heading-hero-font-size-l * .5 !default;
$heading-hero-line-height: 1.1 !default;

/** Inverse */

$inverse-heading-divider-border: $inverse-global-border !default;
$inverse-heading-bullet-border: $inverse-global-border !default;
$inverse-heading-line-border: $inverse-global-border !default;
// ================================================================================
// Height
// ================================================================================
$height-small-height: 150px !default;
$height-medium-height: 300px !default;
$height-large-height: 450px !default;
// ================================================================================
// Icons
// ================================================================================
$icon-image-size: 20px !default;

/** Link */

$icon-link-color: $global-muted-color !default;
$icon-link-hover-color: $global-color !default;
$icon-link-active-color: darken($global-color, 5%) !default;

/** Button */

$icon-button-size: 36px !default;
$icon-button-border-radius: 500px !default;
$icon-button-background: $global-muted-background !default;
$icon-button-color: $global-muted-color !default;
$icon-button-hover-background: darken($icon-button-background, 5%) !default;
$icon-button-hover-color: $global-color !default;
$icon-button-active-background: darken($icon-button-background, 10%) !default;
$icon-button-active-color: $global-color !default;

/** Inverse */

$inverse-icon-link-color: $inverse-global-muted-color !default;
$inverse-icon-link-hover-color: $inverse-global-color !default;
$inverse-icon-link-active-color: $inverse-global-color !default;
$inverse-icon-button-background: $inverse-global-muted-background !default;
$inverse-icon-button-color: $inverse-global-muted-color !default;
$inverse-icon-button-hover-background: darken($inverse-icon-button-background, 5%) !default;
$inverse-icon-button-hover-color: $inverse-global-color !default;
$inverse-icon-button-active-background: darken($inverse-icon-button-background, 10%) !default;
$inverse-icon-button-active-color: $inverse-global-color !default;
$iconnav-margin-horizontal: $global-small-margin !default;
$iconnav-margin-vertical: $iconnav-margin-horizontal !default;
$iconnav-item-color: $global-muted-color !default;
$iconnav-item-hover-color: $global-color !default;
$iconnav-item-active-color: $global-color !default;
$inverse-iconnav-item-color: $inverse-global-muted-color !default;
$inverse-iconnav-item-hover-color: $inverse-global-color !default;
$inverse-iconnav-item-active-color: $inverse-global-color !default;
$inverse-global-color-mode: light !default;
// ================================================================================
// Label
// ================================================================================
$label-padding-vertical: 0 !default;
$label-padding-horizontal: $global-small-margin !default;
$label-background: $global-primary-background !default;
$label-line-height: $global-line-height !default;
$label-font-size: $global-small-font-size !default;
$label-color: $global-inverse-color !default;

/** Success */

$label-success-background: $global-success-background !default;
$label-success-color: $global-inverse-color !default;

/** Warning */

$label-warning-background: $global-warning-background !default;
$label-warning-color: $global-inverse-color !default;

/** Danger */

$label-danger-background: $global-danger-background !default;
$label-danger-color: $global-inverse-color !default;

/** Inverse */

$inverse-label-background: $inverse-global-primary-background !default;
$inverse-label-color: $inverse-global-inverse-color !default;
// ================================================================================
// Leader
// ================================================================================
$leader-fill-content: unquote(".") !default;
$leader-fill-margin-left: $global-small-gutter !default;
// ================================================================================
// Lightbox
// ================================================================================
$lightbox-z-index: $global-z-index+10 !default;
$lightbox-background: #000000 !default;

/** Item */

$lightbox-item-color: rgba(255, 255, 255, .7) !default;
$lightbox-item-max-width: 100vw !default;
$lightbox-item-max-height: 100vh !default;

/** Toolbar */

$lightbox-toolbar-padding-vertical: 10px !default;
$lightbox-toolbar-padding-horizontal: 10px !default;
$lightbox-toolbar-background: rgba(0, 0, 0, .3) !default;
$lightbox-toolbar-color: rgba(255, 255, 255, .7) !default;
$lightbox-toolbar-icon-padding: 5px !default;
$lightbox-toolbar-icon-color: rgba(255, 255, 255, .7) !default;
$lightbox-toolbar-icon-hover-color: #ffffff !default;

/** Button */

$lightbox-button-size: 50px !default;
$lightbox-button-background: $lightbox-toolbar-background !default;
$lightbox-button-color: rgba(255, 255, 255, .7) !default;
$lightbox-button-hover-color: #ffffff !default;
// ================================================================================
// Links
// ================================================================================
$link-muted-color: $global-muted-color !default;
$link-muted-hover-color: $global-color !default;
$link-text-hover-color: $global-muted-color !default;
$link-heading-hover-color: $global-primary-background !default;
$link-heading-hover-text-decoration: none !default;

/** Inverse */

$inverse-link-muted-color: $inverse-global-muted-color !default;
$inverse-link-muted-hover-color: $inverse-global-color !default;
$inverse-link-text-hover-color: $inverse-global-muted-color !default;
$inverse-link-heading-hover-color: $inverse-global-primary-background !default;
// ================================================================================
// List
// ================================================================================
$list-margin-top: $global-small-margin !default;
$list-nested-padding-left: $global-gutter !default;
$list-divider-margin-top: $global-small-margin !default;
$list-divider-border-width: $global-border-width !default;
$list-divider-border: $global-border !default;
$list-striped-padding-vertical: $global-small-margin !default;
$list-striped-padding-horizontal: $global-small-margin !default;
$list-striped-background: $global-muted-background !default;
$list-bullet-width: ($global-line-height * 1em) !default;
$list-bullet-height: $list-bullet-width !default;
$list-bullet-margin-right: $global-small-margin !default;
$list-bullet-icon-color: $global-color !default;
$list-large-margin-top: $global-margin !default;
$list-large-divider-margin-top: $global-margin !default;
$list-large-striped-padding-vertical: $global-margin !default;
$list-large-striped-padding-horizontal: $global-small-margin !default;
$internal-list-bullet-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22#000%22%20cx%3D%223%22%20cy%3D%223%22%20r%3D%223%22%20%2F%3E%0A%3C%2Fsvg%3E" !default;
$inverse-list-divider-border: $inverse-global-border !default;
$inverse-list-striped-background: $inverse-global-muted-background !default;
$inverse-list-bullet-icon-color: $inverse-global-color !default;
// ================================================================================
// Margins
// ================================================================================
$margin-margin: $global-margin !default;
$margin-small-margin: $global-small-margin !default;
$margin-medium-margin: $global-medium-margin !default;
$margin-large-margin: $global-medium-margin !default;
$margin-large-margin-l: $global-large-margin !default;
$margin-xlarge-margin: $global-large-margin !default;
$global-xlarge-margin: 140px !default;
$margin-xlarge-margin-l: $global-xlarge-margin !default;
// ================================================================================
// Markers
// ================================================================================
$marker-padding: 5px !default;
$marker-background: $global-secondary-background !default;
$marker-color: $global-inverse-color !default;
$marker-hover-color: $global-inverse-color !default;
$inverse-marker-background: $global-muted-background !default;
$inverse-marker-color: $global-color !default;
$inverse-marker-hover-color: $global-color !default;
// ================================================================================
// Modal
// ================================================================================
$modal-z-index: $global-z-index+10 !default;
$modal-background: rgba(0, 0, 0, .6) !default;
$modal-padding-horizontal: 15px !default;
$modal-padding-horizontal-s: $global-gutter !default;
$modal-padding-horizontal-m: $global-medium-gutter !default;
$modal-padding-vertical: $modal-padding-horizontal !default;
$modal-padding-vertical-s: 50px !default;
$modal-dialog-width: 600px !default;
$modal-dialog-background: $global-background!default;
$modal-container-width: 1200px !default;
$modal-body-padding-horizontal: $global-gutter !default;
$modal-body-padding-vertical: $global-gutter !default;
$modal-header-padding-horizontal: $global-gutter !default;
$modal-header-padding-vertical: calc($modal-header-padding-horizontal / 2) !default;
$modal-header-background: $global-muted-background !default;
$modal-footer-padding-horizontal: $global-gutter !default;
$modal-footer-padding-vertical: calc($modal-footer-padding-horizontal / 2) !default;
$modal-footer-background: $global-muted-background !default;
$modal-title-font-size: $global-xlarge-font-size !default;
$modal-title-line-height: 1.3 !default;
$modal-close-position: $global-small-margin !default;
$modal-close-padding: 5px !default;
$modal-close-outside-position: 0 !default;
$modal-close-outside-translate: 100% !default;
$modal-close-outside-color: lighten($global-inverse-color, 20%) !default;
$modal-close-outside-hover-color: $global-inverse-color !default;
// ================================================================================
// Navigation
// ================================================================================

/** Item */

$nav-item-padding-vertical: 5px !default;
$nav-item-padding-horizontal: 0 !default;

/** Sublist */

$nav-sublist-padding-vertical: 5px !default;
$nav-sublist-padding-left: 15px !default;
$nav-sublist-deeper-padding-left: 15px !default;
$nav-sublist-item-padding-vertical: 2px !default;

/** Parent */

$nav-parent-icon-width: ($global-line-height * 1em) !default;
$nav-parent-icon-height: $nav-parent-icon-width !default;
$nav-parent-icon-color: $global-color !default;

/** Header */

$nav-header-background: $global-primary-background !default;
$nav-header-padding-vertical: $nav-item-padding-vertical !default;
$nav-header-padding-horizontal: $nav-item-padding-horizontal !default;
$nav-header-font-size: $global-small-font-size !default;
$nav-header-text-transform: uppercase !default;
$nav-header-margin-top: $global-margin !default;

/** Divider */

$nav-divider-margin-vertical: 5px !default;
$nav-divider-margin-horizontal: 0 !default;

/** Default */

$nav-default-item-color: $global-muted-color !default;
$nav-default-item-hover-color: $global-color !default;
$nav-default-item-active-color: $global-emphasis-color !default;
$nav-default-header-color: $global-emphasis-color !default;
$nav-default-divider-border-width: $global-border-width !default;
$nav-default-divider-border: $global-border !default;
$nav-default-sublist-item-color: $global-muted-color !default;
$nav-default-sublist-item-hover-color: $global-color !default;
$nav-default-sublist-item-active-color: $global-emphasis-color !default;

/** Primary */

$nav-primary-item-font-size: $global-large-font-size !default;
$nav-primary-item-line-height: $global-line-height !default;
$nav-primary-item-color: $global-muted-color !default;
$nav-primary-item-hover-color: $global-color !default;
$nav-primary-item-active-color: $global-emphasis-color !default;
$nav-primary-header-color: $global-emphasis-color !default;
$nav-primary-divider-border-width: $global-border-width !default;
$nav-primary-divider-border: $global-border !default;
$nav-primary-sublist-item-color: $global-muted-color !default;
$nav-primary-sublist-item-hover-color: $global-color !default;
$nav-primary-sublist-item-active-color: $global-emphasis-color !default;

/** Inverse */

$internal-nav-parent-close-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22#000%22%20stroke-width%3D%221.1%22%20points%3D%2210%201%204%207%2010%2013%22%20%2F%3E%0A%3C%2Fsvg%3E" !default;
$internal-nav-parent-open-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22#000%22%20stroke-width%3D%221.1%22%20points%3D%221%204%207%2010%2013%204%22%20%2F%3E%0A%3C%2Fsvg%3E" !default;
$inverse-nav-parent-icon-color: $inverse-global-color !default;
$inverse-nav-default-item-color: $inverse-global-muted-color !default;
$inverse-nav-default-item-hover-color: $inverse-global-color !default;
$inverse-nav-default-item-active-color: $inverse-global-emphasis-color !default;
$inverse-nav-default-header-color: $inverse-global-emphasis-color !default;
$inverse-nav-default-divider-border: $inverse-global-border !default;
$inverse-nav-default-sublist-item-color: $inverse-global-muted-color !default;
$inverse-nav-default-sublist-item-hover-color: $inverse-global-color !default;
$inverse-nav-default-sublist-item-active-color: $inverse-global-emphasis-color !default;
$inverse-nav-primary-item-color: $inverse-global-muted-color !default;
$inverse-nav-primary-item-hover-color: $inverse-global-color !default;
$inverse-nav-primary-item-active-color: $inverse-global-emphasis-color !default;
$inverse-nav-primary-header-color: $inverse-global-emphasis-color !default;
$inverse-nav-primary-divider-border: $inverse-global-border !default;
$inverse-nav-primary-sublist-item-color: $inverse-global-muted-color !default;
$inverse-nav-primary-sublist-item-hover-color: $inverse-global-color !default;
$inverse-nav-primary-sublist-item-active-color: $inverse-global-emphasis-color !default;
// ================================================================================
// Navbar
// ================================================================================
$navbar-background: $global-muted-background !default;
$navbar-color-mode: none !default;

/** NavItem */

$navbar-nav-item-height: 80px !default;
$navbar-nav-item-padding-horizontal: 15px !default;
$navbar-nav-item-color: $global-muted-color !default;
$navbar-nav-item-font-size: $global-font-size !default;
$navbar-nav-item-font-family: $global-font-family !default;
$navbar-nav-item-hover-color: $global-color !default;
$navbar-nav-item-onclick-color: $global-emphasis-color !default;
$navbar-nav-item-active-color: $global-emphasis-color !default;
$navbar-item-color: $global-color !default;

/** Toggle */

$navbar-toggle-color: $global-muted-color !default;
$navbar-toggle-hover-color: $global-color !default;
$navbar-subtitle-font-size: $global-small-font-size !default;

/** Dropdown */

$navbar-dropdown-z-index: $global-z-index+20 !default;
$navbar-dropdown-width: 200px !default;
$navbar-dropdown-margin: 0 !default;
$navbar-dropdown-padding: 15px !default;
$navbar-dropdown-background: $global-muted-background !default;
$navbar-dropdown-color: $global-color !default;
$navbar-dropdown-grid-gutter-horizontal: $global-gutter !default;
$navbar-dropdown-grid-gutter-vertical: $navbar-dropdown-grid-gutter-horizontal !default;
$navbar-dropdown-dropbar-margin-top: 0 !default;
$navbar-dropdown-dropbar-margin-bottom: $navbar-dropdown-dropbar-margin-top !default;
$navbar-dropdown-nav-item-color: $global-muted-color !default;
$navbar-dropdown-nav-item-hover-color: $global-color !default;
$navbar-dropdown-nav-item-active-color: $global-emphasis-color !default;
$navbar-dropdown-nav-header-color: $global-emphasis-color !default;
$navbar-dropdown-nav-divider-border-width: $global-border-width !default;
$navbar-dropdown-nav-divider-border: $global-border !default;
$navbar-dropdown-nav-sublist-item-color: $global-muted-color !default;
$navbar-dropdown-nav-sublist-item-hover-color: $global-color !default;
$navbar-dropdown-nav-sublist-item-active-color: $global-emphasis-color !default;
$navbar-dropbar-background: $navbar-dropdown-background !default;
$navbar-dropbar-z-index: $global-z-index - 20 !default;

/** Inverse */

$inverse-navbar-nav-item-color: $inverse-global-muted-color !default;
$inverse-navbar-nav-item-hover-color: $inverse-global-color !default;
$inverse-navbar-nav-item-onclick-color: $inverse-global-emphasis-color !default;
$inverse-navbar-nav-item-active-color: $inverse-global-emphasis-color !default;
$inverse-navbar-item-color: $inverse-global-color !default;
$inverse-navbar-toggle-color: $inverse-global-muted-color !default;
$inverse-navbar-toggle-hover-color: $inverse-global-color !default;
// ================================================================================
// Notifications
// ================================================================================
$notification-position: 10px !default;
$notification-z-index: $global-z-index+40 !default;
$notification-width: 350px !default;
$notification-message-margin-top: 10px !default;
$notification-message-padding: $global-small-gutter !default;
$notification-message-background: $global-muted-background !default;
$notification-message-color: $global-color !default;
$notification-message-font-size: $global-medium-font-size !default;
$notification-message-line-height: 1.4 !default;
$notification-close-top: $notification-message-padding+5px !default;
$notification-close-right: $notification-message-padding !default;
$notification-message-primary-color: $global-primary-background !default;
$notification-message-success-color: $global-success-background !default;
$notification-message-warning-color: $global-warning-background !default;
$notification-message-danger-color: $global-danger-background !default;
// ================================================================================
// Offcanvas
// ================================================================================
$offcanvas-z-index: $global-z-index !default;
$offcanvas-bar-width: 270px !default;
$offcanvas-bar-padding-vertical: $global-margin !default;
$offcanvas-bar-padding-horizontal: $global-margin !default;
$offcanvas-bar-background: $global-secondary-background !default;
$offcanvas-bar-color-mode: light !default;
$offcanvas-bar-width-m: 350px !default;
$offcanvas-bar-padding-vertical-m: $global-medium-gutter !default;
$offcanvas-bar-padding-horizontal-m: $global-medium-gutter !default;
$offcanvas-close-position: 20px !default;
$offcanvas-close-padding: 5px !default;
$offcanvas-overlay-background: rgba(0, 0, 0, .1) !default;
// ================================================================================
// Overlay
// ================================================================================
$overlay-padding-horizontal: $global-gutter !default;
$overlay-padding-vertical: $global-gutter !default;
$overlay-default-background: rgba($global-background, .8) !default;
$overlay-primary-background: rgba($global-secondary-background, .8) !default;
$overlay-primary-color-mode: light !default;
// ================================================================================
// Padding
// ================================================================================
$padding-padding: $global-gutter !default;
$padding-padding-l: $global-medium-gutter !default;
$padding-small-padding: $global-small-gutter !default;
$padding-large-padding: $global-gutter !default;
$padding-large-padding-l: $global-large-gutter !default;
// ================================================================================
// Pagination
// ================================================================================
$pagination-margin-horizontal: 20px !default;
$pagination-item-color: $global-muted-color !default;
$pagination-item-hover-color: $global-color !default;
$pagination-item-hover-text-decoration: none !default;
$pagination-item-active-color: $global-color !default;
$pagination-item-disabled-color: $global-muted-color !default;

/** Inverse */

$inverse-pagination-item-color: $inverse-global-muted-color !default;
$inverse-pagination-item-hover-color: $inverse-global-color !default;
$inverse-pagination-item-active-color: $inverse-global-color !default;
$inverse-pagination-item-disabled-color: $inverse-global-muted-color !default;
// ================================================================================
// Placeholder
// ================================================================================
$placeholder-margin-vertical: $global-margin !default;
$placeholder-padding-vertical: $global-gutter !default;
$placeholder-padding-horizontal: $global-gutter !default;
$placeholder-background: $global-muted-background !default;
// ================================================================================
// Position
// ================================================================================
$position-small-margin: $global-small-gutter !default;
$position-medium-margin: $global-gutter !default;
$position-large-margin: $global-gutter !default;
$position-large-margin-l: 50px !default;
// ================================================================================
// Progress
// ================================================================================
$progress-height: 15px !default;
$progress-margin-vertical: $global-margin !default;
$progress-background: $global-muted-background !default;
$progress-bar-background: $global-primary-background !default;
// ================================================================================
// Search
// ================================================================================
$search-color: $global-color !default;
$search-placeholder-color: $global-muted-color !default;
$search-icon-color: $global-muted-color !default;
$search-default-width: 180px !default;
$search-default-height: $global-control-height !default;
$search-default-padding-horizontal: 6px !default;
$search-default-background: $global-muted-background !default;
$search-default-focus-background: $search-default-background !default;
$search-default-icon-width: $global-control-height !default;
$search-navbar-width: 400px !default;
$search-navbar-height: 40px !default;
$search-navbar-background: transparent !default;
$search-navbar-font-size: $global-large-font-size !default;
$search-navbar-icon-width: 40px !default;
$search-large-width: 500px !default;
$search-large-height: 80px !default;
$search-large-background: transparent !default;
$search-large-font-size: $global-xxlarge-font-size !default;
$search-large-icon-width: 80px !default;
$search-toggle-color: $global-muted-color !default;
$search-toggle-hover-color: $global-color !default;

/** Inverse */

$inverse-search-color: $inverse-global-color !default;
$inverse-search-placeholder-color: $inverse-global-muted-color !default;
$inverse-search-icon-color: $inverse-global-muted-color !default;
$inverse-search-default-background: $inverse-global-muted-background !default;
$inverse-search-default-focus-background: $inverse-search-default-background !default;
$inverse-search-navbar-background: transparent !default;
$inverse-search-large-background: transparent !default;
$inverse-search-toggle-color: $inverse-global-muted-color !default;
$inverse-search-toggle-hover-color: $inverse-global-color !default;
// ================================================================================
// Sections
// ================================================================================
$section-padding-vertical: $global-medium-margin !default;
$section-padding-vertical-m: $global-large-margin !default;
$section-xsmall-padding-vertical: $global-margin !default;
$section-small-padding-vertical: $global-medium-margin !default;
$section-large-padding-vertical: $global-large-margin !default;
$section-large-padding-vertical-m: $global-xlarge-margin !default;
$section-xlarge-padding-vertical: $global-xlarge-margin !default;
$section-xlarge-padding-vertical-m: ($global-large-margin + $global-xlarge-margin) !default;
$section-default-background: $global-background!default;
$section-muted-background: $global-muted-background !default;
$section-primary-background: $global-primary-background !default;
$section-primary-color-mode: light !default;
$section-secondary-background: $global-secondary-background !default;
$section-secondary-color-mode: light !default;
// ================================================================================
// Slidenav
// ================================================================================
$slidenav-padding-vertical: 5px !default;
$slidenav-padding-horizontal: 10px !default;
$slidenav-color: rgba($global-color, .5) !default;
$slidenav-hover-color: rgba($global-color, .9) !default;
$slidenav-active-color: rgba($global-color, .5) !default;
$slidenav-large-padding-vertical: 10px !default;
$slidenav-large-padding-horizontal: $slidenav-large-padding-vertical !default;

/** Inverse */

$inverse-slidenav-color: rgba($inverse-global-color, .7) !default;
$inverse-slidenav-hover-color: rgba($inverse-global-color, .95) !default;
$inverse-slidenav-active-color: rgba($inverse-global-color, .7) !default;
// ================================================================================
// Sortable
// ================================================================================
$sortable-dragged-z-index: $global-z-index+50 !default;
$sortable-placeholder-opacity: 0 !default;
$sortable-empty-height: 50px !default;
// ================================================================================
// Spinner
// ================================================================================
$spinner-size: 30px !default;
$spinner-stroke-width: 1px !default;
$spinner-radius: floor(calc(($spinner-size - $spinner-stroke-width) / 2)) !default;
$spinner-circumference: round(2 * 3.141 * $spinner-radius) !default;
$spinner-duration: 1.4s !default;
// ================================================================================
// Sticky
// ================================================================================
$sticky-z-index: $global-z-index - 20 !default;
$sticky-animation-duration: .2s !default;
$sticky-reverse-animation-duration: .2s !default;
// ================================================================================
// Subnav
// ================================================================================
$subnav-margin-horizontal: 20px !default;

/** Item */

$subnav-item-color: $global-muted-color !default;
$subnav-item-hover-color: $global-color !default;
$subnav-item-hover-text-decoration: none !default;
$subnav-item-active-color: $global-emphasis-color !default;
$subnav-item-disabled-color: $global-muted-color !default;

/** Divider */

$subnav-divider-margin-horizontal: $subnav-margin-horizontal !default;
$subnav-divider-border-height: 1.5em !default;
$subnav-divider-border-width: $global-border-width !default;
$subnav-divider-border: $global-border !default;

/** Pill */

$subnav-pill-item-padding-vertical: 5px !default;
$subnav-pill-item-padding-horizontal: 10px !default;
$subnav-pill-item-background: transparent !default;
$subnav-pill-item-color: $subnav-item-color !default;
$subnav-pill-item-hover-background: $global-muted-background !default;
$subnav-pill-item-hover-color: $global-color !default;
$subnav-pill-item-onclick-background: $subnav-pill-item-hover-background !default;
$subnav-pill-item-onclick-color: $subnav-pill-item-hover-color !default;
$subnav-pill-item-active-background: $global-primary-background !default;
$subnav-pill-item-active-color: $global-inverse-color !default;

/** Inverse */

$inverse-subnav-item-color: $inverse-global-muted-color !default;
$inverse-subnav-item-hover-color: $inverse-global-color !default;
$inverse-subnav-item-active-color: $inverse-global-emphasis-color !default;
$inverse-subnav-divider-border: $inverse-global-border !default;
$inverse-subnav-pill-item-background: transparent !default;
$inverse-subnav-pill-item-color: $inverse-global-muted-color !default;
$inverse-subnav-pill-item-hover-background: $inverse-global-muted-background !default;
$inverse-subnav-pill-item-hover-color: $inverse-global-color !default;
$inverse-subnav-pill-item-onclick-background: $inverse-subnav-pill-item-hover-background !default;
$inverse-subnav-pill-item-onclick-color: $inverse-subnav-pill-item-hover-color !default;
$inverse-subnav-pill-item-active-background: $inverse-global-primary-background !default;
$inverse-subnav-pill-item-active-color: $inverse-global-inverse-color !default;
$inverse-subnav-item-disabled-color: $inverse-global-muted-color !default;
// ================================================================================
// Tab
// ================================================================================
$tab-margin-horizontal: 20px !default;
$tab-item-padding-horizontal: 10px !default;
$tab-item-padding-vertical: 5px !default;
$tab-item-color: $global-muted-color !default;
$tab-item-hover-color: $global-color !default;
$tab-item-hover-text-decoration: none !default;
$tab-item-active-color: $global-emphasis-color !default;
$tab-item-disabled-color: $global-muted-color !default;

/** Inverse */

$inverse-tab-item-color: $inverse-global-muted-color !default;
$inverse-tab-item-hover-color: $inverse-global-color !default;
$inverse-tab-item-active-color: $inverse-global-emphasis-color !default;
$inverse-tab-item-disabled-color: $inverse-global-muted-color !default;
// ================================================================================
// Table
// ================================================================================
$table-margin-vertical: $global-margin !default;

/** Cell */

$table-cell-padding-vertical: 16px !default;
$table-cell-padding-horizontal: 12px !default;
$table-small-cell-padding-vertical: 10px !default;
$table-small-cell-padding-horizontal: 12px !default;
$table-large-cell-padding-vertical: 22px !default;
$table-large-cell-padding-horizontal: 12px !default;

/** Header Cell */

$table-header-cell-font-size: $global-font-size !default;
$table-header-cell-font-weight: bold !default;
$table-header-cell-color: $global-color !default;

/** Footer */

$table-footer-font-size: $global-small-font-size !default;

/** Caption */

$table-caption-font-size: $global-small-font-size !default;
$table-caption-color: $global-muted-color !default;
$table-row-active-background: #ffffdd !default;

/** Divider */

$table-divider-border-width: $global-border-width !default;
$table-divider-border: $global-border !default;
$table-striped-row-background: $global-muted-background !default;
$table-hover-row-background: $table-row-active-background !default;
$table-expand-min-width: 150px !default;

/** Inverse */

$inverse-table-header-cell-color: $inverse-global-color !default;
$inverse-table-caption-color: $inverse-global-muted-color !default;
$inverse-table-row-active-background: fade-out($inverse-global-muted-background, .02) !default;
$inverse-table-divider-border: $inverse-global-border !default;
$inverse-table-striped-row-background: $inverse-global-muted-background !default;
$inverse-table-hover-row-background: $inverse-table-row-active-background !default;
// ================================================================================
// Text
// ================================================================================
$text-lead-font-size: $global-large-font-size !default;
$text-lead-line-height: 1.5 !default;
$text-lead-color: $global-emphasis-color !default;
$text-meta-font-size: $global-small-font-size !default;
$text-meta-line-height: 1.4 !default;
$text-meta-color: $global-muted-color !default;
$text-small-font-size: $global-small-font-size !default;
$text-small-line-height: 1.5 !default;
$text-large-font-size: $global-large-font-size !default;
$text-large-line-height: 1.5 !default;
$text-bold-font-weight: bolder !default;
$text-muted-color: $global-muted-color !default;
$text-emphasis-color: $global-emphasis-color !default;
$text-primary-color: $global-primary-background !default;
$text-secondary-color: $global-secondary-background !default;
$text-success-color: $global-success-background !default;
$text-warning-color: $global-warning-background !default;
$text-danger-color: $global-danger-background !default;
$text-background-color: $global-primary-background !default;

/** Inverse */

$inverse-text-lead-color: $inverse-global-color !default;
$inverse-text-meta-color: $inverse-global-muted-color !default;
$inverse-text-muted-color: $inverse-global-muted-color !default;
$inverse-text-emphasis-color: $inverse-global-emphasis-color !default;
$inverse-text-primary-color: $inverse-global-primary-background !default;
$inverse-text-secondary-color: $inverse-global-primary-background !default;
// ================================================================================
// Thumbnav
// ================================================================================
$thumbnav-margin-horizontal: 15px !default;
$thumbnav-margin-vertical: $thumbnav-margin-horizontal !default;
// ================================================================================
// Title
// ================================================================================
$tile-padding-horizontal: 15px !default;
$tile-padding-horizontal-s: $global-gutter !default;
$tile-padding-horizontal-m: $global-medium-gutter !default;
$tile-padding-vertical: $global-medium-margin !default;
$tile-padding-vertical-m: $global-large-margin !default;
$tile-xsmall-padding-vertical: $global-margin !default;
$tile-small-padding-vertical: $global-medium-margin !default;
$tile-large-padding-vertical: $global-large-margin !default;
$tile-large-padding-vertical-m: $global-xlarge-margin !default;
$tile-xlarge-padding-vertical: $global-xlarge-margin !default;
$tile-xlarge-padding-vertical-m: ($global-large-margin + $global-xlarge-margin) !default;
$tile-default-background: $global-background!default;
$tile-muted-background: $global-muted-background !default;
$tile-primary-background: $global-primary-background !default;
$tile-primary-color-mode: light !default;
$tile-secondary-background: $global-secondary-background !default;
$tile-secondary-color-mode: light !default;
// ================================================================================
// Tooltip
// ================================================================================
$tooltip-z-index: $global-z-index+30 !default;
$tooltip-max-width: 200px !default;
$tooltip-padding-vertical: 3px !default;
$tooltip-padding-horizontal: 6px !default;
$tooltip-background: #666666 !default;
$tooltip-border-radius: 2px !default;
$tooltip-color: $global-inverse-color !default;
$tooltip-font-size: 12px !default;
$tooltip-margin: 10px !default;
// ================================================================================
// ToTop
// ================================================================================
$totop-padding: 5px !default;
$totop-color: $global-muted-color !default;
$totop-hover-color: $global-color !default;
$totop-active-color: $global-emphasis-color !default;

/** Inverse */

$inverse-totop-color: $inverse-global-muted-color !default;
$inverse-totop-hover-color: $inverse-global-color !default;
$inverse-totop-active-color: $inverse-global-emphasis-color !default;
// ================================================================================
// Transition
// ================================================================================
$transition-duration: .3s !default;
$transition-scale: 1.1 !default;
$transition-slide-small-translate: 10px !default;
$transition-slide-medium-translate: 50px !default;
$transition-slow-duration: .7s !default;
// ================================================================================
// Panel
// ================================================================================
$panel-scrollable-height: 170px !default;
$panel-scrollable-padding: 10px !default;
$panel-scrollable-border-width: $global-border-width !default;
$panel-scrollable-border: $global-border !default;
// ================================================================================
// Border radius
// ================================================================================
$border-rounded-border-radius: 20px !default;
// ================================================================================
// Box Shadow
// ================================================================================
$box-shadow-duration: .1s !default;
$box-shadow-bottom-height: 30px !default;
$box-shadow-bottom-border-radius: 100% !default;
$box-shadow-bottom-background: #444444 !default;
$box-shadow-bottom-blur: 20px !default;
// ================================================================================
// Dropcap
// ================================================================================
$dropcap-margin-right: 10px !default;
$dropcap-font-size: (($global-line-height * 3) * 1em) !default;
// ================================================================================
// Logo
// ================================================================================
$logo-font-size: $global-large-font-size !default;
$logo-font-family: $global-font-family !default;
$logo-color: $global-color !default;
$logo-hover-color: $global-color !default;
$dragover-box-shadow: 0 0 20px rgba(100, 100, 100, .3) !default;
$inverse-logo-color: $inverse-global-color !default;
$inverse-logo-hover-color: $inverse-global-color !default;
$deprecated: false !default;
// ================================================================================
// Breakpoints
// ================================================================================
$breakpoint-small: 768px !default;
$breakpoint-medium: 1020px !default;
$breakpoint-medium-light: 1280px !default;
$breakpoint-large: 1366px !default;
$breakpoint-xlarge-1440: 1440px !default;
$breakpoint-xlarge: 1600px !default;
$breakpoint-xxlarge: 1920px !default;
$breakpoint-xsmall-max: ($breakpoint-small - 1) !default;
$breakpoint-small-max: ($breakpoint-medium - 1) !default;
$breakpoint-medium-max: ($breakpoint-large - 1) !default;
$breakpoint-large-max: ($breakpoint-xlarge - 1) !default;
$breakpoint-xlarge-max: ($breakpoint-xxlarge + 1) !default;
// ================================================================================
// Width
// ================================================================================
$width-small-width: 150px !default;
$width-medium-width: 300px !default;
$width-large-width: 450px !default;
$width-xlarge-width: 600px !default;
$width-xxlarge-width: 750px !default;