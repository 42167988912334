//
// Component: Site Logo
//
// ========================================================================
// Site Logo
// ========================================================================
.uk-navbar-center:not(:only-child) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
    box-sizing: border-box;
    z-index: 990;
    &.logo-client {
        text-align: center;
        display: none;
        margin: 0;
        border-bottom: 0;
        width: 20%;
        @media (min-width: $breakpoint-small) {
            display: block;
            width: 13%;
            transform: translate3d(-50%, -40px, 0);
        }
        &:hover {
            border-bottom: 0;
        }
    }
}