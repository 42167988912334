//
// Layout: Connexion
//
// Connexion
// ========================================================================
.section-connexion {
    .container-forms {
        display: block;
        @media (min-width: $breakpoint-small) {
            display: flex;
        }
    }
}

.section-creation {
    .container-forms {
        display: flex;
        flex-flow: column;
        align-items: center;
        .form {
            margin-left: 0;
        }
    }
    .submit-button {
        width: 70%;
        margin: 0 auto;
        @media (min-width: $breakpoint-small) {
            width: 40%;
        }
    }
}

.container-forms {
    display: flex;
    .form {
        background: #F9F7F7;
        padding: $padding-padding;
        height: max-content;
        //width: 100%;
        @media (min-width: $breakpoint-small) {
            padding: $padding-padding*3;
            margin-left: $margin-margin*3;
        }
    }
}

.input-data {
    height: 50px;
    display: flex;
}

.btn-reset-password {
    font-family: $global-heading-font-family;
    color: $global-color-black;
    border-bottom: 2px solid transparent;
    &:hover {
        border-bottom: 2px solid $global-color-green;
    }
}

.warning-message {
    p {
        font-size: .9rem;
        padding-top: 10px;
        font-family: $global-heading-font-family;
    }
}

.container-select-items {
    .uk-form-label {
        font-family: $global-heading-font-family;
        font-weight: bold;
        display: block;
    }
    .uk-select {
        border: 1px solid silver;
        background-color: white;
        height: 50px;
        &::placeholder {
            list-style: italic;
        }
    }
}