//
// Layout: Contact
//
// Contact
// ========================================================================
.section-contact {
    .liste-adresse {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            display: flex;
            align-items: center;
            margin: 10px 0;
            span {
                color: $global-color-green;
                font-size: 3rem;
                &.item {
                    color: $global-color;
                    font-size: 1rem;
                    display: flex;
                    align-items: center;
                    &:before {
                        content: "";
                        width: 30px;
                        height: 1px;
                        display: block;
                        background: $global-color;
                        margin: 0 10px;
                    }
                }
            }
        }
    }
    figure {
        text-align: center;
        @media (min-width: $breakpoint-small) {
            text-align-last: left;
        }
        img {
            width: 40%;
            @media (min-width: $breakpoint-small) {
                width: 20%;
            }
        }
    }
    .contact-form {
        .input-data {
            .uk-input {
                border: none;
                border-bottom: 1px solid silver;
            }
            label {
                text-transform: uppercase;
                font-style: normal;
            }
        }
    }
}