//
// Component: Pagination
//
// ========================================================================
// Variables
// ========================================================================
// Pagination
// ========================================================================
.uk-pagination {
    margin-top: $margin-margin*2;
    align-items: center;
    margin-left: 0;
    li {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 0;
        margin-top: 0!important;
        a {
            color: #5B5456;
            width: 30px;
            height: 30px;
            text-align: center;
            display: table;
            font-size: 1rem;
            font-weight: 500;
            border-bottom: none;
            transition: all .3s ease-in-out;
            &:after {
                content: "";
                width: 20px;
                height: 1px;
                background: transparent;
                display: block;
                transition: all .3s ease-in-out;
                @media (min-width: $breakpoint-small) {
                    width: 30px;
                }
            }
        }
        &:hover {
            a {
                color: $global-color-green;
                border-bottom: none;
                &:after {
                    content: "";
                    background: $global-color-soft-gray;
                }
            }
        }
        &.uk-active {
            a {
                color: $global-color-green;
                &:after {
                    content: "";
                    background: $global-color-soft-gray;
                }
            }
        }
        &.uk-disabled {
            span {
                color: $global-color;
            }
        }
        &.prev,
        &.next {
            a {
                border: none;
                background: transparent;
                display: flex;
                align-items: center;
                justify-content: center;
                background: white;
                border: 1px solid $global-color-soft-gray;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                transition: all .3s ease-in-out;
                @media (min-width: $breakpoint-small) {
                    width: 40px;
                    height: 40px;
                }
                &:before {
                    content: "";
                    font-family: 'icomoon';
                    color: $global-color;
                    display: block;
                    font-size: 1rem;
                    transition: all .3s ease-in-out;
                    @media (min-width: $breakpoint-small) {
                        font-size: 1.3rem;
                    }
                }
                &:after {
                    content: none;
                }
                &:hover {
                    background: $global-color-green;
                    &:before {
                        color: white;
                    }
                }
            }
        }
        &.prev {
            a {
                &:before {
                    content: "\e903";
                    transform: rotate(90deg);
                }
            }
        }
        &.next {
            a {
                &:before {
                    content: "\e903";
                    transform: rotate(-90deg);
                }
            }
        }
        &.first,
        &.last {
            a {
                color: $global-color;
                transition: all .3s ease-in-out;
                &:after {
                    content: none;
                }
                &:hover {
                    color: $global-color-green;
                }
            }
        }
    }
}