//
// Component: Listing produits
//
// Listing produits
// ========================================================================
.section-listing {
    transition: all .3s ease-in-out;
    .listing-produits {
        margin: 0;
        padding: 0;
        list-style: none;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        transition: all .3s ease-in-out;
        @media (min-width: $breakpoint-medium) {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 20px;
        }
        @media (min-width: $breakpoint-medium-light) {
            grid-template-columns: repeat(4, 1fr);
        }
    }
    &.list-mode {
        .listing-produits {
            display: block;
            .uk-card-default {
                display: flex;
                align-items: center;
                margin: 0 0 30px 0;
                figure {
                    width: 450px;
                }
                .content-card {
                    width: 100%;
                    text-align: left;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
        }
    }
}

#btn-open-filters-tri {
    background: $global-color-soft-gray;
    color: $global-color-black;
    padding: 10px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -30px auto 0 auto;
    width: 90%;
    font-size: 1.2rem;
    z-index: 1;
    position: relative;
    font-family: $global-heading-font-family;
    span {
        margin-right: 15px;
    }
    @media (min-width: $breakpoint-small) {
        display: none;
    }
}