//
// Component: Mes adresses
//
// Mes adresses
// ========================================================================
.container-items-compte {
    display: block;
    @media (min-width: $breakpoint-medium) {
        display: flex;
    }
    h2 {
        font-size: 1.5rem;
    }
}

.mes-adresses {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    margin: 0 0 $margin-margin*2 0;
    @media (min-width: $breakpoint-small) {
        grid-template-columns: repeat(2, 1fr);
        margin: 0 0 $margin-margin 0;
    }
    .adresse {
        text-align: left;
        background: #F8F8F8;
        padding: $padding-padding*1.5;
        min-height: 300px;
        position: relative;
        .content_adresse {
            margin: 0;
            padding: 0;
        }
        p {
            margin: 0;
            &.titre_adresse {
                margin: 0 0 $margin-margin 0;
            }
        }
    }
    .checkbox-container {
        input {
            margin-right: 10px;
        }
        label {
            font-family: $global-heading-font-family;
            display: block;
            color: $global-color-black;
        }
    }
    .container-nouvelle-adresse {
        .add-adresse-button {
            border: 1px solid $global-color-soft-gray;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-flow: column;
            padding: $padding-padding*1.5;
            min-height: 300px;
            font-family: $global-heading-font-family;
            transition: all .2s ease-in-out;
            &:hover {
                span {
                    color: $global-color-green;
                    border: 1px solid $global-color-green;
                    margin-bottom: $margin-margin*1.5;
                }
            }
            span {
                width: 70px;
                height: 70px;
                border: 1px solid $global-color-gray;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 2rem;
                color: $global-color-black;
                margin-bottom: $margin-margin;
                transition: all .2s ease-in-out;
            }
        }
    }
}

.container-input-radio-choice {
    fieldset {
        border: none;
        padding: 0;
        legend {
            text-transform: uppercase;
            font-family: $global-heading-font-family;
            font-weight: normal;
            letter-spacing: 2px;
            display: flex;
            align-items: center;
            margin-bottom: $margin-margin*2;
            color: $global-color-black;
            font-size: 1.2rem;
            @media (min-width: $breakpoint-medium) {
                font-size: 1.5rem;
            }
            @media (min-width: $breakpoint-xlarge-max) {
                font-size: 2.2rem;
            }
            &:before {
                content: "";
                display: block;
                width: 45px;
                margin-right: $margin-margin;
                height: 1px;
                background: $global-color-green;
                @media (min-width: $breakpoint-small) {
                    width: 65px;
                }
            }
        }
    }
    .item-input-radio {
        border: 1px solid $global-color-soft-gray;
        padding: $padding-padding;
        .container-x-cheque {
            margin: $margin-margin*1.5 0 0 15px;
            font-size: .9rem;
            p {
                margin: 0;
                color: $global-color-black;
            }
            span {
                display: block;
            }
            >* {
                margin-right: $margin-margin*1.5;
            }
        }
        #alma-x-container {
            display: flex;
        }
        .alma-lock {
            cursor: not-allowed;
        }
        .alma-x {
            border: 1px black solid;
            border-radius: 15px;
            padding: 10px;
            margin-right: 10px;
            font-size: 20px;
            width: 50px;
            height: 50px;
            text-align: center;
        }
        .alma-lock .alma-x {
            border-color: #6C6C6C;
            color: #6C6C6C;
        }
        .alma-x:hover {
            background-color: $global-color-green;
            color: white;
            transition-duration: 0.5s;
            border-color: $global-color-green;
            cursor: pointer;
        }
        .alma-check {
            background-color: $global-color-green;
            color: white;
            border-color: $global-color-green;
        }
        .alma-lock .alma-x:hover {
            background-color: white;
            border-color: #6C6C6C;
            color: #6C6C6C;
            cursor: not-allowed;
        }
    }
    label {
        font-family: $global-heading-font-family;
        color: $global-color-black;
    }
    &.container-mode-paiement {
        .item-input-radio {
            display: flex;
            align-items: start;
            justify-content: space-between;
        }
    }
}

.modify-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: all .3s ease-in-out;
    margin-left: 30px;
    &.modify-button-livraison {
        position: absolute;
        bottom: 20px;
        right: 30px;
    }
    &:hover {
        .txt {
            color: $global-color-green;
            border-bottom: 2px solid $global-color-green;
        }
        span {
            color: $global-color-green;
        }
    }
    span {
        font-size: 1.8rem;
        color: $global-color-black;
        transition: all .3s ease-in-out;
    }
    .txt {
        margin-left: 10px;
        color: $global-color-black;
        font-family: $global-heading-font-family;
        font-size: 1rem;
        border-bottom: 2px solid transparent;
        transition: all .3s ease-in-out;
    }
}

.container-buttons {
    display: block;
    @media (min-width: $breakpoint-small) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

.section-modification-adresse,
.section-mes-informations {
    .container-forms {
        display: block;
        .form {
            margin-left: 0;
        }
        .submit-button {
            max-width: 90%;
            margin: 0 auto;
            @media (min-width: $breakpoint-small) {
                max-width: 50%;
            }
        }
    }
}