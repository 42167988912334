//
// Component: Container
//
// ========================================================================
// Variables
// ========================================================================
.uk-container {
    width: 90%;
    padding: 0;
    @media (min-width: $breakpoint-small) {
        width: 85%;
    }
    @media (min-width: $breakpoint-xxlarge) {
        max-width: 1680px;
    }
}