//
// Component: Glossaire
//
// Glossaire
// ========================================================================
.section-glossaire {
    .uk-container {
        width: 90%;
    }
    &__header {
        background: #F9F7F7;
        padding: $padding-padding;
        @media (min-width: $breakpoint-medium) {
            padding: $padding-padding $padding-padding*2;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: 10px;
            li {
                .letter-link {
                    text-transform: uppercase;
                    color: #B7B7B7;
                    transition: all .2s ease-in-out;
                    font-size: 1.5625rem /* 25/16 */;
                    font-family: $global-heading-font-family;
                    display: flex;
                    line-height: 1;
                    align-items: center;
                    justify-content: center;
                    @media (min-width: $breakpoint-medium) {
                        width: 50px;
                        height: 50px;
                        font-size: 2.5rem /* 40/16 */;
                    }
                    &:hover {
                        color: #1D1719;
                    }
                }
            }
        }
    }
    &__content {
        .content-marques {
            display: flex;
            gap: 8vw;
            height: 100%;
            align-items: center;
            justify-content: center;
            margin: 50px 0;
            width: 100%;
            flex-direction: column;
            @media (min-width: $breakpoint-medium) {
                flex-direction: row;
                align-items: flex-start;
                justify-content: center;
                gap: 15vw;
                margin: 80px 0;
            }
        }
        .letter-select {
            font-family: $global-heading-font-family;
            margin: 0;
            font-size: 100px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            gap: 10px;
            color: #1D1719;
            line-height: 1;
            flex-direction: column-reverse;
            width: 150px;
            flex-shrink: 0;
            @media (min-width: $breakpoint-medium) {
                flex-direction: row;
            }
            &:before {
                content: "";
                width: 63px;
                height: 1px;
                background: #789904;
            }
        }
        .marques {
            flex-basis: 100%;
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                columns: 2;
                column-gap: 50px;
                @media (min-width: $breakpoint-large) {
                    columns: 3;
                }
                li{
                    a {
                        font-size: 1.125rem /* 18/16 */;
                    }
                }
            }
        }
    }
}