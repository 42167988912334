//
// Layout: Commandes
//
// Commandes
// ========================================================================
.table-historique-commandes {
    border: 0;
    width: 100%;
    border-spacing: 0;
    @media (min-width: $breakpoint-small) {
        /* border: 1px solid #ccc;
        border-collapse: collapse; */
        margin: 0;
        padding: 0;
        width: 100%;
        table-layout: fixed;
    }
    .item-facture {
        text-align: center;
        a {
            border: 1px solid #ccc;
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            transition: all .2s ease-in-out;
            @media (min-width: $breakpoint-small) {
                width: 4rem;
                height: 4rem;
            }
            &:hover {
                color: $global-color-green;
                border: 1px solid $global-color-green;
                span {
                    color: $global-color-green;
                }
            }
            span {
                color: $global-color-gray;
                font-size: 1.5rem;
                transition: all .2s ease-in-out;
                @media (min-width: $breakpoint-small) {
                    font-size: 2.5rem;
                }
            }
        }
    }
    thead {
        tr {
            display: none;
            @media (min-width: $breakpoint-small) {
                display: table-row;
            }
            th {
                text-transform: uppercase;
                font-weight: normal;
                border: none;
                border-bottom: 1px solid #ccc;
                padding-bottom: 20px;
                text-align: left;
            }
        }
    }
    tbody {
        tr {
            margin-bottom: 50px;
            display: block;
            overflow: auto;
            @media (min-width: $breakpoint-small) {
                overflow: inherit;
                display: table-row;
                margin-bottom: 20px;
            }
        }
    }
    tr {
        padding: .35em;
        td {
            float: left;
            width: 50%;
            display: block;
            border-bottom: 1px solid #ccc;
            text-align: left;
            padding: 10px 0;
            min-height: 100px;
            @media (min-width: $breakpoint-small) {
                float: none;
                width: inherit;
                min-height: initial;
                display: table-cell;
                padding: 30px 0
            }
            &:before {
                content: attr(data-label);
                display: block;
                font-size: .8em;
                font-weight: normal;
                margin-left: 0;
                text-align: left;
                text-transform: uppercase;
                width: auto;
                color: $global-color-gray;
                white-space: nowrap;
                @media (min-width: $breakpoint-small) {
                    content: none;
                }
            }
            a {
                color: $global-color-green;
                border-bottom: 2px solid transparent;
                font-weight: 500;
                display: inline;
                font-size: 1rem;
                transition: all .2s ease-in-out;
                &:hover {
                    border-bottom: 2px solid $global-color-green;
                }
            }
        }
    }
}