//
// Component: Nav bar
//
// Nav bar
// ========================================================================
.uk-navbar-nav {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: transparent;
    width: 100%;
    >li {
        &:hover {
            >a {
                color: $global-color-green!important;
                border-bottom: none!important;
            }
        }
        /* margin-right: $margin-margin*6;
        @media (min-width: $breakpoint-medium) {
            margin-right: $margin-margin*2.5;
        }
        @media (min-width: $breakpoint-large) {
            margin-right: $margin-margin*5;
        }
        @media (min-width: $breakpoint-xlarge) {
            margin-right: $margin-margin*6;
        }
        @media (min-width: $breakpoint-xxlarge) {
            margin-right: $margin-margin*6;
        }
        &:last-child {
            margin-right: 0;
        } */
        >a {
            color: $global-color-black;
            text-rendering: optimizeLegibility;
            font-family: $global-heading-font-family;
            text-transform: none;
            font-size: 1.2em;
            min-height: initial;
            border-bottom: none!important;
            transition: all .3s ease-in-out;
            @media (min-width: $breakpoint-medium) {
                font-size: 1em;
            }
            @media (min-width: $breakpoint-large) {
                font-size: 1em;
            }
            @media (min-width: $breakpoint-xlarge) {
                font-size: 1.2em;
            }
            @media (min-width: $breakpoint-xxlarge) {
                font-size: 1.2em;
            }
            &:hover {
                color: $global-color-green;
                text-decoration: none;
                border-bottom: none;
            }
        }
    }
    .page-link {
        color: $global-color-green;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        font-size: .8rem;
        font-weight: 500;
        transition: all .2s ease-in-out;
        margin: 20% 0 20px 0;
        @media (min-width: $breakpoint-small) {
            margin: 10% 0 20px 0;
        }
        @media (min-width: $breakpoint-medium) {
            margin: 0;
            transform: translate3d(40px, -40px, 0);
            font-size: 1rem;
        }
        &:hover {
            span {
                margin-right: 25px;
            }
        }
        span {
            margin-right: 10px;
            font-weight: bold;
            color: $global-color-black;
            transition: all .2s ease-in-out;
            &:last-child {
                font-weight: 500;
                color: $global-color-green;
                border-bottom: 2px solid $global-color-black;
            }
        }
    }
}

.uk-navbar-dropdown {
    width: 100%;
    left: 0!important;
    .content {
        //display: flex;
        .container-list-subnav {}
        .item-sub-nav {
            display: flex;
            transition: all .2s ease-in-out;
            align-items: center;
            &:before {
                content: "\e901";
                font-family: 'icomoon';
                font-size: 0;
                display: block;
                margin-right: 0;
                color: $global-color-white;
                transition: all .2s ease-in-out;
            }
            &:hover {
                &:before {
                    font-size: 1em;
                    margin-right: calc($margin-margin/4);
                }
            }
        }
        .container-annonce {
            margin-left: 150px;
            position: relative;
            .uk-button {
                position: absolute;
                z-index: 1;
                bottom: 20px;
                left: 40px;
                color: $global-color-white;
                text-transform: uppercase;
            }
        }
    }
    .uk-navbar-dropdown-nav {
        >li>a {
            color: $global-color;
            text-transform: uppercase;
        }
    }
}

[class*='uk-drop-bottom'] {
    margin-top: 0;
}

nav.uk-navbar-container.uk-navbar {
    background: transparent;
}

.uk-navbar-nav>li.uk-active>a {
    color: $global-color-white;
    border-bottom: 8px solid $global-color-white;
}


/*
 * Hover
 * Apply hover style also to focus state and if dropdown is opened
 */

.uk-navbar-nav>li:hover>a,
.uk-navbar-nav>li>a:focus-visible,
.uk-navbar-nav>li>a.uk-open {
    color: $global-color;
    border-bottom: 4px solid $global-color;
}

.uk-navbar-container:not(.uk-navbar-transparent) {
    background: none;
    width: 90%;
    margin: 0 auto;
}