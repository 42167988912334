//
// Component: Header
//
// Header
// ========================================================================
header {
    &#header {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        background: $global-color-white;
        padding: 0;
        position: relative;
        font-size: 18px;
        z-index: 1000;
        top: 0;
        left: 0;
        right: 0;
        padding: 0;
        transition: all .2s ease-in-out;
        @media (min-width: $breakpoint-small) {
            position: fixed;
        }
        &.smaller {
            height: 100px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
            @media (min-width: $breakpoint-small) {
                height: 70px;
            }
            @media (min-width: $breakpoint-medium) {
                height: 80px;
            }
            .container-middle {
                height: 75px;
                .uk-container {
                    padding: 5px 0 20px 0;
                }
                .container-search {
                    height: 30px;
                    margin-top: .2rem;
                    .uk-search {
                        height: 30px;
                        .uk-search-input,
                        .submit-button-search {
                            height: 30px;
                        }
                    }
                }
                .search-marques {
                    height: 30px;
                    .container-select-field {
                        height: 30px;
                        select {
                            height: 30px!important;
                        }
                    }
                }
            }
            .container-navigation {
                .uk-navbar {
                    height: 60px;
                    @media (min-width: $breakpoint-medium-light) {
                        height: 45px;
                    }
                    @media (min-width: $breakpoint-xlarge) {
                        height: 40px;
                    }
                    .uk-navbar-left .uk-navbar-nav li .uk-navbar-dropdown,
                    .uk-navbar-right .uk-navbar-nav li .uk-navbar-dropdown {
                        top: 80px !important;
                    }
                    .uk-navbar-left .uk-navbar-nav>li>a {
                        padding: 10px 0;
                        @media (min-width: $breakpoint-medium-light) {
                            padding: 12px 0;
                        }
                        @media (min-width: $breakpoint-xlarge) {
                            padding: 10px 0;
                        }
                    }
                }
                .container-top-links {
                    padding: 0 $padding-padding;
                }
                .navigation {
                    height: 60px;
                    @media (min-width: $breakpoint-medium-light) {
                        height: 45px;
                    }
                    @media (min-width: $breakpoint-xlarge) {
                        height: 40px;
                    }
                    .uk-navbar-nav {
                        height: 60px;
                        @media (min-width: $breakpoint-medium-light) {
                            height: 45px;
                        }
                        @media (min-width: $breakpoint-xlarge) {
                            height: 40px;
                        }
                        >li:hover>a:after,
                        .uk-navbar-nav>li>a.uk-open:after {
                            //transform: translate3d(0, 21px, 0);
                            @media (min-width: $breakpoint-xlarge) {
                                transform: translate3d(0, 1px, 0);
                            }
                        }
                    }
                    .logo-client {
                        transform: translate3d(-50%, -22px, 0);
                        img {
                            height: 50px;
                        }
                    }
                }
            }
            .container-logo {
                margin-top: -20px;
                a {
                    img {
                        width: 40%;
                    }
                }
            }
            .container-navigation .uk-navbar-nav .uk-navbar-dropdown {
                @media (min-width: $breakpoint-small) {
                    top: 120px !important;
                    margin: 0;
                }
            }
        }
        .banner-top {
            background: $global-color-black;
            width: 100%;
            padding: 10px 0;
            display: none;
            @media (min-width: $breakpoint-small) {
                display: block;
            }
            .uk-container-banner-top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: white;
                &>* {
                    margin: 0;
                    padding: 0;
                    font-size: .6rem;
                    @media (min-width: $breakpoint-medium-light) {
                        font-size: .9rem;
                    }
                }
                p {
                    span {
                        margin-right: 10px;
                    }
                }
                a {
                    color: white;
                    &:hover {
                        color: $global-color-green;
                    }
                }
            }
        }
        .container-middle {
            width: 100%;
            height: 140px;
            background: url("../images/motif.svg") repeat center center;
            background-size: 40%;
            border-bottom: 1px solid #e2e2e2;
            transition: all .2s ease-in-out;
            @media (min-width: $breakpoint-small) {
                height: 140px;
                background-size: 17%;
            }
            @media (min-width: $breakpoint-medium) {
                background-size: 12%;
                border-bottom: none;
            }
            @media (min-width: $breakpoint-medium-light) {
                background-size: 8%;
            }
            @media (min-width: $breakpoint-xxlarge) {
                background-size: 6%;
            }
            .uk-container {
                justify-content: space-between;
                height: 100%;
                padding: 0;
                width: 90%;
                @media (min-width: $breakpoint-medium) {
                    padding: $padding-padding 0;
                }
            }
            .container-left {
                z-index: 1;
                height: 50px;
                display: none;
                @media (min-width: $breakpoint-medium) {
                    display: block;
                }
                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 1rem;
                    /*   @media (min-width: $breakpoint-small) {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        grid-gap: 10px;
                    } */
                    @media (min-width: $breakpoint-medium-light) {
                        display: flex;
                        grid-gap: initial;
                    }
                    li {
                        display: flex;
                        align-items: center;
                    }
                }
            }
            .container-right {
                z-index: 1;
                display: none;
                @media (min-width: $breakpoint-medium) {
                    display: block;
                }
                .compte-items {
                    margin: -5px 0 0 0;
                    padding: 0;
                    list-style: none;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    gap: 1rem;
                    /*  @media (min-width: $breakpoint-small) {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        grid-gap: 10px;
                    } */
                    @media (min-width: $breakpoint-medium-light) {
                        display: flex;
                        grid-gap: initial;
                    }
                    li {
                        display: flex;
                        align-items: center;
                    }
                }
            }
            .container-search {
                height: 50px;
                display: none;
                margin-top: 1rem;
                @media (min-width: $breakpoint-medium) {
                    display: flex;
                }
            }
            .container-left,
            .container-right {
                a {
                    text-transform: uppercase;
                    font-size: .85rem;
                    margin-right: 20px;
                    @media (min-width: $breakpoint-small) {
                        font-size: .7rem;
                        margin-right: 5px;
                    }
                    @media (min-width: $breakpoint-xlarge) {
                        font-size: .85rem;
                        margin-right: 20px;
                    }
                    &.item-nav__panier {
                        color: $global-color-green;
                        display: flex;
                        align-items: center;
                        gap: .5rem;
                        position: relative;
                        transition: all .2s ease-in-out;
                        &:hover {
                            color: $global-color;
                            .icon-panier {
                                background: #000;
                                color: $global-color-green;
                            }
                            .count-panier {
                                background: $global-color-green;
                            }
                        }
                        .icon-panier {
                            font-size: 1rem;
                            width: 35px;
                            height: 35px;
                            background: $global-color-green;
                            fill: #fff;
                            color: #fff;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            transition: all .2s ease-in-out;
                        }
                        .count-panier {
                            background: #000;
                            transition: all .2s ease-in-out;
                            color: #fff;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 25px;
                            height: 25px;
                            font-size: .7rem;
                            border-radius: 50%;
                            position: absolute;
                            right: -15px;
                            top: -8px;
                        }
                    }
                }
                .search-global,
                .search-marques {
                    width: 100%;
                    @media (min-width: $breakpoint-medium-light) {
                        width: 48%;
                    }
                    @media (min-width: $breakpoint-xlarge) {
                        width: 100%;
                    }
                }
                .container-select-field {
                    background: $global-color-soft-gray;
                }
            }
        }
        .container-logo {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 0;
            @media (min-width: $breakpoint-small) {
                //margin-top: 20px;
            }
            @media (min-width: $breakpoint-medium) {
                margin-top: -10px;
            }
            @media (min-width: $breakpoint-xxlarge) {
                margin-top: -20px;
            }
            a {
                border: none;
                height: 110px;
                text-align: center;
                @media (min-width: $breakpoint-medium-light) {
                    height: 130px;
                }
                &:hover {
                    border-bottom: none;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
        .container-navigation {
            position: fixed;
            top: 0;
            width: 100%;
            padding: 0;
            height: calc(100vh - 70px);
            z-index: 10;
            right: -100%;
            background: $global-color-white;
            transition: all .3s ease-in-out;
            @media (min-width: $breakpoint-medium) {
                z-index: 0;
                height: inherit;
                right: inherit;
                position: relative;
                width: 100%;
            }
            &.open {
                right: 0;
            }
            .navigation {
                height: calc(100vh - 70px);
                padding: $padding-padding;
                overflow-y: auto;
                border-left: 2px solid $global-color-green;
                @media (min-width: $breakpoint-medium) {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: auto;
                    padding: 0;
                    overflow-y: initial;
                    border-top: 1px solid #E5E1E2;
                    border-bottom: 1px solid #E5E1E2;
                    border-left: none;
                }
                @media (min-width: $breakpoint-medium) {
                    height: 65px;
                }
                .logo-mobile-nav {
                    text-align: center;
                    display: block;
                    width: 50%;
                    margin: 0 auto 20px auto;
                    @media (min-width: $breakpoint-medium) {
                        display: none;
                    }
                }
            }
            .container-top-links {
                display: none;
                @media (min-width: $breakpoint-small) {
                    display: block;
                    background: $global-color;
                    padding: calc($padding-padding/4) $padding-padding;
                }
                @media (min-width: $breakpoint-medium) {
                    display: block;
                    background: $global-color;
                    padding: calc($padding-padding/4) $padding-padding;
                }
                ul {
                    display: flex;
                    align-items: center;
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    .uk-dropdown {
                        background: $global-color-white;
                        z-index: 100;
                        padding: $padding-padding;
                        min-width: 410px;
                        .uk-dropdown-nav {
                            display: block;
                            li {
                                a {
                                    color: $global-color;
                                    display: flex;
                                    transition: all .3s ease-in-out;
                                    align-items: center;
                                    border-bottom: none;
                                    &:before {
                                        content: "\e901";
                                        font-family: 'icomoon';
                                        margin-right: 0;
                                        display: block;
                                        font-size: 0;
                                        color: $global-color;
                                        transition: all .3s ease-in-out;
                                    }
                                    &:hover {
                                        border-bottom: none;
                                        text-decoration: none;
                                        &:before {
                                            font-size: 1em;
                                            margin-right: calc($margin-margin/4);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    li {
                        margin-right: $margin-margin;
                        a {
                            text-transform: uppercase;
                            color: $global-color;
                            font-weight: bold;
                            text-rendering: optimizeLegibility;
                            border-bottom: none;
                            transition: all .3s ease-in-out;
                            @media (min-width: $breakpoint-small) {
                                font-size: .6em;
                            }
                            @media (min-width: $breakpoint-medium) {
                                font-size: .75em;
                            }
                            @media (min-width: $breakpoint-medium-light) {
                                font-size: .85em;
                            }
                            &:hover {
                                text-decoration: none;
                                border-bottom: none;
                                color: $global-color;
                            }
                            &.social-link {
                                span {
                                    font-size: 2em;
                                }
                            }
                        }
                    }
                }
            }
            .uk-navbar-nav {
                display: block;
                margin-bottom: 50px;
                @media (min-width: $breakpoint-medium) {
                    width: 100%;
                    display: flex;
                    margin: 0;
                }
                >li {
                    margin-right: 0;
                    border-bottom: 1px solid #e2e2e2;
                    margin-bottom: 5px;
                    @media (min-width: $breakpoint-medium) {
                        margin-bottom: inherit;
                        border-bottom: none;
                    }
                    &.container-marques {
                        display: none;
                        @media (min-width: $breakpoint-medium) {
                            display: block;
                        }
                        .marques {
                            display: flex;
                            align-items: center;
                            a {
                                margin-right: 20px;
                                border-bottom: none;
                                @media (min-width: $breakpoint-small) {
                                    width: 20px;
                                    margin-right: 5px;
                                    display: flex;
                                    align-items: center;
                                }
                                @media (min-width: $breakpoint-medium) {
                                    width: 25px;
                                    margin-right: 10px;
                                }
                                @media (min-width: $breakpoint-medium-light) {
                                    width: inherit;
                                    margin-right: 20px;
                                }
                                &:hover {
                                    border-bottom: none;
                                    transform: translate3d(0, -5px, 0);
                                }
                                figure {
                                    padding: 0;
                                    margin: 0;
                                    width: 46px;
                                }
                                span {
                                    font-size: 3.2em;
                                    color: #30AF0A;
                                    @media (min-width: $breakpoint-small) {
                                        font-size: 1.2em;
                                    }
                                    @media (min-width: $breakpoint-medium-light) {
                                        font-size: 2.5em;
                                    }
                                }
                            }
                        }
                    }
                    &.item-mobile {
                        .mobile-link-blue {
                            color: $global-color;
                            font-size: .8em;
                            letter-spacing: 1px;
                            &:after {
                                content: none;
                            }
                        }
                        &.item-social-mobile {
                            display: flex;
                            align-items: center;
                            border-bottom: none;
                            a {
                                font-size: 2em;
                            }
                        }
                        &.item-mobile-comparateur {
                            a {
                                display: block;
                            }
                        }
                    }
                    >button,
                    >a {
                        border-bottom: none;
                        width: 100%;
                        font-weight: 400;
                        position: relative;
                        font-family: $global-heading-font-family;
                        font-size: 1.1rem;
                        padding: 5px 0;
                        border: none;
                        background: none;
                        transition: all .2s ease-in-out;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        @media (min-width: $breakpoint-small) {
                            font-size: .8rem;
                            padding: 0;
                        }
                        @media (min-width: $breakpoint-large) {
                            font-size: 1.1rem;
                            justify-content: initial;
                        }
                        &:after {
                            content: "\e903";
                            font-family: 'icomoon';
                            font-size: .6rem;
                            margin-left: 8px;
                            transform: rotate(-90deg);
                            @media (min-width: $breakpoint-small) {
                                margin-left: 3px;
                            }
                            @media (min-width: $breakpoint-medium) {
                                transform: initial;
                            }
                            @media (min-width: $breakpoint-large) {
                                margin-left: 8px;
                            }
                        }
                        &:hover {
                            color: $global-color-green;
                        }
                        &:focus-visible {
                            box-shadow: 0 0 10px $global-color-green!important;
                            border: 2px solid $global-color-green;
                            border-bottom: 2px solid $global-color-green!important;
                        }
                    }
                }
                .uk-navbar-dropdown {
                    max-width: 100%;
                    width: 100%!important;
                    position: fixed;
                    padding: 5%;
                    z-index: 1;
                    background: #F9F7F7;
                    box-shadow: none;
                    top: 0!important;
                    border-bottom: 1px solid $global-color-soft-gray;
                    @media (min-width: $breakpoint-small) {
                        padding: 2%;
                        margin: 17px 0 0 0;
                        top: initial !important;
                    }
                    @media (min-width: $breakpoint-medium-light) {
                        padding: 3% 4%;
                    }
                    .content {
                        ul {
                            border-bottom: 1px solid $global-color;
                            padding-bottom: 10px;
                            @media (min-width: $breakpoint-small) {
                                border-bottom: none;
                                font-size: .65rem;
                                margin: 0;
                                padding: 0;
                            }
                            @media (min-width: $breakpoint-medium) {
                                font-size: .8rem;
                            }
                            @media (min-width: $breakpoint-large) {
                                font-size: 1rem;
                            }
                            li {
                                &.title_subnav {
                                    text-transform: uppercase;
                                    color: $global-color;
                                    padding-bottom: 0;
                                    @media (min-width: $breakpoint-small) {
                                        padding-bottom: $padding-padding;
                                    }
                                    a {
                                        display: flex;
                                        transition: all .2s ease-in-out;
                                        align-items: center;
                                        border-bottom: none;
                                        color: $global-color-black;
                                        font-weight: 500;
                                        font-size: .8rem;
                                        @media (min-width: $breakpoint-small) {
                                            font-size: .7rem;
                                        }
                                        @media (min-width: $breakpoint-medium-light) {
                                            font-size: 1rem;
                                        }
                                        &:hover {
                                            border-bottom: none;
                                            color: $global-color-green;
                                        }
                                    }
                                    .item-sub-nav {
                                        border-bottom: none;
                                        text-transform: none;
                                        margin-left: 0;
                                        font-weight: normal!important;
                                        color: $global-color-gray!important;
                                        font-size: .7rem;
                                        display: flex;
                                        align-items: center;
                                        @media (min-width: $breakpoint-small) {
                                            font-size: .7rem;
                                        }
                                        @media (min-width: $breakpoint-medium-light) {
                                            font-size: .9rem;
                                        }
                                        &:before {
                                            content: "";
                                            background: $global-color-green;
                                            height: 2px;
                                            width: 0;
                                            display: block;
                                        }
                                        &:hover {
                                            margin-left: 5px;
                                            &:before {
                                                width: 20px;
                                            }
                                        }
                                        &.link-suite {
                                            text-transform: uppercase;
                                            margin: calc($margin-margin/2) 0;
                                            color: $global-color-green!important;
                                            &:before {
                                                content: none;
                                            }
                                            span {
                                                transition: all .2s ease-in-out;
                                                font-size: 1.5rem;
                                                margin-right: 5px;
                                                @media (min-width: $breakpoint-small) {
                                                    font-size: .8rem;
                                                }
                                                @media (min-width: $breakpoint-medium-light) {
                                                    font-size: 1.5rem;
                                                }
                                            }
                                            &:hover {
                                                span {
                                                    margin-right: 15px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .container-comparateur-button {
            width: 100px!important;
            min-width: 100px;
            background: $global-color;
            transition: all .3s ease-in-out;
            display: none;
            @media (min-width: $breakpoint-small) {
                width: 70px!important;
                min-width: 70px;
                display: block;
            }
            @media (min-width: $breakpoint-medium) {
                width: 140px!important;
                min-width: 140px;
            }
            &.active {
                animation: comparateurButtonAnimation 1.5s ease-in-out infinite;
            }
            &:hover {
                background: $global-color;
            }
            a {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $global-color-white;
                border-bottom: none;
                font-size: 4em;
                @media (min-width: $breakpoint-small) {
                    font-size: 3em;
                }
                @media (min-width: $breakpoint-medium-light) {
                    font-size: 4em;
                }
                &:hover {
                    border-bottom: none;
                }
            }
            .count {
                background: $global-color;
                color: $global-color;
                font-size: .2em;
                position: absolute;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                bottom: 37px;
                right: 30px;
                @media (min-width: $breakpoint-small) {
                    bottom: 7px;
                    right: 5px;
                    width: 20px;
                    height: 20px;
                }
                @media (min-width: $breakpoint-medium-light) {
                    bottom: 37px;
                    right: 30px;
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }
}

@keyframes comparateurButtonAnimation {
    0% {
        background-color: $global-color;
    }
    50% {
        background-color: $global-color;
    }
    100% {
        background-color: $global-color;
    }
}

body.mobile {
    .container-top-elements-mobile {
        height: 70px;
        display: flex;
        @media (min-width: $breakpoint-small) {
            display: none;
        }
        .title-element {
            color: white;
            background: $global-color;
            height: 70px;
            padding-left: 30px;
            text-transform: uppercase;
            margin: 0;
            line-height: 1;
            display: flex;
            flex-flow: column;
            justify-content: center;
            span {
                display: block;
                color: $global-color;
                font-style: italic;
                text-transform: none;
            }
        }
        .close-mobile-nav-button,
        .close-search-button {
            display: block;
            color: $global-color-white;
            background: $global-color;
            padding: 10px;
            z-index: 1000;
            font-size: 1.5em;
            border: none;
            border-radius: 0;
            position: relative;
            top: inherit;
            right: inherit;
            @media (min-width: $breakpoint-small) {
                display: none;
            }
        }
        &.search-mobile-container {
            .title-element {
                background-image: linear-gradient(rgba($color: $global-color, $alpha: 1.0), rgba($color: $global-color, $alpha: .65));
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
            }
        }
    }
    .container-navigation {
        right: -100%;
        .close-mobile-nav-button {
            display: block;
        }
    }
    header#header .container-navigation .uk-navbar-nav .uk-navbar-dropdown {
        transform: translate3d(0, 0, 0);
        margin: 0;
        max-width: 100%;
        position: fixed;
        right: 0;
        width: 100%!important;
        height: 105vh;
        top: 0!important;
        background: #F9F7F7;
        justify-content: center;
        z-index: 100;
        opacity: 1;
        padding-bottom: 190px;
        transition: all .3s ease-in-out;
        flex-flow: column;
        @media (min-width: $breakpoint-medium) {
            flex-flow: initial;
            padding-bottom: inherit;
            position: relative;
            top: inherit!important;
            width: inherit!important;
            height: auto;
            background: $global-color-white;
        }
        .back-button-subnav-mobile {
            position: absolute;
            left: 10px;
            top: 25px;
            display: flex;
            align-items: center;
            span {
                margin-right: 10px;
                color: $global-color-green;
            }
            button {
                border-bottom: 2px solid $global-color-green;
                background: none;
                color: $global-color-black;
                font-size: 1em;
                font-family: $global-heading-font-family;
                font-weight: 500;
                padding: 0;
            }
        }
        .content {
            display: block;
            width: 100%;
            margin: 0 auto;
            height: 100%;
            overflow-y: scroll;
            @media (min-width: $breakpoint-small) {
                display: flex;
                flex-flow: column;
                align-items: center;
                height: inherit;
                margin: 5% auto 0 auto;
            }
            @media (min-width: $breakpoint-medium) {
                justify-content: center;
                margin: 0 auto;
                overflow-y: inherit;
            }
            .container-list-subnav {
                &.uk-grid>* {
                    padding-left: 0;
                    margin-top: 0;
                }
            }
            >div {
                border-bottom: 1px solid $global-color;
                ul {
                    border-bottom: none;
                    padding-bottom: 0;
                }
                &:last-child {
                    border-bottom: none;
                }
            }
            .uk-width-1-2 {
                width: 100%;
            }
        }
        .container-annonce {
            margin-left: 0;
        }
        &.uk-open {
            display: flex;
        }
    }
}

.back-button-subnav-mobile {
    display: none;
}

#container-nav-mobile {
    display: block;
    position: fixed;
    bottom: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    z-index: 1010;
    background: $global-color-black;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.7);
    @media (min-width: $breakpoint-medium) {
        right: 0;
        z-index: 999;
        top: 241px;
        width: 50px;
        height: 899px;
        box-shadow: none;
    }
    @media (min-width: $breakpoint-large) {
        width: 80px;
    }
    @media (min-width: $breakpoint-xxlarge) {
        top: 249px;
        height: 636px;
    }
    @media (min-width: $breakpoint-xlarge-max) {
        top: 249px;
        height: 886px;
    }
    .container-input-search-mobile {
        @media (max-width: $breakpoint-small-max) {
            position: absolute;
            width: 100%;
            z-index: -1;
            bottom: -70px;
            background: white;
            border-top: 1px solid $global-color-dark-gray;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .2s ease-in-out;
        }
        display: none;
        &.show {
            bottom: 70px;
            opacity: 1;
        }
    }
    .list-items-nav-mobile {
        padding: 0;
        list-style: none;
        margin: 0;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 10px;
        align-items: center;
        width: 100%;
        background: $global-color-black;
        @media (min-width: $breakpoint-medium) {
            display: flex;
            flex-flow: column-reverse;
            justify-content: center;
            height: 899px;
        }
        @media (min-width: $breakpoint-xxlarge) {
            height: 636px;
        }
        @media (min-width: $breakpoint-xlarge-max) {
            height: 886px;
        }
        li {
            a,
            button {
                display: block;
                border-bottom: none;
                span {
                    color: white;
                    transition: all .3s ease-in-out;
                }
                &:hover {
                    border-bottom: none;
                    span {
                        color: $global-color-green;
                    }
                }
                &.item-nav-mobile {
                    color: white;
                    font-size: 1.8rem;
                    font-weight: 500;
                    padding: 5px;
                    height: 70px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &.btn-mobile-menu {
                        background: #5B5456;
                        overflow: hidden;
                        @media (min-width: $breakpoint-medium) {
                            display: none;
                        }
                    }
                    &#btn_search_mobile {
                        @media (min-width: $breakpoint-medium) {
                            display: none;
                        }
                    }
                    &.active {
                        color: white;
                    }
                }
            }
        }
    }
}

#bloc_panier {
    position: relative;
    &:hover {
        span.count-panier {
            background: white;
            color: $global-color-green;
        }
    }
    span.count-panier {
        position: absolute;
        right: 10px;
        top: 8px;
        font-size: .8rem;
        line-height: 1;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: $global-color-green;
    }
}

.comparate-bulle {
    background: $global-color;
    color: $global-color;
    border-radius: 50%;
    display: inline-block;
    width: 20px;
    height: 20px;
    text-align: center;
}

.facebook-link {
    display: flex;
    align-items: center;
    span {
        &::before {
            font-size: 1.2rem;
            margin-right: 5px;
        }
    }
}