/**
 * Style.css
 *
 */

// 1. Custom variables and variables overwrites.
// @import "variables-theme";
@import "variables";
// 2. Import default variables and available mixins.
@import "uikit/src/scss/variables-theme";
@import "uikit/src/scss/mixins-theme";
@import 'uikit-theme/_import';
// 4. Import UIkit
@import "uikit/src/scss/uikit";
// 5. Custom Files
@import 'custom/_import';
//@import 'nouislider/nouislider';
*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    height: 100%;
}

html,
body {
    margin: 0;
    padding: 0;
    //overflow-x: hidden;
}

body {
    &.mobile {
        overflow-x: hidden;
    }
}

strong {
    color: $global-color-black;
}

.site-content {
    padding: 0;
    position: relative;
    @media (min-width: $breakpoint-small) {
        margin-top: 130px;
    }
    @media (min-width: $breakpoint-medium) {
        margin-top: 235px;
    }
}

.listing {
    margin: 0;
    padding: 0;
    list-style: none;
    grid-gap: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media (min-width: $breakpoint-medium) {
        max-width: 100%;
        margin: 0 auto;
    }
    @media (min-width: $breakpoint-medium-light) {
        display: flex;
        //align-items: center;
        justify-content: space-between;
    }
    .swiper-slide {
        flex-shrink: initial;
        height: initial;
        width: 100%;
    }
    &.listing-promo {
        grid-template-columns: repeat(1, 1fr);
        margin-top: 25px;
        @media (min-width: $breakpoint-medium-light) {
            margin-top: 0;
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

a.evitement {
    color: white;
    background: transparent;
    padding: 10px 20px;
    position: absolute;
    z-index: 10000;
    margin: 0 auto;
    left: -100%;
    top: 6px;
    border: 1px solid white;
    font-size: .8rem;
    line-height: .8;
}

a.evitement:focus-visible {
    left: 10px;
    background: $global-color-green;
}

a:focus-visible {
    box-shadow: 0 0 10px $global-color-green!important;
    border: 2px solid $global-color-green;
    border-bottom: 2px solid $global-color-green!important;
}

section {
    position: relative;
}

.container-img-pattern {
    background: url("../images/motif-sombre.svg") repeat center center;
    background-size: 20%;
    padding: $padding-padding!important;
    position: relative;
    max-height: 430px;
    @media (min-width: $breakpoint-medium) {
        overflow: hidden;
    }
    &:hover {
        figure {
            img {
                transform: scale(1.05);
            }
        }
        .link-img-pattern {
            background: rgba($color: #000000, $alpha: .3);
            span {
                bottom: 80px;
                background: white;
                &:after {
                    height: 83px;
                    bottom: -83px;
                }
                &:before {
                    color: $global-color-black;
                }
            }
        }
    }
    .link-img-pattern {
        position: absolute;
        z-index: 100;
        background: rgba($color: #000000, $alpha: .7);
        font-family: $global-heading-font-family;
        font-size: 1rem;
        letter-spacing: 2px;
        color: white;
        text-transform: uppercase;
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        padding: $padding-padding;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-flow: column;
        overflow: hidden;
        text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
        transition: all .2s ease-in-out;
        @media (min-width: $breakpoint-large) {
            font-size: 1.5rem;
        }
        span {
            border: 1px solid white;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: 40px;
            transition: all .3s ease-in-out;
            @media (min-width: $breakpoint-medium) {
                width: 30px;
                height: 30px;
                bottom: 30px;
            }
            @media (min-width: $breakpoint-large) {
                width: 50px;
                height: 50px;
                bottom: 70px;
            }
            &:after {
                content: "";
                height: 73px;
                bottom: -73px;
                width: 1px;
                background: white;
                display: block;
                position: absolute;
                transition: all .3s ease-in-out;
            }
            &:before {
                margin-left: 2px;
            }
        }
    }
    figure {
        padding: 0;
        margin: 0;
        overflow: hidden;
        height: calc(430px - 40px);
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            transition: all .3s ease-in-out;
        }
    }
    &.link-page-nouveautes {
        max-height: 100%;
        background-size: 60%;
        min-height: 400px;
        max-height: initial;
        @media (min-width: $breakpoint-small) {
            min-height: 580px;
            background-size: 30%;
        }
        figure {
            max-height: 100%;
            height: calc(400px - 40px);
            @media (min-width: $breakpoint-small) {
                height: calc(580px - 40px);
            }
        }
        .link-img-pattern {
            background: rgba(#789904, $alpha: .5);
            width: calc(100% - 40px);
            height: calc(100% - 40px);
            filter: contrast(200%);
            font-size: .9rem;
            @media (min-width: $breakpoint-small) {
                font-size: 1rem;
            }
            &:before {
                content: "";
                background: url('../images/bois.svg') no-repeat center center;
                background-size: contain;
                width: 100px;
                height: 67px;
                margin-bottom: 20px;
                display: block;
                @media (min-width: $breakpoint-small) {
                    width: 120px;
                    margin-bottom: 50px;
                }
            }
            &:hover {
                text-decoration: none;
                border-bottom: none;
            }
        }
    }
}

.content-link {
    @media (max-width: $breakpoint-small-max) {
        justify-content: center;
    }
    color: $global-color-black;
    text-transform: none;
    border: none;
    margin-top: calc($margin-margin/2);
    transition: all .2s ease-in-out;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
        border-bottom: 1px solid $global-color-green;
        font-family: $global-heading-font-family;
        font-weight: 500;
        transition: all .2s ease-in-out;
    }
    &:hover {
        border: none;
        text-decoration: none;
        span {
            color: $global-color-green;
        }
    }
    &:before {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50px;
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
        background: $global-color-green;
    }
}

.content-link-contact {
    @media (max-width: $breakpoint-small-max) {
        justify-content: center;
    }
    color: $global-color-black;
    text-transform: none;
    border: none;
    margin-top: calc($margin-margin/2);
    transition: all .2s ease-in-out;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    input {
        border: none;
        background: none;
        border-bottom: 1px solid $global-color-green;
        font-family: $global-heading-font-family;
        font-weight: 500;
        cursor: pointer;
        font-size: 1.2rem;
        padding: 0;
        transition: all .2s ease-in-out;
    }
    &:hover {
        input {
            color: $global-color-green;
        }
    }
    &:before {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50px;
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
        background: $global-color-green;
    }
}

.button-background {
    height: 100%;
    width: 100%;
    border: none;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.grecaptcha-badge {
    bottom: 70px!important;
    z-index: 100;
}

body .buorg {
    position: absolute;
    position: fixed;
    z-index: 1111111;
    width: 100%;
    bottom: 0px;
    left: 0px;
    border-bottom: 1px solid $global-color;
    text-align: center;
    color: $global-color-white;
    background-color: $global-color;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    animation: 1s ease-out 0s buorgfly;
    .buorg-pad {
        padding: 9px;
        line-height: 1.7em;
        .buorg-buttons {
            display: block;
            text-align: center;
        }
    }
}

#buorgig,
#buorgul,
#buorgpermanent {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
    padding: 1px 10px;
    border-radius: 4px;
    font-weight: normal;
    background: #5ab400;
    white-space: nowrap;
    margin: 0 2px;
    display: inline-block;
}

#buorgig {
    background-color: #edbc68;
}

@media only screen and (max-width: 700px) {
    .buorg div {
        padding: 5px 12px 5px 9px;
        line-height: 1.3em;
    }
}

@keyframes buorgfly {
    from {
        opacity: 0;
        transform: translateY(-50px)
    }
    to {
        opacity: 1;
        transform: translateY(0px)
    }
}

.buorg-fadeout {
    transition: visibility 0s 8.5s, opacity 8s ease-out .5s;
}

.buorg-icon {
    width: 22px;
    height: 16px;
    vertical-align: middle;
    position: relative;
    top: -0.05em;
    display: inline-block;
    background: no-repeat 0px center;
}

.section-intro {
    margin: 2rem auto;
    max-width: 95%;
    font-weight: 500;
    @media (min-width: $breakpoint-small) {
        margin: 3rem auto;
        max-width: 70%;
    }
}

.page__404 {
    .section-intro {
        .center {
            .uk-button-default {
                margin: 0 auto;
            }
        }
    }
}

.sticky-container {
    height: 70px;
    position: relative;
    width: 368px;
    @media (min-width: $breakpoint-small) {
        width: 216px;
    }
    @media (min-width: $breakpoint-medium-light) {
        width: 253px;
    }
    @media (min-width: $breakpoint-large) {
        width: 272px;
    }
    @media (min-width: $breakpoint-xlarge-1440) {
        width: 289px;
    }
    @media (min-width: $breakpoint-xlarge) {
        width: 343px;
    }
    @media (min-width: $breakpoint-xxlarge) {
        width: 301px;
    }
    @media (min-width: $breakpoint-xlarge-max) {
        width: 368px;
    }
}

.sticky-button {
    background: $global-color;
    transition: background 250ms;
    width: auto;
    display: block;
    padding: 15px;
    border-radius: 25px;
    color: $global-color;
    font-size: .9em;
    text-transform: uppercase;
    margin: 0 auto;
    border: none;
    cursor: pointer;
    &:hover {
        background: $global-color;
        color: white;
    }
    &.stuck {
        position: fixed;
        z-index: 100000;
        bottom: 0;
        margin: 0 0 0 60px;
        @media (min-width: $breakpoint-small) {
            margin: 0 0 0 60px;
        }
        @media (min-width: $breakpoint-medium-light) {
            margin: 0 0 0 25px;
        }
        @media (min-width: $breakpoint-large) {
            margin: 0 0 0 30px;
        }
        @media (min-width: $breakpoint-xlarge-1440) {
            margin: 0 0 0 43px;
        }
        @media (min-width: $breakpoint-xlarge) {
            margin: 0 0 0 70px;
        }
        @media (min-width: $breakpoint-xxlarge) {
            margin: 0 0 0 45px;
        }
        @media (min-width: $breakpoint-xlarge-max) {
            margin: 0 0 0 90px;
        }
    }
}


/******  Alerte message   *****/

.alert_message.uk-modal {
    .uk-modal-dialog {
        transform: translateY(-100px);
        width: 60%;
        background: white;
        color: #231f20;
        @media (min-width: $breakpoint-xlarge) {
            width: 40%;
        }
        .uk-close {
            color: #231f20;
        }
        .titre {
            font-family: $global-font-family;
            text-transform: uppercase;
            font-size: 1.5em;
            font-weight: bold;
        }
    }
    .errors {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }
    }
    /*     &.uk-open {
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1010;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding: 15px 15px;
        background: rgba(0, 0, 0, 0.6);
        opacity: 1;
        transition: opacity .15s linear;
        .uk-modal-dialog {
            transform: translateY(250px);
            opacity: 1;
        }
        &.error {
            .uk-modal-dialog {
                border: 5px solid $global-color;
                height: auto;
            }
        }
        &.success {
            .uk-modal-dialog {
                border: 5px solid $global-color;
                height: auto;
            }
        }
        &.warning {
            .uk-modal-dialog {
                border: 5px solid $global-color;
                height: auto;
            }
        }
    } */
}

.icon-swipe {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    font-size: 6em;
    color: $global-color;
    @media (min-width: $breakpoint-small) {
        display: none;
    }
}

#toolbarContainer {
    display: none;
}

#burger-menu {
    display: block;
    z-index: 10;
    background: none;
    border: none;
    transition: all .2s ease-in-out;
    width: 60px;
    height: 60px;
    @media (min-width: $breakpoint-medium) {
        display: none;
    }
}

#nav-icon1 {
    width: 30px;
    height: 40px;
    position: relative;
    margin: 0 auto;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
}

#nav-icon1 span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: white;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
    top: 10px;
}

#nav-icon1 span:nth-child(2) {
    top: 18px;
    width: 50%;
}

#nav-icon1 span:nth-child(3) {
    top: 26px;
}

#nav-icon1.open span:nth-child(1) {
    top: 18px;
    transform: rotate(135deg);
}

#nav-icon1.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}

#nav-icon1.open span:nth-child(3) {
    top: 18px;
    transform: rotate(-135deg);
}

.section-swiper-bandeau {
    .uk-position-top-center-h1 {
        margin-top: 50px;
        @media (min-width: $breakpoint-small) {
            margin-top: 90px;
        }
        figure {
            img {
                //width: 80%;
            }
        }
        h1 {
            color: $global-color-black;
            text-shadow: none;
            margin-top: 25px;
        }
    }
}

.section-swiper-facettes {
    .uk-container {
        width: 100%;
        padding-left: 5%;
        @media (min-width: $breakpoint-medium) {
            width: 90%;
            padding: 0;
        }
    }
    .swiper-facette {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
        @media (min-width: $breakpoint-medium) {
            width: 80%;
            margin: 0 0 0 auto;
        }
        .swiper-wrapper {
            margin: 0;
            padding: 0;
        }
        .swiper-slide {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 20px;
            height: auto!important;
            align-items: start!important;
            min-width: 930px;
            @media (min-width: $breakpoint-medium) {
                min-width: initial;
            }
            .container-img-pattern {
                max-height: 30vh;
                width: 300px;
                @media (min-width: $breakpoint-medium) {
                    max-height: 50vh;
                    width: initial;
                }
            }
            figure {
                height: calc(30vh - 40px)!important;
                @media (min-width: $breakpoint-medium) {
                    height: calc(50vh - 40px)!important;
                }
            }
            .col-1 {
                @media (min-width: $breakpoint-medium) {
                    height: 50vh;
                    grid-column: 1 / 3;
                    grid-row: 1 / 3;
                    width: 100%;
                    .container-img-pattern {
                        background-size: 15%;
                    }
                }
            }
            .col-2 {
                @media (min-width: $breakpoint-medium) {
                    grid-column: 3;
                    grid-row: 1 / 2;
                    .container-img-pattern {
                        background-size: 28%;
                    }
                }
            }
            .col-3 {
                @media (min-width: $breakpoint-medium) {
                    grid-column: 3;
                    grid-row: 2 / 3;
                    .container-img-pattern {
                        background-size: 28%;
                    }
                }
            }
            .col-2,
            .col-3 {
                height: 30vh;
                @media (min-width: $breakpoint-medium) {
                    height: 24vh;
                    width: 100%;
                }
                .container-img-pattern {
                    max-height: 30vh;
                    width: 300px;
                    @media (min-width: $breakpoint-medium) {
                        max-height: 24vh;
                        width: 100%;
                    }
                    .link-img-pattern {
                        span {
                            bottom: 40px;
                            @media (min-width: $breakpoint-medium) {
                                bottom: 10px;
                            }
                            &:after {
                                height: 73px;
                                bottom: -73px;
                                @media (min-width: $breakpoint-medium) {
                                    height: 44px;
                                    bottom: -44px;
                                }
                            }
                        }
                    }
                }
                figure {
                    height: calc(30vh - 40px)!important;
                    @media (min-width: $breakpoint-medium) {
                        height: calc(24vh - 40px)!important;
                    }
                }
            }
        }
        .container-swiper-buttons {
            position: absolute;
            left: 5%;
            top: 50%;
            transform: translateY(-50%);
        }
        .swiper-button-prev,
        .swiper-button-next {
            left: initial;
            right: initial;
            position: relative;
            margin: calc($margin-margin*3) 0;
            display: none;
            @media (min-width: $breakpoint-medium) {
                display: block;
            }
            &:hover {
                transition: all .2s ease-in-out;
                &:after {
                    border: 1px solid rgba($global-color-black, 1)!important;
                }
            }
            &:after {
                content: "\e904";
                font-family: 'icomoon';
                font-size: 2rem;
                color: $global-color-black;
                border: 1px solid rgba($global-color-black, .5)!important;
                width: 70px;
                height: 70px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                transition: all .2s ease-in-out;
            }
        }
        .swiper-button-next {
            left: initial;
            &:after {
                content: "\e91f";
            }
        }
    }
}

.section-background {
    color: $global-color-black;
    &.section-ou-nous-trouver {
        color: white;
        h2 {
            &:before {
                background: white;
            }
        }
        h2,
        p {
            color: white;
        }
        strong {
            color: white;
            font-weight: 600;
        }
        .container-flex-map {
            @media (min-width: $breakpoint-medium) {
                display: flex;
            }
        }
        .map {
            padding: 0;
            margin: 0 0 20px -40px;
            width: 100%;
            @media (min-width: $breakpoint-medium) {
                margin: 0;
                width: 48%;
                position: absolute;
                bottom: -1px;
                left: 0;
            }
            @media (min-width: $breakpoint-large) {
                width: 39%;
            }
            /*   img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            } */
        }
        .uk-background-cover {
            height: 100%;
        }
    }
    .uk-background-cover {
        height: 80vh;
        padding: calc($padding-padding*2);
        @media (min-width: $breakpoint-small) {
            height: 50vh;
        }
    }
    .content-link {
        color: white;
        padding: 5px 20px;
        border-radius: 50px;
        background: $global-color-green;
        display: inline-block;
        &:hover {
            background: white;
            color: $global-color-green;
            &:before {
                background: $global-color-green;
                transition: all .2s ease-in-out;
            }
        }
        &:before {
            background: white;
            transition: all .2s ease-in-out;
        }
        span {
            border-bottom: none;
        }
    }
}

.section-duo {
    .uk-grid {
        align-items: center;
        .content-txt {
            padding-left: 0;
            @media (min-width: $breakpoint-small) {
                padding-right: 10%;
                padding-left: 60px;
            }
        }
        .photo {
            @media (min-width: $breakpoint-small) {
                height: 700px;
            }
            img {
                object-fit: contain;
                width: 100%;
                height: 100%;
                @media (min-width: $breakpoint-small) {
                    object-fit: contain;
                }
            }
        }
        .visuel {
            position: relative;
            max-width: 100%;
            margin: 0 auto;
            padding-left: 0;
            @media (min-width: $breakpoint-small) {
                min-width: initial;
                margin: 0;
                padding-left: initial;
            }
            .elt-graphique {
                position: absolute;
                bottom: -50px;
                left: -66px;
                padding: 0;
                margin: 0;
                width: 60%;
            }
        }
    }
}

.section-swiper,
.section-swiper-bandeau {
    .uk-position-top-center-h1 {
        z-index: 1;
        text-align: center;
        margin-top: 50px;
        @media (min-width: $breakpoint-medium) {
            margin-top: 20px;
        }
        @media (min-width: $breakpoint-xxlarge) {
            margin-top: 30px;
        }
        figure {
            padding: 0;
            margin: 0;
            /*   img {
                width: 50%;
            } */
        }
    }
    .uk-position-bottom-center {
        z-index: 1;
        figure {
            @media (max-width: $breakpoint-small-max) {
                max-width: 40%;
            }
            padding: 0;
            max-width: 65%;
            margin: 0 auto;
            
        }
    }
    .scroll-button {
        position: absolute;
        color: white;
        top: 50%;
        left: 50%;
        border-bottom: none;
        transform: translate3d(-50%, 0, 0);
        @media (min-width: $breakpoint-small) {
            transform: translate3d(-50%, 50%, 0);
        }
        &:hover {
            border-bottom: none;
            text-decoration: none;
        }
        span {
            font-size: 2rem;
            color: white;
        }
    }
    .swiper-header,
    .swiper-listing {
        width: 100%;
        height: 100%;
        margin: 0 0 0 auto;
        position: relative;
        .swiper-wrapper {
            margin: 0;
            padding: 0;
            list-style: none;
            .swiper-slide {
                background: #fff;
                /* Center slide text vertically */
                display: flex;
                justify-content: center;
                align-items: center;
                height: 76vh;
                @media (min-width: $breakpoint-xxlarge) {
                    height: 650px;
                    //line-height: 650px;
                }
                @media (min-width: $breakpoint-xlarge-max) {
                    height: 900px;
                    //line-height: 900px;
                }
                figure,
                picture {
                    width: 100%;
                    padding: 0;
                    margin: 0;
                    height: 100%;
                    overflow: hidden;
                    position: relative;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: right;
                    position: absolute;
                    @media (min-width: $breakpoint-medium) {
                        object-position: center;
                    }
                }
                picture {
                    height: 430px;
                    width: 100%;
                    padding: 0;
                    margin: 0;
                    display: block;
                    overflow: hidden;
                    position: absolute;
                    @media (min-width: $breakpoint-small) {
                        height: 370px;
                    }
                    @media (min-width: $breakpoint-medium) {
                        height: 100%;
                    }
                }
                .container-title {
                    @media (max-width: $breakpoint-small) {
                        margin: 0;
                    }
                    //width: max-content;
                    margin: 0 0 var(--kg-spacing-medium) 0;
                    color: $global-color-white;
                    .title {
                        @media (max-width: $breakpoint-small) {
                            font-size: 1.2rem;
                        }
                        color: $global-color-green;
                        font-size: 3rem;
                        font-family: $global-heading-font-family;
                        font-weight: 600;
                        margin: 0;
                        line-height:1;
                        //text-shadow: 2px 2px 1px rgba(black, 0.3);
                    }
                    .subtitle {
                        @media (max-width: $breakpoint-small) {
                            font-size: .9rem;
                        }
                        font-family: $global-heading-font-family;
                        font-size: 2rem;
                        color: $global-color-soft-gray;
                        display: block;
                    }
                }
                .content-link {
                    color: white;
                    padding: 5px 20px;
                    border-radius: 50px;
                    background: $global-color-green;
                    display: inline-block;
                    &:hover {
                        background: white;
                        color: $global-color-green;
                        &:before {
                            background: $global-color-green;
                            transition: all .2s ease-in-out;
                        }
                    }
                    &:before {
                        background: white;
                        transition: all .2s ease-in-out;
                    }
                    span {
                        border-bottom: none;
                    }
                }
            }
        }
        .swiper-button-prev,
        .swiper-button-next {
            left: 10%;
            width: 70px;
            height: 70px;
            display: none;
            @media (min-width: $breakpoint-small) {
                display: block;
            }
            &.swiper-button-disabled {
                display: none;
            }
            &:hover {
                transition: all .2s ease-in-out;
                &:after {
                    border: 1px solid rgba(white, $alpha: 1);
                }
            }
            &:after {
                content: "\e904";
                font-family: 'icomoon';
                color: white;
                font-size: 2rem;
                border: 1px solid rgba(white, $alpha: .4);
                width: 70px;
                height: 70px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                transition: all .2s ease-in-out;
            }
        }
        .swiper-button-next {
            right: 10%;
            left: initial;
            &:after {
                content: "\e91f";
            }
        }
    }
    .swiper-listing {
        &:before {
            content: "";
            transform: skewX(-25deg);
            background: rgba($global-color-green, .7);
            width: 250px;
            display: block;
            position: absolute;
            top: 0;
            left: -90px;
            height: 390px;
            z-index: 10;
            @media (min-width: $breakpoint-small) {
                width: 450px;
                left: -90px;
            }
            @media (min-width: $breakpoint-xxlarge) {
                height: 390px;
            }
        }
        &:after {
            content: "";
            background: rgba(white, .4);
            width: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 390px;
            z-index: 1;
        }
        .swiper-wrapper {
            .swiper-slide {
                height: 230px;
                @media (min-width: $breakpoint-small) {
                    width: 390px;
                }
                @media (min-width: $breakpoint-xxlarge) {
                    height: 390px;
                    line-height: 390px;
                }
                @media (min-width: $breakpoint-xlarge-max) {
                    height: 390px;
                    line-height: 390px;
                }
                img {
                    object-position: center;
                    @media (min-width: $breakpoint-small) {
                        object-position: left;
                    }
                }
            }
        }
    }
}



.section-swiper__home {
    .swiper-header {
        .swiper-wrapper {
            .swiper-slide {
                picture {
                    height: 100%!important;
                }
            }
        }
        .swiper-button-prev {
            @media (max-width: $breakpoint-small-max) {
                bottom: 65px;
                top: initial;
                display: block;
            }
            left: 5%;
        }
        .swiper-button-next {
            @media (max-width: $breakpoint-small-max) {
                bottom: 65px;
                top: initial;
                display: block;
            }
            right: 7%;
        }
    }
    .uk-container {
        @media (max-width: $breakpoint-small-max) {
            width: 85%;
        }
        width: 65%;
    }
    .uk-position-top-center-h1 {
        @media (max-width: $breakpoint-xsmall-max) {
            display: none;
        }
    }
    .uk-position-center {
        text-align: center;
        text-shadow: 3px 2px 6px rgba(0,0,0, .4);
    }
    .uk-position-top-center-h1 {
        z-index: 1;
        text-align: center;
        margin-top: 50px;
        @media (min-width: $breakpoint-medium) {
            margin-top: 20px;
        }
        @media (min-width: $breakpoint-xxlarge) {
            margin-top: 30px;
        }
        figure {
            padding: 0;
            margin: 0;
            /*   img {
                width: 50%;
            } */
        }
    }
    .uk-position-bottom-center {
        z-index: 1;
        figure {
            @media (max-width: $breakpoint-small-max) {
                max-width: 40%;
            }
            padding: 0;
            max-width: 65%;
            margin: 0 auto;
            
        }
    }
    .scroll-button {
        position: absolute;
        color: white;
        top: 50%;
        left: 50%;
        border-bottom: none;
        transform: translate3d(-50%, 0, 0);
        @media (min-width: $breakpoint-small) {
            transform: translate3d(-50%, 50%, 0);
        }
        &:hover {
            border-bottom: none;
            text-decoration: none;
        }
        span {
            font-size: 2rem;
            color: white;
        }
    }
    .swiper-header,
    .swiper-listing {
        width: 100%;
        height: 100%;
        margin: 0 0 0 auto;
        position: relative;
        .swiper-wrapper {
            margin: 0;
            padding: 0;
            list-style: none;
            .swiper-slide {
                background: #fff;
                /* Center slide text vertically */
                display: flex;
                justify-content: center;
                align-items: center;
                height: 76vh;
                @media (min-width: 615px) {
                    height: 28.5vh;
                }
                @media (min-width: 750px) {
                    height: 40vh;
                }
                @media (min-width: 1200px) {
                    height: 50vh;
                }
                @media (min-width: 1500px) {
                    height: 60vh;
                }
                @media (min-width: 1600px) {
                    height: 70vh;
                }
                @media (min-width: $breakpoint-xlarge-max) {
                    height: 900px;
                }
                .picture-link {
                    width: 100%;
                    padding: 0;
                    margin: 0;
                    height: 100%;
                    overflow: hidden;
                    figure, 
                    picture {
                        height: 430px;
                        width: 100%;
                        padding: 0;
                        margin: 0;
                        display: block;
                        overflow: hidden;
                        @media (min-width: $breakpoint-small) {
                            height: 370px;
                        }
                        @media (min-width: $breakpoint-medium) {
                            height: 100%;
                        }
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        //object-position: right;
                        position: absolute;
                        @media (min-width: 615px) {
                            object-position: center;
                            object-fit: cover;
                        }
                        @media (min-width: 750px) {
                            object-fit: cover;
                        }
                        @media (min-width: $breakpoint-xlarge) {
                            object-position: center;
                        }
                    }
                }
               
                .container-title {
                    @media (max-width: $breakpoint-small) {
                        margin: 0;
                    }
                    //width: max-content;
                    margin: 0 0 var(--kg-spacing-medium) 0;
                    color: $global-color-white;
                    .title {
                        @media (max-width: $breakpoint-small) {
                            font-size: 1.2rem;
                        }
                        color: $global-color-green;
                        font-size: 3rem;
                        font-family: $global-heading-font-family;
                        font-weight: 600;
                        margin: 0;
                        line-height:1;
                        //text-shadow: 2px 2px 1px rgba(black, 0.3);
                    }
                    .subtitle {
                        @media (max-width: $breakpoint-small) {
                            font-size: .9rem;
                        }
                        font-family: $global-heading-font-family;
                        font-size: 2rem;
                        color: $global-color-soft-gray;
                        display: block;
                    }
                }
                .content-link {
                    color: white;
                    padding: 5px 20px;
                    border-radius: 50px;
                    background: $global-color-green;
                    display: inline-block;
                    &:hover {
                        background: white;
                        color: $global-color-green;
                        &:before {
                            background: $global-color-green;
                            transition: all .2s ease-in-out;
                        }
                    }
                    &:before {
                        background: white;
                        transition: all .2s ease-in-out;
                    }
                    span {
                        border-bottom: none;
                    }
                }
            }
        }
        .swiper-button-prev,
        .swiper-button-next {
            left: 10%;
            width: 70px;
            height: 70px;
            display: none;
            @media (min-width: $breakpoint-small) {
                display: block;
            }
            &.swiper-button-disabled {
                display: none;
            }
            &:hover {
                transition: all .2s ease-in-out;
                &:after {
                    border: 1px solid rgba(white, $alpha: 1);
                }
            }
            &:after {
                content: "\e904";
                font-family: 'icomoon';
                color: white;
                font-size: 2rem;
                border: 1px solid rgba(white, $alpha: .4);
                width: 70px;
                height: 70px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                transition: all .2s ease-in-out;
            }
        }
        .swiper-button-next {
            right: 10%;
            left: initial;
            &:after {
                content: "\e91f";
            }
        }
    }
    .swiper-listing {
        &:before {
            content: "";
            transform: skewX(-25deg);
            background: rgba($global-color-green, .7);
            width: 250px;
            display: block;
            position: absolute;
            top: 0;
            left: -90px;
            height: 390px;
            z-index: 10;
            @media (min-width: $breakpoint-small) {
                width: 450px;
                left: -90px;
            }
            @media (min-width: $breakpoint-xxlarge) {
                height: 390px;
            }
        }
        &:after {
            content: "";
            background: rgba(white, .4);
            width: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 390px;
            z-index: 1;
        }
        .swiper-wrapper {
            .swiper-slide {
                height: 230px;
                @media (min-width: $breakpoint-small) {
                    width: 390px;
                }
                @media (min-width: $breakpoint-xxlarge) {
                    height: 390px;
                    line-height: 390px;
                }
                @media (min-width: $breakpoint-xlarge-max) {
                    height: 390px;
                    line-height: 390px;
                }
                img {
                    object-position: center;
                    @media (min-width: $breakpoint-small) {
                        object-position: left;
                    }
                }
            }
        }
    }
}

.swiper-marques,
.swiper-produits {
    .swiper-wrapper {
        padding: 0;
        margin: 0;
        list-style: none;
    }
    .swiper-slide {
        padding: 10px;
        border: 1px solid $global-color-soft-gray;
        background: white;
        transition: all .2s ease-in-out;
        max-width: 405px;
        /* &.swiper-slide-active {
            figure {
                img {
                    filter: grayscale(0);
                    opacity: 1;
                }
            }
        } */
        &:hover {
            figure {
                img {
                    filter: grayscale(0);
                    opacity: 1;
                }
            }
        }
        figure {
            padding: 10px;
            margin: 0;
            height: 135px;
            @media (min-width: $breakpoint-medium) {
                padding: calc($padding-padding*3);
                height: 195px;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                filter: grayscale(100%);
                opacity: .6;
                transition: all .2s ease-in-out;
            }
        }
    }
}

.swiper-produits {
    .swiper-slide {
        padding: 0;
        &.uk-card-default {
            border: 1px solid transparent;
            &:hover {
                border: 1px solid $global-color-soft-gray;
            }
        }
        figure {
            @media (min-width: $breakpoint-medium) {
                padding: calc($padding-padding*2);
                height: 195px;
            }
            img {
                opacity: 1;
                filter: none;
            }
        }
    }
}

.section-slider-marques,
.section-slider-produits {
    .container-swiper-buttons {
        position: absolute;
        right: 45px;
        top: 0;
        display: flex;
        align-items: center;
        width: 120px;
        justify-content: space-between;
    }
    .swiper-button-prev,
    .swiper-button-next {
        left: initial;
        right: initial;
        position: relative;
        margin: 0;
        display: none;
        @media (min-width: $breakpoint-medium) {
            display: block;
        }
        &:hover {
            transition: all .2s ease-in-out;
            &:after {
                border: 1px solid rgba($global-color-black, 1)!important;
            }
        }
        &:after {
            content: "\e904";
            font-family: 'icomoon';
            font-size: 2rem;
            color: $global-color-black;
            border: 1px solid rgba($global-color-black, .5)!important;
            width: 70px;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            transition: all .2s ease-in-out;
        }
    }
    .swiper-button-next {
        left: initial;
        &:after {
            content: "\e91f";
        }
    }
    .swiper-wrapper {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
            @media (max-width: $breakpoint-small-max) {
                max-width: 100%;
            }
            max-width: 25%;
            margin-right: 1rem;
        }
    }
}

.uk-grid>* {
    padding-left: 0;
    @media (min-width: $breakpoint-small) {
        padding-left: 20px;
    }
}

#module_de_recherche {
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-flow: column;
    overflow-x: hidden;
    overflow-y: auto;
    right: -100%;
    padding-bottom: 5vh;
    transition: all .2s ease-in-out;
    @media (min-width: $breakpoint-medium) {
        top: 249px;
        padding-bottom: initial;
        z-index: 999;
        flex-flow: initial;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }
    &::-webkit-scrollbar {
        width: 5px;
        background-color: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: $global-color-green;
    }
    &.open {
        right: 0;
    }
    .close-button-module-recherche {
        position: absolute;
        background: white;
        z-index: 1;
        right: 1rem;
        top: 1rem;
        color: black;
        width: 50px;
        height: 50px;
        border: 2px solid black;
        border-radius: 50%;
        svg {
            pointer-events: none;
        }
        @media (min-width: $breakpoint-medium) {
            position: absolute;
        }
    }
    &.smaller {
        top: 120px;
    }
    .count {
        text-transform: uppercase;
        color: black;
        font-weight: 500;
        font-size: .8rem;
        margin: 0 1rem 0 0;
        padding: 0;
    }
    .content-link {
        margin: 0;
        font-size: .8rem;
    }
    .container-count {
        display: flex;
        align-items: center;
    }
    .container-produits {
        width: 100%;
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(249, 247, 247, 1) 100%);
        padding: 1.5rem;
        @media (min-width: $breakpoint-medium) {
            overflow-x: hidden;
            overflow-y: auto;
            width: 75%;
            padding: 2.5rem;
        }
        @media (min-width: $breakpoint-medium) {
            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 10px;
                background-color: #F5F5F5;
            }
            &::-webkit-scrollbar {
                width: 5px;
                background-color: #F5F5F5;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
                background-color: $global-color-green;
            }
        }
        .listing-produits {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            margin: 5vh 0;
            padding: 0;
            list-style: none;
            grid-gap: 2rem;
            @media (min-width: $breakpoint-small) {
                grid-template-columns: repeat(2, 1fr);
                margin: 5vh 0 20vh 0;
                grid-gap: 1rem;
            }
            @media (min-width: $breakpoint-xxlarge) {
                grid-template-columns: repeat(3, 1fr);
            }
            .uk-card-default {
                display: flex;
                align-items: center;
                .container-tags {
                    .tag {
                        font-size: .6rem;
                        padding: .2rem .5rem;
                        top: 5px;
                        left: 5px;
                    }
                }
                figure {
                    height: 120px;
                    width: 170px;
                }
                .content-card {
                    padding: .5rem;
                    text-align: left;
                    height: 120px;
                    background: none;
                    .title {
                        font-size: .7rem;
                        min-height: initial;
                        margin: 0;
                    }
                    .desc {
                        font-size: .6rem;
                        min-height: initial;
                    }
                }
                .container-prices {
                    margin: .5rem 0;
                    .price {
                        font-size: .9rem;
                    }
                }
            }
        }
    }
    .container-right-module-recherche {
        width: 100%;
        padding: 1.5rem;
        background: #D7E1B2;
        @media (min-width: $breakpoint-medium) {
            width: 25%;
            padding: 2.5rem;
        }
        .container-cat {
            .liste-lien-cat {
                margin: .5rem 0;
                padding: 0;
                list-style: none;
                li {
                    margin: .1rem 0;
                    a {
                        font-size: .7rem;
                        display: flex;
                        align-items: center;
                        &:before {
                            content: "\e903";
                            font-family: 'icomoon';
                            font-size: .6rem;
                            margin-right: 2px;
                            transform: rotate( -90deg);
                            display: inline-block;
                        }
                    }
                }
            }
        }
        .container-marques {
            margin: 2rem 0;
            .listing-marques {
                margin: 1rem 0;
                padding: 0;
                list-style: none;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 10px;
                @media (min-width: $breakpoint-medium) {
                    grid-template-columns: repeat(3, 1fr);
                }
                li {
                    figure {
                        width: 100%;
                        height: 75px;
                        background: white;
                        padding: 1rem;
                        margin: 0;
                        @media (min-width: $breakpoint-medium) {
                            width: 110px;
                        }
                        img {
                            object-fit: contain;
                            width: 100%;
                            height: 100%;
                            filter: grayscale(1);
                        }
                    }
                }
            }
        }
        .content-link {
            margin: 1rem 0;
            font-size: .8rem;
        }
    }
}

.grecaptcha-badge {
    visibility: hidden;
}