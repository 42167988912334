//
// Component: Subnav
//
// ========================================================================
.uk-subnav {
    width: 100%;
    justify-content: space-between;
    li {
        width: 25%;
        @media (min-width: $breakpoint-small) {
            width: 33%;
        }
        a {
            text-align: center;
            letter-spacing: 2px;
        }
    }
}

.uk-subnav-pill>*> :first-child {
    padding: 10px 20px;
    background: $global-color-soft-gray;
    @media (min-width: $breakpoint-medium) {
        font-size: .6em;
    }
    @media (min-width: $breakpoint-large) {
        font-size: .7em;
    }
    @media (min-width: $breakpoint-xlarge) {
        font-size: .8em;
    }
}

.uk-subnav-pill>.uk-active>a {
    background-color: $global-color;
}

.uk-subnav-pill>*>a:hover,
.uk-subnav-pill>*>a:focus-visible {
    background-color: $global-color;
    color: $global-color-white;
}