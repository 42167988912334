//
// Component: Search
//
// Search
// ========================================================================
.uk-search {
    background: $global-color-soft-gray;
    width: 90%;
    border-radius: 25px;
    padding: 0 20px;
    .uk-search-input {
        background: none;
        border: none;
        width: 100%;
        color: $global-color-black;
        line-height: 50px;
        height: 50px;
        font-size: .8rem;
        @media (min-width: $breakpoint-medium) {
            line-height: 45px;
            height: 45px;
            padding: 0!important;
        }
        &:focus-visible {
            background: $global-color-soft-gray;
        }
        &::placeholder {
            font-style: italic;
            color: $global-color-gray;
            font-size: .75rem;
        }
    }
    .submit-button-search {
        cursor: pointer;
        width: 60px;
        svg {
            color: $global-color-black;
            width: 20px;
        }
    }
}

.uk-form-custom {
    width: 100%;
}

.uk-button-search-marques-submit {
    background: $global-color-soft-gray;
    border: none;
    text-transform: none;
    font-style: italic;
    font-size: .75rem;
    height: 50px;
    line-height: 1;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (min-width: $breakpoint-medium) {
        height: 40px;
        width: 90%;
    }
    .uk-icon {
        svg {
            width: 30px;
        }
    }
}