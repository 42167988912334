//
// Component: Panier
//
// Panier
// ========================================================================
section {
    &.heading-title-cart {
        .uk-container {
            display: flex;
            align-items: center;
            border-bottom: 2px solid $global-color-soft-gray;
            h1 {
                &:after {
                    content: none;
                }
            }
            .container-number-products {
                margin: 0 0 0 auto;
                span {
                    color: $global-color-green;
                }
            }
        }
    }
}

section {
    &.section-my-cart {
        padding-bottom: $padding-padding*2.5;
        .uk-container {
            .button-panier {
                display: flex;
                align-items: center;
                margin: 0 0 0 auto;
                border: 1px solid transparent;
                font-size: 1rem;
                &::after {
                    content: "\e93c";
                    font-family: 'icomoon';
                    font-size: 1.3rem;
                }
                &:hover {
                    border: 1px solid $global-color-green;
                    background: white;
                    color: $global-color-green;
                }
            }
            .uk-subnav-pill {
                width: 100%;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0 0 calc($margin-margin/2) 0;
                @media (min-width: $breakpoint-small) {
                    margin: 0 0 $margin-large-margin-l 0;
                }
                @media (min-width: $breakpoint-medium-light) {
                    grid-template-columns: repeat(2, 1fr);
                }
                &:before {
                    content: "";
                    width: 100%;
                    height: 1px;
                    background: $global-color-gray;
                    position: absolute;
                    top: 20px;
                    @media (min-width: $breakpoint-small) {
                        top: 50px;
                    }
                }
                li {
                    padding: 0;
                    margin-bottom: calc($margin-margin/2);
                    @media (min-width: $breakpoint-small) {
                        width: 25%;
                        margin-bottom: 0;
                    }
                    &:last-child {
                        a {
                            &:after {
                                content: none;
                            }
                            &:hover {
                                &:after {
                                    content: none;
                                }
                            }
                        }
                    }
                    &.item-subnav-gray {
                        text-align: center;
                        display: flex;
                        align-items: center;
                        flex-flow: column;
                        color: $global-color;
                        text-transform: initial;
                        font-weight: 600;
                        font-size: .6rem;
                        letter-spacing: 1px;
                        background: none;
                        @media (min-width: $breakpoint-small) {
                            font-size: 1rem;
                        }
                        span {
                            font-size: 1rem;
                            font-weight: 500;
                            border: 1px solid $global-color-gray;
                            border-radius: 50%;
                            width: 40px;
                            height: 40px;
                            align-items: center;
                            justify-content: center;
                            display: flex;
                            margin-bottom: 10px;
                            background: white;
                            color: $global-color-black;
                            @media (min-width: $breakpoint-small) {
                                font-size: 1.2rem;
                                width: 80px;
                                height: 80px;
                            }
                        }
                    }
                    &.uk-active {
                        background: none;
                        color: $global-color;
                        span {
                            background: black;
                            color: white;
                            border: 1px solid black;
                        }
                    }
                    &.checked {
                        span {
                            background: $global-color-soft-gray;
                            color: white;
                        }
                    }
                }
            }
            .uk-switcher {
                h3 {
                    color: $global-color-green;
                    border-bottom: 1px solid $global-color-soft-gray;
                }
            }
            #adresses {
                .mes-adresses {
                    .adresse {
                        background: $global-color-green;
                        position: relative;
                        cursor: pointer;
                        h2 {
                            text-align: left;
                            color: white;
                            border-bottom: none;
                            margin: 0;
                        }
                        input {
                            display: none;
                        }
                        label {
                            color: white;
                        }
                        &.active {
                            border: 5px solid $global-color-green;
                        }
                    }
                }
                .btn-submit.uk-margin {
                    margin: 40px auto!important;
                    background: $global-color-green;
                    &:hover {
                        background: blue;
                    }
                }
                .message-commande {
                    padding: $padding-padding;
                    background: #f8f8f8;
                    label {
                        color: $global-color;
                        display: block;
                        margin-bottom: 10px;
                    }
                    textarea {
                        display: block;
                        background: white;
                        width: 100%;
                    }
                }
            }
            #summary {
                .table-responsive {
                    text-align: center;
                }
                .item {
                    font-family: $global-font-family;
                    font-weight: bold;
                    font-size: 1.2rem;
                    color: #707073;
                    cursor: inherit;
                    &:before {
                        content: "";
                        display: inline-block;
                        font-family: 'icomoon';
                        font-size: 1.2rem;
                        color: $global-color-dark-gray;
                        vertical-align: middle;
                        margin: 0 $margin-margin 0 0;
                    }
                    &.item-picto-1 {
                        &:before {
                            content: "\e915";
                        }
                    }
                    &.item-picto-2 {
                        &:before {
                            content: "\e90b";
                        }
                    }
                    &.item-picto-3 {
                        &:before {
                            content: "\e91d";
                        }
                    }
                    &.item-picto-4 {
                        &:before {
                            content: "\e929";
                        }
                    }
                    .uk-radio {
                        margin-left: $margin-margin;
                        &:checked {
                            background: $global-color-green;
                        }
                    }
                }
            }
            #my-cart-table {
                @media (max-width: $breakpoint-small) {
                    margin: 0;
                }
                margin-top: 0;
                thead {
                    tr {
                        th {
                            font-weight: normal;
                            padding: $padding-padding 0;
                            text-align: center;
                            &.produit {
                                text-align: left;
                            }
                        }
                    }
                }
                td {
                    padding: calc($padding-padding/4) calc($padding-padding/2);
                    display: block;
                    text-align: left;
                    float: left;
                    width: 30%;
                    @media (min-width: $breakpoint-small) {
                        padding: calc($padding-padding*1.5) calc($padding-padding/2);
                        display: table-cell;
                        text-align: center;
                        float: none;
                        width: 20%;
                    }
                    &.trash {
                        display: flex;
                        @media (min-width: $breakpoint-small) {
                            display: table-cell;
                        }
                        button {
                            border: none;
                            background: none;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            margin: 0 auto;
                            cursor: pointer;
                            width: 50px;
                            height: 50px;
                            margin: 10px 0 20px 0;
                            border: 1px solid $global-color-soft-gray;
                            border-radius: 50%;
                            transition: all .2s ease-in-out;
                            @media (min-width: $breakpoint-small) {
                                width: 60px;
                                margin: 0;
                                height: 60px;
                            }
                            span {
                                font-size: 2rem;
                                color: $global-color-gray;
                                font-weight: 500;
                                transition: all .2s ease-in-out;
                                @media (min-width: $breakpoint-small) {
                                    font-size: 2.5rem;
                                }
                            }
                            &:hover {
                                background: $global-color-green;
                                border: 1px solid $global-color-green;
                                span {
                                    color: white;
                                }
                            }
                        }
                    }
                }
                tbody {
                    .product {
                        padding: $padding-padding 0;
                        width: 100%;
                        @media (min-width: $breakpoint-small) {
                            padding: calc($padding-padding*1.5) calc($padding-padding/2) calc($padding-padding*1.5) 0;
                            width: 80%;
                        }
                        .uk-flex-middle {
                            align-items: start;
                            @media (min-width: $breakpoint-small) {
                                align-items: center;
                            }
                        }
                        figure {
                            padding: 10px;
                            margin: 0;
                            height: 80px;
                            width: 80px;
                            background: white;
                            border: 1px solid $global-color-soft-gray;
                            /*    @media (min-width: $breakpoint-small) {
                                height: 100%;
                                width: 100%;
                            } */
                            a {
                                display: block;
                                width: 100%;
                                margin: 0;
                                padding: 0;
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                    max-height: inherit;
                                }
                            }
                        }
                        .desc {
                            margin-left: 20px;
                            text-align: left;
                            width: 70%;
                            .title {
                                padding: 0;
                                margin: 0;
                                a {
                                    text-transform: uppercase;
                                    color: $global-color-black;
                                    font-weight: 500;
                                    &:hover {
                                        color: $global-color-green;
                                        text-decoration: underline;
                                    }
                                }
                            }
                            .ref {
                                margin: 0;
                                color: $global-color-green;
                                font-size: .8rem;
                                font-weight: 500;
                            }
                            .decli {
                                margin: 0;
                                padding: 0;
                                list-style: none;
                                li {
                                    font-size: .8rem;
                                    &.color {
                                        display: flex;
                                        align-items: center;
                                        span.color-bkg {
                                            width: 1.5rem;
                                            height: 1.5rem;
                                            display: block;
                                            border-radius: 100rem;
                                            margin: 0 .2rem 0 0;
                                        }
                                    }
                                }
                            }
                            .stock {
                                display: flex;
                                align-items: center;
                                font-size: .8rem;
                                margin: 5px 0 0 0;
                                span {
                                    color: $global-color-green;
                                    font-size: 1.2rem;
                                    margin-right: 5px;
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                    .quantity {
                        width: 40%;
                        @media (min-width: $breakpoint-small) {
                            width: auto;
                        }
                    }
                    .description {
                        text-align: left;
                        font-size: 1rem;
                        p {
                            padding: 0;
                            margin: 0;
                            &.title {
                                a {
                                    color: $global-color-green;
                                    font-weight: 500;
                                    font-size: 1rem;
                                }
                            }
                            &.ref {
                                font-size: .8rem;
                                font-style: italic;
                                color: $global-color-dark-gray;
                            }
                        }
                    }
                    .unit-price-container {
                        p {
                            &.unit-price {
                                color: #1D1719;
                                font-weight: 500;
                                font-family: $global-font-family;
                                text-align: left;
                                margin: 0;
                                font-size: 1rem;
                                @media (min-width: $breakpoint-medium-light) {
                                    text-align: center;
                                }
                                @media (min-width: $breakpoint-large) {
                                    font-size: 1.2rem;
                                }
                                @media (min-width: $breakpoint-xlarge) {
                                    font-size: 1rem;
                                }
                            }
                            &.old-price {
                                color: $global-color-soft-gray;
                                font-size: .6rem;
                                text-align: left;
                                margin: 0;
                                text-decoration: line-through;
                                @media (min-width: $breakpoint-medium-light) {
                                    text-align: center;
                                    font-size: 1rem;
                                }
                            }
                        }
                    }
                    .delivery {
                        font-size: 1rem;
                        p {
                            font-size: .8rem;
                        }
                    }
                    .total {
                        color: #1D1719;
                        font-weight: 500;
                        font-family: $global-font-family;
                        font-size: 1rem;
                    }
                    .discount {
                        color: $global-color-dark-gray;
                        font-weight: bold;
                        font-size: 1rem;
                        p {
                            background: $global-color-green;
                            font-size: 1rem;
                            padding: 5px;
                            width: 50%;
                            margin: 0 auto;
                            color: white;
                            font-family: $global-font-family;
                            @media (min-width: $breakpoint-small) {
                                width: auto;
                            }
                        }
                    }
                }
                tbody.totaux {
                    position: relative;
                    @media (min-width: $breakpoint-small) {
                        position: absolute;
                        right: 0;
                        width: 35%;
                    }
                    tr {
                        border-bottom: none;
                        margin-bottom: 0;
                        @media (min-width: $breakpoint-small) {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 0;
                        }
                        &:nth-of-type(odd) {
                            background: white;
                        }
                        th {
                            padding: 0;
                            display: none;
                            text-align: right;
                            font-size: 1.2rem;
                            text-transform: none;
                            color: $global-color-dark-gray;
                            @media (min-width: $breakpoint-small) {
                                display: block;
                            }
                            &.total {
                                font-family: $global-font-family;
                                text-transform: uppercase;
                                font-size: 1rem;
                                font-weight: bold;
                            }
                        }
                        td {
                            text-align: right;
                            padding: 20px;
                            font-size: 1.2rem;
                            color: $global-color;
                            text-transform: uppercase;
                            font-family: $global-font-family;
                            &:first-child {
                                width: 70%;
                            }
                            &:last-child {
                                width: 30%;
                                background: #e8e8e8;
                            }
                            p {
                                font-weight: 500;
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
            .shopping-button {
                width: 250px;
                background: $global-color;
                &:hover {
                    background: $global-color-black;
                }
            }
        }
        .container-recap {
            margin-right: $margin-margin;
            @media (min-width: $breakpoint-medium-light) {
                margin-right: calc($margin-margin*2.5);
            }
            .cdg-link {
                font-weight: 500;
                color: $global-color-black;
                &:hover {
                    color: $global-color-green;
                    text-decoration: underline;
                }
            }
            .right-position {
                width: 250px;
                margin: 0 0 $margin-margin auto;
                span {
                    margin-left: 10px;
                    font-size: 1.5rem;
                    font-weight: 500;
                }
                &:hover {
                    color: white;
                }
            }
        }
        .container-buttons-navigation {
            display: block;
            justify-content: space-between;
            margin-top: $margin-large-margin-l;
            @media (min-width: $breakpoint-small) {
                display: flex;
                align-items: center;
            }
            .submit-button {
                margin: $margin-margin auto;
                width: auto;
                min-width: 250px;
                padding: 0 $padding-padding;
                justify-content: space-evenly;
                @media (min-width: $breakpoint-small) {
                    margin: $margin-margin 0;
                }
                &:hover {
                    color: white;
                }
                span {
                    font-size: 1.5rem;
                }
            }
        }
    }
}

.table-responsive {
    display: block;
    width: 100%;
    .container-confirmation {
        padding: $padding-padding;
        background: white;
        border: 2px solid $global-color-green;
        div {
            display: flex;
            align-items: center;
            justify-content: center;
            a {
                margin: 0 $margin-margin;
                color: $global-color-green;
            }
        }
    }
}

.table-responsive-full {
    box-shadow: none;
    width: 100%;
    margin: calc($margin-margin*1.5) 0;
    @media (min-width: $breakpoint-small) {
        font-size: .7rem;
    }
    @media (min-width: $breakpoint-medium-light) {
        font-size: 1rem;
    }
    thead {
        border-bottom: 1px solid $global-color-gray;
        th {
            font-family: $global-font-family;
            color: $global-color-gray;
            text-transform: none;
            text-align: center;
            text-transform: uppercase;
            @media (min-width: $breakpoint-small) {
                font-size: .9rem;
            }
            @media (min-width: $breakpoint-large) {
                font-size: 1rem;
            }
            &.uk-width-medium {
                text-align: left;
            }
        }
    }
    tbody {
        td {
            text-align: center;
            @media (min-width: $breakpoint-medium-light) {
                font-size: .7rem;
                text-align: center;
            }
            @media (min-width: $breakpoint-large) {
                font-size: 1rem;
            }
            &.uk-width-medium {
                text-align: left;
            }
            &.uk-width-small {
                width: 10%;
            }
        }
    }
    thead tr,
    tfoot tr {
        display: none;
        @media (min-width: $breakpoint-small) {
            display: table-row;
        }
    }
    tbody tr {
        margin-bottom: 20px;
        display: block;
        overflow: auto;
        border-top: 1px solid $global-color-soft-gray;
        &:last-child {
            border-bottom: 1px solid $global-color-soft-gray;
        }
        @media (min-width: $breakpoint-small) {
            overflow: inherit;
            display: table-row;
        }
        &.impair {
            background: #f8f8f8;
        }
        &.pair {
            background: white;
        }
    }
    .spinner-form {
        display: flex;
        align-items: center;
        @media (min-width: $breakpoint-small) {
            justify-content: center;
        }
        .buttons-more-less {
            background: $form-background;
            border: 1px solid rgba(65, 74, 89, 0.2);
            border-radius: 5px;
            span {
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                position: relative;
                @media (min-width: $breakpoint-xlarge) {
                    width: 20px;
                    height: 20px;
                }
                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    background: $global-color-green;
                    display: block;
                    font-size: 2rem;
                    height: 2px;
                    width: 10px;
                    top: 50%;
                    left: 50%;
                    margin-top: -1px;
                    margin-left: -5px;
                }
                &.plus {
                    &::after {
                        transform: rotate(90deg);
                    }
                }
            }
        }
        input {
            width: 30px;
            height: 30px;
            text-align: center;
            margin: 0 calc($margin-margin/2);
            padding: calc($padding-padding/4);
            background: $form-background;
            border: 1px solid rgba(65, 74, 89, 0.2);
            border-radius: 5px;
            font-size: 1rem;
            font-family: $global-font-family;
            @media (min-width: $breakpoint-xlarge) {
                width: 50px;
                height: 30px;
            }
        }
    }
    tr,
    td {
        display: block;
        @media (min-width: $breakpoint-small) {
            display: table-row;
        }
    }
    td {
        border-top: none;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $global-color-dark-gray;
        @media (min-width: $breakpoint-small) {
            display: table-cell;
        }
        &:first-child:before {
            width: 100%;
        }
        &::before {
            content: attr(data-label);
            display: inline-block;
            font-size: .8rem;
            font-weight: normal;
            margin-left: 0;
            text-align: left;
            text-transform: uppercase;
            width: auto;
            color: $global-color-dark-gray;
            white-space: nowrap;
            @media (min-width: $breakpoint-small) {
                display: none;
                font-size: 1rem;
            }
        }
        &.address {
            display: block;
            @media (min-width: $breakpoint-small) {
                display: table-cell;
            }
            &::before {
                width: 100%;
            }
        }
        &.details {
            a {
                &::before {
                    content: "\e91a";
                    font-family: 'icomoon';
                    font-size: 2rem;
                    color: $global-color-dark-gray;
                    display: block;
                    margin: 0 auto;
                    transform: scale(1);
                    transition: all .1s ease-in-out;
                }
                &:hover {
                    text-decoration: none;
                    &::before {
                        transform: scale(1.2);
                    }
                }
            }
        }
        &.contact {
            a {
                &::before {
                    content: "\e92f";
                    font-family: 'icomoon';
                    font-size: 2rem;
                    color: $global-color-dark-gray;
                    display: block;
                    margin: 0 auto;
                    transform: scale(1);
                    transition: all .1s ease-in-out;
                }
                &:hover {
                    text-decoration: none;
                    &::before {
                        transform: scale(1.2);
                    }
                }
            }
        }
        &.statut {
            span {
                width: 30px;
                height: 30px;
                display: flex;
                border-radius: 20px;
                text-align: center;
                margin: 0;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                @media (min-width: $breakpoint-small) {
                    margin: 0 auto;
                }
            }
            &.statut-ok {
                span {
                    &::before {
                        content: "";
                        width: 30px;
                        height: 30px;
                        display: block;
                        background: url("../images/svg/validate.svg") no-repeat center center;
                    }
                }
            }
            &.statut-no {
                span {
                    &::before {
                        content: "";
                        width: 30px;
                        height: 30px;
                        display: block;
                        background: url("../images/svg/error-order.svg") no-repeat center center;
                    }
                }
            }
            &.statut-in-progress {
                span {
                    width: 43px;
                    &::before {
                        content: "";
                        width: 43px;
                        height: 10px;
                        display: block;
                        background: url("../images/svg/in-progress.svg") no-repeat center center;
                    }
                }
            }
        }
        img {
            border-radius: 5px;
            width: 100%;
            max-height: 100px;
            object-fit: contain;
        }
    }
    &.uk-table-hover>tr:hover,
    &.uk-table-hover tbody tr:hover {
        background: #e1e1e1;
    }
    &#pieces-table {
        td {
            text-align: left;
            @media (max-width: $breakpoint-small) {
                display: block;
                font-size: .8rem;
                &:before {
                    font-size: 1rem;
                    margin-bottom: $margin-margin;
                }
            }
        }
    }
}

.container-promo-totaux {
    display: block;
    @media (min-width: $breakpoint-small) {
        display: flex;
        justify-content: space-between;
    }
    .promo {
        .continuer-achats {
            margin: $margin-margin auto;
            @media (min-width: $breakpoint-small) {
                margin: 0;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
        form {
            label {
                color: $global-color-green;
                font-weight: 500;
                font-size: 1.2rem;
                margin-bottom: 10px;
            }
        }
    }
}

.content-panier {
    position: relative;
    @media (min-width: $breakpoint-medium) {
        display: flex;
    }
}

.container-totaux-panier {
    position: -webkit-sticky;
    position: sticky;
    top: 145px;
    height: calc(100% - 145px);
}

.container-totaux {
    background: #F9F7F7;
    padding: $padding-padding;
    @media (min-width: $breakpoint-small) {
        padding: calc($padding-padding*1.5);
    }
    .title-total {
        text-transform: uppercase;
        color: $global-color-black;
        font-weight: 600;
    }
    .container-price {
        display: flex;
        justify-content: space-between;
        &:last-child {
            border-bottom: none;
        }
        .label {
            font-size: .8rem;
            @media (min-width: $breakpoint-small) {
                font-size: .6rem;
            }
            @media (min-width: $breakpoint-medium-light) {
                font-size: .8rem;
            }
            span {
                display: block;
                font-size: .55rem;
                margin-top: 5px;
            }
        }
        .price {
            font-weight: 600;
            color: $global-color-black;
            font-size: .9rem;
            display: block;
            width: 45%;
            text-align: right;
            @media (min-width: $breakpoint-small) {
                font-size: .7rem;
                width: 33%;
            }
            @media (min-width: $breakpoint-medium-light) {
                font-size: .9rem;
            }
            &.total-price {
                color: $global-color-black;
            }
        }
        >div {
            //padding: $padding-padding 0;
        }
    }
    .container-produits-recap {
        @media (min-width: $breakpoint-small) {
            display: flex;
        }
        @media (min-width: $breakpoint-medium) {
            display: block;
        }
        @media (min-width: $breakpoint-medium-light) {
            display: flex;
        }
        figure {
            padding: 10px;
            margin: 0;
            height: 80px;
            width: 80px;
            background: white;
            border: 1px solid $global-color-soft-gray;
            @media (min-width: $breakpoint-small) {
                height: 110px;
                width: 110px;
            }
            @media (min-width: $breakpoint-medium) {
                width: 100%;
            }
            @media (min-width: $breakpoint-medium-light) {
                width: 110px;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                max-height: inherit;
            }
        }
        .desc {
            margin-left: 20px;
            text-align: left;
            @media (min-width: $breakpoint-medium) {
                margin-left: 0;
            }
            @media (min-width: $breakpoint-medium-light) {
                margin-left: 20px;
            }
            .title {
                padding: 0;
                margin: 0;
                a {
                    text-transform: uppercase;
                    color: $global-color-black;
                    font-weight: 500;
                    font-size: .9rem;
                    @media (min-width: $breakpoint-medium-light) {
                        font-size: 1rem;
                    }
                    &:hover {
                        color: $global-color-green;
                        text-decoration: underline;
                    }
                }
            }
            .quantity {
                margin: 0;
                font-size: .7rem;
                @media (min-width: $breakpoint-medium-light) {
                    font-size: 1rem;
                }
            }
            .product-price {
                font-weight: 600;
                color: $global-color-black;
                font-size: .8rem;
                display: block;
                margin: 0;
                @media (min-width: $breakpoint-medium-light) {
                    font-size: .9rem;
                }
            }
        }
    }
    .promo-form {
        padding: 25px 0;
        border-top: 1px solid $global-color-soft-gray;
        border-bottom: 1px solid $global-color-soft-gray;
        label {
            color: $global-color-black;
            display: block;
            font-weight: 500;
            padding-bottom: 10px;
            font-size: 1rem;
            @media (min-width: $breakpoint-small) {
                font-size: .8rem;
            }
            @media (min-width: $breakpoint-medium-light) {
                font-size: 1rem;
            }
        }
        .uk-form-controls {
            height: 50px;
            position: relative;
            .uk-input {
                border-bottom: none;
                border-radius: 25px;
                height: 50px;
                width: 100%;
                max-width: 88%;
                padding-right: 80px;
                &::placeholder {
                    font-style: italic;
                    font-size: 1rem;
                    @media (min-width: $breakpoint-small) {
                        font-size: .8rem;
                    }
                    @media (min-width: $breakpoint-medium-light) {
                        font-size: 1rem;
                    }
                }
                &:focus-visible {
                    background: white;
                }
            }
            button {
                position: absolute;
                right: 0;
                width: auto;
                padding: 0 calc($padding-padding*1.5);
                font-size: 1rem;
                @media (min-width: $breakpoint-small) {
                    font-size: .8rem;
                }
                @media (min-width: $breakpoint-medium-light) {
                    font-size: 1rem;
                }
            }
        }
    }
}

.totaux {
    position: relative;
    tr {
        border-bottom: none;
        margin-bottom: 0;
        height: 80px;
        @media (min-width: $breakpoint-small) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0;
        }
        &:nth-of-type(odd) {
            background: white;
        }
        th {
            padding: 0;
            display: none;
            text-align: right;
            font-size: 1.2rem;
            text-transform: none;
            color: $global-color-dark-gray;
            @media (min-width: $breakpoint-small) {
                display: block;
            }
            &.total {
                font-family: $global-font-family;
                text-transform: uppercase;
                font-size: 1rem;
                font-weight: bold;
            }
        }
        td {
            text-align: right;
            padding: 20px;
            font-size: 1.2rem;
            color: $global-color;
            text-transform: uppercase;
            font-family: $global-font-family;
            &:first-child {
                width: 70%;
            }
            &:last-child {
                width: 30%;
                background: #e8e8e8;
            }
            p {
                font-weight: 500;
                font-size: 1rem;
            }
        }
    }
}

.container-paiement {
    margin-bottom: calc($margin-margin*4);
    .choix-paiement {
        display: flex;
        align-items: center;
        padding: 20px;
        background: #f8f8f8;
        position: relative;
        cursor: pointer;
        margin: 20px 0;
        transition: all .2s ease-in-out;
        @media (min-width: $breakpoint-small) {
            margin: 20px 60px;
        }
        &:hover {
            background: #eaeaea;
        }
        div {
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            span {
                position: absolute;
                right: 0;
                bottom: 0;
                font-size: 2rem;
                color: blue;
                @media (min-width: $breakpoint-small) {
                    right: 20px;
                    bottom: inherit;
                }
            }
        }
        input {
            display: none;
        }
        label {
            display: block;
            width: 100%;
            cursor: pointer;
            @media (min-width: $breakpoint-small) {
                display: flex;
                align-items: center;
            }
            figure {
                padding: 0;
                margin: 0;
            }
        }
    }
}

.accordion-pieces-obligatoires {
    border-bottom: 1px solid #E5E1E2;
    padding-bottom: 20px;
    .uk-open>.uk-accordion-title::before {
        transform: rotate(180deg);
    }
    .uk-accordion-title {
        &:before {
            content: "\e903";
            font-family: 'icomoon';
            background: $global-color-green;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            font-size: .8rem;
            border-radius: 50%;
        }
    }
}