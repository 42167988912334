//
// Layout: Mon compte
//
// Mon compte
// ========================================================================
.section-mon-compte {
    .container-titre-bienvenue {
        background: white;
        text-align: center;
        padding: calc($padding-padding*2.5);
        width: 90%;
        margin: -80px auto 0 auto;
        position: relative;
        @media (min-width: $breakpoint-small) {
            width: 60%;
        }
        p {
            padding: 0;
            margin: 0;
            font-family: $global-heading-font-family;
            text-transform: uppercase;
            color: $global-color-black;
            letter-spacing: 1px;
            font-size: 1.2rem;
            @media (min-width: $breakpoint-small) {
                font-size: 1.5rem;
            }
        }
    }
    .container-forms {
        .form {
            margin-left: 0;
        }
    }
}

.container-facette-espace-compte {
    margin: $margin-margin 0 calc($margin-margin*2) 0;
    @media (min-width: $breakpoint-medium-light) {
        margin: calc($margin-margin*4) 0 calc($margin-margin*2) 0;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
        @media (min-width: $breakpoint-small) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width: $breakpoint-medium-light) {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 30px;
        }
        li {
            a {
                display: block;
                background: #F9F7F7;
                display: flex;
                flex-flow: column;
                align-items: center;
                min-height: 120px;
                justify-content: center;
                font-size: 1rem;
                line-height: 1;
                text-align: center;
                padding: $padding-padding;
                transition: all .3s ease-in-out;
                @media (min-width: $breakpoint-medium-light) {
                    font-size: 1rem;
                    height: 180px;
                }
                @media (min-width: $breakpoint-xlarge) {
                    height: 300px;
                }
                @media (min-width: $breakpoint-xxlarge) {
                    height: 380px;
                    padding: $padding-padding*2;
                }
                span {
                    font-size: 2.5em;
                    color: $global-color-black;
                    width: 85px;
                    height: 85px;
                    border: 1px solid $global-color-soft-gray;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: $margin-margin;
                    transition: all .3s ease-in-out;
                }
                .title {
                    font-weight: 600;
                    text-transform: uppercase;
                    color: $global-color-black;
                    font-size: .7rem;
                    letter-spacing: 1px;
                    transition: all .3s ease-in-out;
                    @media (min-width: $breakpoint-medium-light) {
                        font-size: 1rem;
                    }
                }
                .desc {
                    color: $global-color;
                    font-size: .9rem;
                    line-height: 1.2rem;
                    display: block;
                    transition: all .3s ease-in-out;
                }
                &.active,
                &:hover {
                    text-decoration: none;
                    background: $global-color-green;
                    color: white;
                    span {
                        border: 1px solid white;
                        color: white;
                    }
                    .title {
                        color: white;
                    }
                    .desc {
                        color: white;
                    }
                }
            }
        }
    }
}

.container-nav-facette {
    margin-right: 80px;
    .container-facette-espace-compte {
        margin: $margin-margin 0 calc($margin-margin*2) 0;
        @media (min-width: $breakpoint-medium-light) {
            margin: 0 0 $margin-margin 0;
        }
        ul {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 5px;
            @media (min-width: $breakpoint-medium) {
                grid-template-columns: repeat(1, 1fr);
                grid-gap: 20px;
            }
            li {
                a {
                    height: 150px;
                    font-size: .9em;
                    display: flex;
                    flex-flow: inherit;
                    justify-content: start;
                    flex-flow: column;
                    padding: $padding-padding;
                    @media (min-width: $breakpoint-small) {
                        flex-flow: initial;
                        padding: calc($padding-padding/2);
                    }
                    @media (min-width: $breakpoint-large) {
                        padding: $padding-padding;
                    }
                    span {
                        margin-bottom: 0;
                        font-size: 2em;
                        width: 65px;
                        height: 65px;
                    }
                    .title {
                        margin: $margin-margin 0 0 0;
                        @media (min-width: $breakpoint-small) {
                            margin: 0 0 0 $margin-margin;
                        }
                    }
                    .desc {
                        display: none;
                    }
                }
            }
        }
    }
}