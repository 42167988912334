//
// Component: Breadcrumb
//
// =======================================================================
#breadcrumb {
    /* position: absolute;
    z-index: 100;
    width: 100%;
    top: 0;
    background: rgba($color: $global-color-white, $alpha: .6);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.01), 0 3px 6px rgba(0, 0, 0, 0.02); */
    @media (min-width: $breakpoint-small) {
        top: 0;
    }
    .uk-container {
        width: 90%;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .uk-accordion-content {
        width: 100%;
        transition: all .3s ease-in-out;
        @media (min-width: $breakpoint-small) {
            width: fit-content;
        }
        .uk-accordion-title {
            color: $global-color;
            font-size: 1em;
            padding: 5px;
            transition: all .3s ease-in-out;
            //border-bottom: 2px solid $global-color-gray-2;
            @media (min-width: $breakpoint-small) {
                display: none!important;
            }
            &:before {
                transition: all .3s ease-in-out;
                background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhbHF1ZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMzAgMzAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMwIDMwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6Izc3Nzc3Nzt9Cjwvc3R5bGU+CjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAsLTk1Mi4zNjIxOCkiPgoJPHBhdGggY2xhc3M9InN0MCIgZD0iTTE1LjMsOTcxLjJsLTAuNS0wLjVsLTYuMS01LjdsMS4xLTEuMmw1LjYsNS4ybDUuNi01LjJsMS4xLDEuMmwtNi4xLDUuN0wxNS4zLDk3MS4yTDE1LjMsOTcxLjJ6Ii8+CjwvZz4KPC9zdmc+Cg==") no-repeat center center;
            }
        }
        .uk-accordion-content {
            margin-top: 0;
            padding: 5px 10px;
            padding-bottom: 10px;
            font-size: .8em;
            background: white;
            @media (min-width: $breakpoint-small) {
                display: block!important;
                padding: 5px 10px 5px 0;
                box-shadow: none;
                background: transparent;
            }
        }
        a {
            color: $global-color;
            border-bottom: 2px solid transparent;
            transition: all .3s ease-in-out;
            &:hover {
                text-decoration: none;
                border-bottom: 2px solid $global-color;
            }
        }
        .uk-breadcrumb>*>* {
            display: inline-block;
            font-size: 0.875rem;
            color: $global-color;
        }
        .uk-breadcrumb> :nth-child(n+2):not(.uk-first-column)::before {
            content: ">";
            display: inline-block;
            margin: 0 calc($margin-margin/2);
            color: $global-color;
        }
        .uk-disabled {
            a {
                color: $global-color-green;
            }
        }
    }
    li.uk-open {
        .uk-accordion-title {
            &:before {
                transform: rotate(180deg);
                background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhbHF1ZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMzAgMzAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMwIDMwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6Izc3Nzc3Nzt9Cjwvc3R5bGU+CjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAsLTk1Mi4zNjIxOCkiPgoJPHBhdGggY2xhc3M9InN0MCIgZD0iTTE1LjMsOTcxLjJsLTAuNS0wLjVsLTYuMS01LjdsMS4xLTEuMmw1LjYsNS4ybDUuNi01LjJsMS4xLDEuMmwtNi4xLDUuN0wxNS4zLDk3MS4yTDE1LjMsOTcxLjJ6Ii8+CjwvZz4KPC9zdmc+Cg==") no-repeat center center;
            }
        }
    }
}