//
// Component: Détail marque
//
// Détail marque
// ========================================================================
.section-header-detail-marque {
    background: #F9F7F7;
    padding: 1.5rem 0;
    @media (min-width: $breakpoint-medium) {
        padding: 2.5rem 0;
    }
    .uk-container {
        width: 90%;
    }
    .img {
        border: 1px solid #E5E1E2;
        padding: 30px;
        background: #ffffff;
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin: 0 0 1.5rem 0;
        @media (min-width: $breakpoint-medium) {
            width: 350px;
            margin: 0;
        }
        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
    .container-flex {
        @media (min-width: $breakpoint-medium) {
            display: flex;
            align-items: center;
            gap: 50px;
        }
        .content-detail {
            @media (min-width: $breakpoint-medium) {
                flex-basis: 60%;
            }
            h1 {
                font-size: 60px;
                text-shadow: none;
                color: #1D1719;
            }
            .kg-truncated-text__toggle-wrapper {
                flex-direction: column;
                align-items: flex-start;
                @media (min-width: $breakpoint-medium) {
                    flex-direction: row;
                    align-items: center;
                }
            }
        }
    }
}

.section-categories {
    padding: 2.5rem 0;
    .uk-container {
        width: 90%;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
        @media (min-width: $breakpoint-medium) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width: $breakpoint-large) {
            grid-template-columns: repeat(3, 1fr);
        }
        li {
            max-width: 100%;
            width: 100%;
            .container-img-pattern {
                max-height: 80px;
                width: 100%;
                background-size: 30%;
                display: flex;
                align-items: center;
                justify-content: center;
                @media (min-width: $breakpoint-medium) {
                    max-height: 120px;
                }
                .cat {
                    background: $global-color-green;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-transform: uppercase;
                    font-family: $global-heading-font-family;
                    color: #ffffff;
                    font-size: 18px;
                    height: 50px;
                    width: 100%;
                    transition: all .2s ease-in-out;
                    @media (min-width: $breakpoint-medium) {
                        height: 80px;
                        font-size: 25px;
                    }
                    &:hover {
                        background: $global-color-gray;
                    }
                }
            }
        }
    }
}

.section-produits-marque {
    .uk-container {
        width: 90%;
    }
    &__wrapper {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 20px;
            @media (min-width: $breakpoint-large) {
                grid-template-columns: repeat(3, 1fr);
            }
            @media (min-width: $breakpoint-xlarge) {
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }
}

.section-decouvrir-marque {
    padding: 1.5rem 0;
    @media (min-width: $breakpoint-medium) {
        padding: 2.5rem 0;
    }
    .uk-container {
        background: #F9F7F7;
        padding: 1.5rem 5%;
        @media (min-width: $breakpoint-medium) {
            padding: 4rem 5rem;
            width: 50%;
        }
    }
    iframe {
        margin-bottom: 40px;
    }
    .content-link {
        &:before {
            content: none;
        }
        text-transform: uppercase;
    }
}

.section-marques {
    .container-marques {
        list-style: none;
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
        @media (min-width: $breakpoint-medium) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width: $breakpoint-xlarge) {
            grid-template-columns: repeat(4, 1fr);
        }
        li {
            &:before {
                content: none;
            }
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
            }
        }
    }
}