//
// Component: Tab To Accordion
//
// Tab To Accordion
// ========================================================================

/* $color__tab: #444;
$color__tab-active: #3bacff;
$background__main: #dce2e2;
$color__light: #fff;
$width__max: 680px;
$padding: 1rem;
$border__radius: 3px;
$lighten__level: 6;
$darken__level: 6;
$font__family: "Roboto",
sans-serif;
$font__weight: 300;
$font__size: 16px; */


/* @mixin tab-active() {
    background: $color__tab-active;
    color: $color__light;
} */

.tab-to-accordion {
    &.has-tabs {
        .tab-to-accordion--tabs {
            opacity: 1;
            height: auto;
            visibility: visible;
        }
        .tab-to-accordion--entry {
            min-height: 0;
            &::before {
                display: none;
            }
            &.is-active {
                .tab-to-accordion--entry-content {
                    opacity: 1;
                    transition: opacity 400ms ease-in-out;
                }
            }
            .tab-to-accordion--entry-content {
                opacity: 0;
                transition: opacity 400ms ease-in-out;
            }
        }
    }
}

.tab-to-accordion--tabs {
    opacity: 0;
    height: 0;
    visibility: hidden;
    display: flex;
    flex-flow: wrap;
    margin: calc($margin-margin/2) 0;
    list-style: none;
    padding: 0 0 $padding-padding 0;
    border-bottom: 1px solid $global-color-soft-gray;
    .tab {
        display: block;
        color: $global-color-black;
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: bold;
        margin: 0 $margin-margin 1px 0;
        border: none;
        cursor: pointer;
        padding: 0;
        transition: all .3s ease-in-out;
        &:hover {
            color: $global-color-green;
        }
        &:focus-visible {
            border: 1px solid $global-color-green;
            color: $global-color-green;
        }
        &.is-active {
            color: $global-color-green;
            &:hover {
                color: $global-color-green;
            }
        }
    }
}

.tab-to-accordion--entry {
    overflow: hidden;
    &::before {
        text-transform: uppercase;
        position: relative;
        content: attr(data-title);
        cursor: pointer;
        z-index: 1;
        color: $global-color-black;
        padding: calc($padding-padding/4) 0;
        display: block;
    }
    &::after {
        content: "\e903";
        font-family: 'icomoon';
        position: absolute;
        right: 0;
        top: 0;
        padding: 0.5rem 0;
        @media (min-width: $breakpoint-medium) {
            display: none;
        }
    }
    &:hover,
    &:focus-visible {
        outline: none;
        &::before {
            color: $global-color-green;
        }
    }
    &.is-active {
        .tab-to-accordion--entry-content {
            margin-top: 0;
            height: auto;
            opacity: 1;
            transition: margin 400ms ease-out -100ms;
        }
        &::before {
            color: $global-color-green;
            text-transform: uppercase;
            font-weight: 500;
        }
        &::after {
            transform: rotate(-180deg);
        }
        &:hover,
        &:focus-visible {
            &::before {
                color: $global-color-green;
            }
        }
    }
}

.tab-to-accordion--entry-container {
    overflow: hidden;
    margin-bottom: 1px;
}

.tab-to-accordion--entry-content {
    position: relative;
    margin-top: -100%;
    height: 0;
    opacity: 0;
    transition: margin 500ms ease-in;
    padding: calc($padding-padding/2) 0 0 0;
    a.more {
        color: #444;
        &:hover {
            color: $global-color-green;
        }
    }
}