//
// Component: KG Truncate Text
//
// KG Truncate Text
// ========================================================================
.kg-truncated-text {
    --kg-truncated-text-line-clamp: 3;
    // Content
    & &__content {
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: var(--kg-truncated-text-line-clamp);
        -webkit-box-orient: vertical;
        &>* {
            margin-top: 0;
            margin-bottom: var(--kg-spacing)!important;
            &:last-child {
                margin-bottom: 0;
            }
            &>* {
                margin-top: 0;
                margin-bottom: var(--kg-spacing);
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    &__toggle-wrapper {
        margin-top: 20px;
        display: flex;
        align-items: center;
        gap: 20px;
    }
    // Is Open
    &.is-open {
        --kg-truncated-text-line-clamp: initial !important;
    }
    .kg-button {
        display: flex;
        margin: 0;
        padding: 0;
    }
    .content-link {
        &:before {
            content: none;
        }
        span {
            text-transform: uppercase;
        }
    }
}