//
// Component: Home
//
// Home
// ========================================================================
section {
    position: relative;
}

.section-header-home-h1 {
    margin: 40px auto;
    h1 {
        text-align: center;
        text-shadow: none;
        color: #1D1719;
        text-transform: uppercase;
    }
}

.section-nouveautes {
    .content-link {
        position: relative!important;
        margin-bottom: $margin-margin;
        display: block;
        @media (min-width: $breakpoint-small) {
            position: absolute!important;
            margin-bottom: 0;
        }
    }
}

.uk-container {
    position: relative;
}

.add-favoris {
    position: absolute;
    top: 5px;
    left: 5px;
    border: none;
    z-index: 1;
    cursor: pointer;
    padding: 0;
    font-size: 1.5em;
    background: rgba(255, 255, 255, .8);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1), 0 0 6px rgba(0, 0, 0, 0.1);
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease-in-out;
    &:before {
        content: "";
        font-family: 'icomoon';
        content: "\e91b";
        color: $global-color;
        display: block;
        transition: all .3s ease-in-out;
    }
    &.active {
        &:before {
            color: $global-color;
        }
    }
    &:hover {
        &:before {
            color: $global-color;
        }
    }
}